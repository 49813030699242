import { getAxiosDataNode } from '@/services/apiConfig';
import { Result } from '@/types/services';
/*
* 接口类型：Post
* 编码类型：Content-Type:application/json
*/

// 地址解析
async function geocoder(params = {}): Promise<any> {
  const url = 'bigdata/geocoder';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}
export {
  geocoder,
};
