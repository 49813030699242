
























































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-nested-ternary */
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getDetailedAnalysis } from '@/utils/echarts/detailedAnalysis';
import { uniqueData } from '@/components/index';
@Component({
  components: {},
  filters: {
    formatName(name: string) {
      let str = name.replaceAll('(人数)', '');
      str = str.replaceAll('(平方公里)', '');
      str = str.replaceAll('(人次)', '');
      return str;
    },
  },
})
export default class extends Vue {
  option: any = {};
  @Prop({
    type: Array,
    required: false,
  })
  data!: [];
  @Prop({
    type: String,
    required: false,
  })
  title!: '';
  @Prop({
    type: String,
    required: false,
  })
  currentName!: '';
  @Prop({
    type: Number,
    required: false,
  })
  displayType!: 0;
  // 初始话数据
  typeObj = {
    min: {
      type: 'min',
      name: '商圈人口(人数)',
      name2: '全市商场商圈人口(人数)最小值',
      color: '#F831F0',
    },
    center: {
      type: 'center',
      name: '商圈人口(人数)',
      name2: '全市商场商圈人口(人数)中位数',
      color: '#4034F3',
    },
    current: {
      type: 'current',
      name: '商圈人口(人数)',
      name2: '当前商场商圈人口(人数)值',
      color: '#2EC7E4',
    },
    average: {
      type: 'average',
      name: '商圈人口(人数)',
      name2: '全市商场商圈人口(人数)平均数',
      color: '#A228F5',
    },
    max: {
      type: 'max',
      name: '商圈人口(人数)',
      name2: '全市商场商圈人口(人数)最大值',
      color: '#317DF6',
    },
  };
  closeClickHandler(): void {
    this.$emit('updatDetailedAnalysisStatus', false);
  }
  mounted(): void {
    this.changeData(this.data);
  }
  changeData(data: any): void {
    const valueCountSectionData: any = [];
    const valueSectionData: any = [];
    const typeData: any = {};
    const markAreaData: any = [];
    const markPointData: any = [];
    let currentObj: any = {};
    let count0Obj: any = {};
    let sliceIndex = 0;
    let updateIndex = 0;
    data.forEach((item: any, index: number) => {
      const { count, type } = item;
      typeData[type.type] = type;
      if (type.type === 'current') {
        currentObj = item;
      }
      if (count === 0 && type.type === 'current') {
        // 直接移除data中的这一项
        sliceIndex = index;
      } else if (count === 0 && type.type !== 'min') {
        // 修改count
        count0Obj = item;
        updateIndex = index;
      }
    });
    if (count0Obj.count === 0 && currentObj) {
      // eslint-disable-next-line no-param-reassign
      data[updateIndex].count = currentObj.count;
    }
    if (sliceIndex !== 0) {
      data.splice(sliceIndex, 1);
    }
    // eslint-disable-next-line no-param-reassign
    data = uniqueData(data, 'value');
    data.forEach((item: any, index: number) => {
      const index2 = index + 1;
      const item2 = data[index2];
      const { count, value, type } = item;
      const { name, name2, color } = type;
      valueCountSectionData.push(count);
      let values = value;
      if (type.type === 'current') {
        values = `${`${value}\n\n` + '当前:'}${this.currentName}`;
      } else if (currentObj.value === value) {
        values = `${`${value}\n\n` + '当前:'}${this.currentName}`;
      }
      valueSectionData.push(values);
      if (item2) {
        const { count: count2, value: value2 } = item2;
        // area 数据
        const markAreaObj = [
          {
            xAxis: index,
            itemStyle: {
              color: 'rgba(216,216,216,0)',
            },
            count: count2,
            value: `${value}~${value2}`,
            nameText: name,
          },
          {
            xAxis: index2,
            itemStyle: {
              color: 'none',
            },
          },
        ];
        markAreaData.push(markAreaObj);
      }

      // point 数据
      const markPointObj = {
        value2: value,
        nameText: name2,
        xAxis: index,
        yAxis: 0,
        itemStyle: {
          color,
        },
        label: {
          show: true,
          formatter: null,
          color: '#000',
          backgroundColor: '#FFFFFF',
          padding: [9, 23],
          fontSize: 18,
          rich: {
            a: {
              fontSize: 16,
              align: 'left',
              color: '#FFFFFF',
              verticalAlign: 'top',
            },
            x: {
              fontSize: 16,
              align: 'left',
              color: '#000',
              padding: [0, 0, 100, 20],
            },
          },
        },
      };
      markPointData.push(markPointObj);
    });
    this.typeObj = typeData;
    let company = '平方公里';
    let xName = '平方公里';
    if (this.displayType === 0) {
      company = '人';
      xName = '人数';
    } else if (this.displayType === 1) {
      company = '%';
      xName = '占比%';
    } else {
      company = '平方公里';
      xName = '平方公里';
    }
    this.option = getDetailedAnalysis(
      this.title,
      xName,
      company,
      valueSectionData,
      valueCountSectionData,
      markAreaData,
      markPointData,
    );
    // 2021-12-07 22:00 利用mackArea配置项实现区域范围 ,暂时不用原声echarts开发。
    // const myChart = this.$echarts.init(document.getElementById('my-chart'));
    // myChart.setOption(option);
    // myChart.off('click');
    // myChart.on('click', (params: any) => {
    // });
  }
}
