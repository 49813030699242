/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
let openDelay = false;
let clearTime: any;
// 防抖节流
export const preventReClick = (el: any, binding: any) => {
  el.onclick = function () {
    if (openDelay) return;
    const { value } = binding;
    openDelay = !openDelay;
    if (!value) {
      console.error('未传入Value数据！');
      return;
    }
    const { callback, time } = value;
    if (typeof time !== 'number') {
      console.error('传入等待时间错误');
      return;
    }
    const args = [];
    // eslint-disable-next-line
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        if (key === 'func' || key === 'time') continue;
        args.push(value[key]);
      }
    }
    if (clearTime) clearTimeout(clearTime);
    clearTime = setTimeout(() => {
      openDelay = !openDelay;
    }, time);
    if (callback) callback(...args);
  };
};
