/* eslint-disable @typescript-eslint/naming-convention */
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

@Module({ dynamic: true, name: 'mall', store })
class Mall extends VuexModule {
  mallId = NaN;
  mallName = '';
  adcode = '';
  cityAdcode = '';
  // 存储日期 日、周、月 主要用于数据获取
  date = '';
  duration = 'd';
  durationText = '日';
  compareDuration = '较昨日';
  boundary = '';
  status!: number;
  center = '';
  dayDate = '';
  rangeDate = '';
  weekDate = '';
  monthDate = '';
  competedModel = 0;
  competedModelObj = {
    mMallId: 0,
    nMallId: 0,
    nCount: 0,
  };
  intensityShow = false;
  // 存储table日期 日、周、月用于前端展示
  dates = '';
  path = '';
  environmentDataType = '';
  aoiPortraitPage = 0;
  aoiId = '';
  numType = 1;
  aoiTypeCurrentObj = {
    id: 1,
    name: '居住地',
    type: 1,
  };
  aoiTypeName = '居住地';
  cityCenter = '';
  services = {
    dates: [],
    mallId: NaN,
    isTestUser: false,
  };

  @Mutation
  setMallId(mallId: number): void {
    if (this.mallId !== mallId) {
      this.mallId = mallId;
    }
  }

  @Mutation
  setCityAdcode(value: string): void {
    this.cityAdcode = value;
  }

  @Mutation
  setCityCenter(value: string): void {
    this.cityCenter = value;
  }

  @Mutation
  setAoiNumType(value: number): void {
    this.numType = value;
  }

  @Mutation
  setAoiTypeCurrentObj(value: any): void {
    if (value) {
      this.aoiTypeCurrentObj = value;
    }
  }

  @Mutation
  setAoiId(value: string): void {
    this.aoiId = value;
  }

  @Mutation
  setEnvironmentDataType(val: string): void {
    this.environmentDataType = val;
  }

  @Mutation
  setCompetedModel(val: number): void {
    this.competedModel = val;
  }

  @Mutation
  setIntensityShow(val: boolean): void {
    this.intensityShow = val;
  }

  @Mutation
  setCompetedModelObj(obj: any): void {
    this.competedModelObj = obj;
  }

  @Mutation
  setRouoter(path: string): void {
    this.path = path;
  }

  @Mutation
  setMallName(name: string): void {
    if (this.mallName !== name) {
      this.mallName = name;
    }
  }

  @Mutation
  setDuration(duration: string): void {
    if (this.duration !== duration) {
      this.duration = duration;
    }
  }

  @Mutation
  setDurationText(durationText: string): void {
    if (this.durationText !== durationText) {
      this.durationText = durationText;
    }
  }

  @Mutation
  setCompareDuration(compareDuration: string): void {
    if (this.compareDuration !== compareDuration) {
      this.compareDuration = compareDuration;
    }
  }

  @Mutation
  setDate(date: string): void {
    if (this.date !== date) {
      this.date = date;
    }
  }

  @Mutation
  setDates(dates: string): void {
    if (this.dates !== dates) {
      this.dates = dates;
    }
  }

  @Mutation
  setDayDate(date: string): void {
    if (this.dayDate !== date) {
      this.dayDate = date;
    }
  }

  @Mutation
  setRangeDate(dates: string[]): void {
    const date1 = dates.join(',');
    if (this.rangeDate !== date1) {
      this.rangeDate = date1;
    }
  }

  @Mutation
  setWeekDate(date: string): void {
    if (this.weekDate !== date) {
      this.weekDate = date;
    }
  }

  @Mutation
  setMonthDate(date: string): void {
    if (this.monthDate !== date) {
      this.monthDate = date;
    }
  }

  @Mutation
  setAdcode(adcode: string): void {
    if (this.adcode !== adcode) {
      this.adcode = adcode;
    }
  }

  @Mutation
  setStatus(status: number): void {
    if (this.status !== status) {
      this.status = status;
    }
  }

  @Mutation
  setBoundary(boundary: string): void {
    if (this.boundary !== boundary) {
      this.boundary = boundary;
    }
  }

  @Mutation
  setCenter(center: string): void {
    if (this.center !== center) {
      this.center = center;
    }
  }

  @Mutation
  setAoiPortraitPage(val: number): void {
    this.aoiPortraitPage = val;
  }

  @Mutation
  setMallServices(services: any): void {
    if (!this.services || this.services.mallId !== services.mallId) {
      this.services = services;
    }
  }
}

export default Mall;
