/* eslint-disable @typescript-eslint/naming-convention */
import { RouterConfig } from '@/types/router';
const manageRouter: Array<RouterConfig> = [
  {
    id: '1',
    path: '/manage/details',
    name: '账户详情',
    meta: {
      isShow: true,
    },
    component: () => import('@/pages/managePages/details/index.vue'),
  },
  {
    id: '2',
    path: '/manage/competition',
    name: '添加竞品',
    meta: {
      isShow: true,
    },
    component: () => import('@/pages/managePages/competition/index.vue'),
  },
  {
    id: '3',
    path: '/manage/source',
    name: '申请客流来源区域',
    meta: {
      isShow: true,
    },
    component: () => import('@/pages/managePages/source/index.vue'),
  },
  {
    id: '4',
    path: '/manage/system',
    name: '系统审核',
    meta: {
      isShow: true,
    },
    component: () => import('@/pages/managePages/system/index.vue'),
  },
  {
    id: '5',
    path: '/manage/user',
    name: '用户管理',
    meta: {
      isShow: true,
    },
    component: () => import('@/pages/managePages/userManage/index.vue'),
  },
  {
    id: '6',
    path: '/manage/service',
    name: '企业服务管理',
    meta: {
      isShow: true,
    },
    component: () => import('@/pages/managePages/service/index.vue'),
  },
  {
    id: '7',
    path: '/manage/monitor',
    name: 'API监控',
    meta: {
      isShow: true,
    },
    component: () => import('@/pages/managePages/monitor/index.vue'),
  },
  {
    id: '8',
    path: '/manage/services',
    name: 'key权限同步',
    meta: {
      isShow: false,
    },
    component: () => import('@/pages/managePages/services/index.vue'),
  },
];
export default manageRouter;
