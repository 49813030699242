/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-param-reassign */
// import { Params } from '@/types/store';
/* eslint-disable prefer-destructuring */
import dayjs from 'dayjs';
import { privacy2 } from '../../config/global';
/* eslint-disable max-len */
import { deepClone } from '@/utils';
// eslint-disable-next-line @typescript-eslint/no-require-imports
const img = require('../../assets/basics/weekend.png');
const hoverDiscolor = 'rgba(20, 20, 20, 0.6)';
const dateInfo = [
  '2017-12-30',
  '2017-12-31',
  '2018-01-01',
  '2018-02-15',
  '2018-02-16',
  '2018-02-17',
  '2018-02-18',
  '2018-02-19',
  '2018-02-20',
  '2018-02-21',
  '2018-04-05',
  '2018-04-06',
  '2018-04-07',
  '2018-04-29',
  '2018-04-30',
  '2018-05-01',
  '2018-06-16',
  '2018-06-17',
  '2018-06-18',
  '2018-09-22',
  '2018-09-23',
  '2018-09-24',
  '2018-10-01',
  '2018-10-02',
  '2018-10-03',
  '2018-10-04',
  '2018-10-05',
  '2018-10-06',
  '2018-10-07',
  '2018-12-30',
  '2018-12-31',
  '2019-01-01',
  '2019-02-04',
  '2019-02-05',
  '2019-02-06',
  '2019-02-07',
  '2019-02-08',
  '2019-02-09',
  '2019-02-10',
  '2019-04-05',
  '2019-04-06',
  '2019-04-07',
  '2019-05-01',
  '2019-05-02',
  '2019-05-03',
  '2019-05-04',
  '2019-06-07',
  '2019-06-08',
  '2019-06-09',
  '2019-09-14',
  '2019-09-15',
  '2019-09-16',
  '2019-10-01',
  '2019-10-02',
  '2019-10-03',
  '2019-10-04',
  '2019-10-05',
  '2019-10-06',
  '2019-10-07',
  '2020-01-01',
  '2020-01-24',
  '2020-01-25',
  '2020-01-26',
  '2020-01-27',
  '2020-01-28',
  '2020-01-29',
  '2020-01-30',
  '2020-01-31',
  '2020-02-01',
  '2020-02-02',
  '2020-04-04',
  '2020-04-05',
  '2020-04-06',
  '2020-05-01',
  '2020-05-02',
  '2020-05-03',
  '2020-05-04',
  '2020-05-05',
  '2020-06-25',
  '2020-06-26',
  '2020-06-27',
  '2020-10-01',
  '2020-10-02',
  '2020-10-03',
  '2020-10-04',
  '2020-10-05',
  '2020-10-06',
  '2020-10-07',
  '2020-10-08',
  '2021-01-01',
  '2021-01-02',
  '2021-01-03',
  '2021-02-11',
  '2021-02-12',
  '2021-02-13',
  '2021-02-04',
  '2021-02-15',
  '2021-02-16',
  '2021-02-17',
  '2021-04-03',
  '2021-04-04',
  '2021-04-05',
  '2021-05-01',
  '2021-05-02',
  '2021-05-03',
  '2021-06-12',
  '2021-06-13',
  '2021-06-14',
  '2021-09-21',
  '2021-10-01',
  '2021-10-02',
  '2021-10-03',
  '2021-10-04',
  '2021-10-05',
  '2021-10-06',
  '2021-10-07',
  '2022-01-01',
  '2022-01-02',
  '2022-01-03',
  '2022-01-31',
  '2022-02-01',
  '2022-02-02',
  '2022-02-03',
  '2022-02-04',
  '2022-02-05',
  '2022-02-06',
  '2022-04-03',
  '2022-04-04',
  '2022-04-05',
  '2022-04-30',
  '2022-05-01',
  '2022-05-02',
  '2022-05-03',
  '2022-05-04',
  '2022-06-03',
  '2022-06-04',
  '2022-06-05',
  '2022-09-10',
  '2022-09-11',
  '2022-09-12',
  '2022-10-01',
  '2022-10-02',
  '2022-10-03',
  '2022-10-04',
  '2022-10-05',
  '2022-10-06',
  '2022-10-07',
];
const weekendInfo = [
  '2018-02-11',
  '2018-02-24',
  '2018-04-08',
  '2018-04-28',
  '2018-09-29',
  '2018-09-30',
  '2018-12-29',
  '2019-02-02',
  '2019-02-03',
  '2019-04-28',
  '2019-05-05',
  '2019-09-29',
  '2019-10-12',
  '2020-04-26',
  '2020-05-09',
  '2020-06-28',
  '2020-09-27',
  '2020-10-10',
  '2021-02-07',
  '2021-02-20',
  '2021-09-26',
  '2021-10-09',
  '2022-01-29',
  '2022-01-30',
  '2022-04-02',
  '2022-04-24',
  '2022-05-07',
  '2022-10-08',
  '2022-10-09',
];
const grayColor = '#999999';
const textColor = '#000000';
const seriesColor = '#3256EE';
const optionColor = '#3398DB';
const white = '#FFFFFF';
const orangeColor = '#ee5c3d';
const greyColor = 'rgba(0,0,0,0.60)';
const axisColor = 'rgba(0,0,0,0.16)';
const nameColor = 'rgba(0,0,0,0.90)';
const blueColor = '#40c8f5';
const greenColor = '#0062ff';
const percentText = '占比（%）';
const portraitGrid = {
  left: '30px',
  right: '30px',
  top: '20%',
  bottom: '15%',
};
const grid = {
  top: '20%',
  bottom: '15%',
  left: '60px',
  right: '60px',
};
function colorFunction(dataObj: any, e: string): string {
  const item = dataObj[e];
  const { time } = item;
  const day = dayjs(new Date(time)).format('YYYY-MM-DD');
  // const day = new Date(time).getDay();
  return (dateInfo.find(i => day === i) || dayjs(day).day() === 0 || dayjs(day).day() === 6) && !weekendInfo.find(i => day === i) ? '#FF284F' : textColor;
  // return day === 0 || day === 6 ? '#FF284F' : textColor;
}

const activePassengerFlow = (xData: string[], seriesData: number[]): any => {
  const options = {
    optionColor,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      textStyle: {
        color: white,
        fontSize: '11px',
      },
      formatter(params: any) {
        const val = params[0].value;
        const previousHour = `${params[0].dataIndex < 10 ? `0${params[0].dataIndex}` : params[0].dataIndex}:59`;
        return `<div style="width:191px;height:62px;padding: 10px;"><div style="height:35px;line-height:35px"><span>时段</span><span style="float:right">${params[0].name} - ${previousHour}</span></div><div style="height:35px;line-height:35px;">${params[0].marker}<span style="margin-left:10px;">顾客占比</span><span style="float:right">${val}%</span></div></div>`;
      },
    },
    grid,
    xAxis: [
      {
        type: 'category',
        name: '时间',
        data: xData,
        axisLine: {
          lineStyle: {
            color: textColor,
          },
        },
        axisTick: {
          show: false,
          alignWithLabel: true,
        },
        axisLabel: {
          align: 'center',
          interval: 0,
        },

      },
    ],
    yAxis: [{
      type: 'value',
      name: percentText,
      axisLabel: {
      },
      axisLine: {
        lineStyle: {
          color: textColor,
        },
      },
      splitLine: {
        lineStyle: {
          color: [textColor],
          width: 1,
          type: 'dashed',
          opacity: 0.2,
        },
      },
    }],
    series: [
      {
        name: '',
        type: 'bar',
        barWidth: '24px',
        itemStyle: {
          normal: {
            color: seriesColor,
          },
        },
        data: seriesData,
      },
    ],
  };
  return options;
};

const lengthStayHour = (xData: string[], seriesData: number[], dates: string[]): any => {
  const dateStart = dates[0];
  const dateEnd = dates[dates.length - 1];
  const options = {
    color: optionColor,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      textStyle: {
        color: white,
        fontSize: '11px',
      },
      formatter(params: any) {
        return `<div style="width:181px;height:62px;padding: 10px;"><div style="height:35px;line-height:35px"><span>开始日期</span><span style="float:right">${dateStart}</span></div><div style="height:35px;line-height:35px"><span>结束日期</span><span style="float:right">${dateEnd}</span></div><div style="height:35px;line-height:35px"><span style="display:inline-block;width:8px;height:8px;border-radius:4px;background:${orangeColor};margin-right:10px;margin-bottom:1px"></span><span>${params[0].axisValue}</span><span style="float:right">${params[0].value}%</span></div></div>`;
      },
    },
    grid,
    xAxis: [
      {
        name: '时长',
        data: xData,
        axisLine: {
          lineStyle: {
            color: textColor,
          },
        },
        axisLabel: {
          interval: 0,
        },
      },
    ],
    yAxis: [{
      type: 'value',
      name: percentText,
      axisLabel: {
      },
      axisLine: {
        lineStyle: {
          color: textColor,
        },
      },
      splitLine: {
        lineStyle: {
          color: [textColor],
          width: 1,
          type: 'dashed',
          opacity: 0.2,
        },
      },
    }],
    series: [
      {
        name: '',
        barGap: '20%',
        type: 'line',
        // barWidth: '24px',
        radius: '45%',
        itemStyle: {
          normal: {
            color: orangeColor,
          },
        },
        data: seriesData,
      },
    ],
  };
  return options;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const numRankTrend = (xData: string[], seriesData: string[], seriesData2: string[], dataObj: any): any => {
  const options = {
    color: optionColor,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      textStyle: {
        color: white,
        fontSize: '11px',
      },
      formatter(params: any) {
        const { axisValue, dataIndex } = params[0];
        const data = dataObj[axisValue];
        let { time } = data;
        time = time.split(' ')[0];
        return `<div style="padding: 10px;"><div style="height:35px;line-height:35px"><span>日期</span><span style="float:right;margin-left:36px;">${time}</span></div><div style="height:35px;line-height:35px"><span style="display:inline-block;width:8px;height:8px;border-radius:4px;background:${orangeColor};margin-right:10px;margin-bottom:1px"></span><span>全市排名</span><span style="float:right;margin-left:36px;">${params[0].value}</span></div><div style="height:35px;line-height:35px"><span style="display:inline-block;width:8px;height:8px;border-radius:4px;background:${orangeColor};margin-right:10px;margin-bottom:1px"></span><span>客流总数</span><span style="float:right;margin-left:36px;">${seriesData2[dataIndex]}</span></div></div>`;
      },
    },
    grid,
    xAxis: [
      {
        name: '',
        data: xData,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: textColor,
          },
        },
        axisLabel: {
          rich: {
            value: {
              fontsize: 20,
            },
            img0: {
              height: 15,
              backgroundColor: { image: img },
            },
          },
          color(e: string) {
            return colorFunction(dataObj, e);
          },
          formatter(value: any) {
            return (dateInfo.find(i => i === dataObj[value].time) || dayjs(dataObj[value].time).day() === 0 || dayjs(dataObj[value].time).day() === 6) && !weekendInfo.find(i => i === dataObj[value].time) ? `{img0|}${value}` : `${value}`;
          },
        },
      },
    ],
    yAxis: [{
      type: 'value',
      name: '排名',
      nameLocation: 'start',
      inverse: true,
      minInterval: 1,
      axisLabel: {
      },
      axisLine: {
        lineStyle: {
          color: textColor,
        },
      },
      splitLine: {
        lineStyle: {
          color: [textColor],
          width: 1,
          type: 'dashed',
          opacity: 0.2,
        },
      },
    }],
    series: [
      {
        name: '',
        barGap: '20%',
        type: 'line',
        // barWidth: '24px',
        radius: '45%',
        itemStyle: {
          normal: {
            color: orangeColor,
          },
        },
        data: seriesData,
      },
    ],
  };
  return options;
};

const flowCityDistribution = (xData: string[], seriesData: string[], date?: string): any => {
  const options = {
    color: optionColor,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      textStyle: {
        color: white,
        fontSize: '11px',
      },
      formatter(params: any) {
        const str = `<div style="height:62px;padding:5px 10px;"><div style="height:35px;line-height:35px"><span>日期</span><span style="float:right">${date}</span></div><div style="height:35px;line-height:35px"><span style="display:inline-block;width:8px;height:8px;border-radius:4px;background:${seriesColor};margin-right:10px;margin-bottom:1px"></span><span>${params[0].axisValue}客流占比</span><span style="float:right;margin-left: 10px;">${params[0].value}%</span></div></div>`;
        return str;
      },
    },
    grid,
    xAxis: [
      {
        type: 'category',
        name: '',
        data: xData,
        axisLine: {
          lineStyle: {
            color: textColor,
          },
        },
        axisTick: {
          show: false,
        },
      },
    ],
    yAxis: [{
      type: 'value',
      name: percentText,
      // max: 100,
      axisLabel: {
      },
      axisLine: {
        lineStyle: {
          color: textColor,
        },
      },
      splitLine: {
        lineStyle: {
          color: [textColor],
          width: 1,
          type: 'dashed',
          opacity: 0.2,
        },
      },
    }],
    series: [
      {
        // name: '直接访问',
        type: 'bar',
        barWidth: '24px',
        radius: '45%',
        itemStyle: {
          normal: {
            color: seriesColor,
          },
        },
        data: seriesData,
      },
    ],
  };
  return options;
};

const flowCityDistributionArray = (xData: string[], seriesData: string[], dates: string[]): any => {
  const dateStart = dates[0];
  const dateEnd = dates[dates.length - 1];
  const options = {
    color: optionColor,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      textStyle: {
        color: white,
        fontSize: '11px',
      },
      formatter(params: any) {
        const str = `<div style="height:62px;padding:5px 10px;"><div style="height:35px;line-height:35px"><span>开始日期</span><span style="float:right">${dateStart}</span></div><div style="height:35px;line-height:35px"><span>结束日期</span><span style="float:right">${dateEnd}</span></div><div style="height:35px;line-height:35px"><span style="display:inline-block;width:8px;height:8px;border-radius:4px;background:${seriesColor};margin-right:10px;margin-bottom:1px"></span><span>${params[0].axisValue}客流占比</span><span style="float:right;margin-left: 10px;">${params[0].value}%</span></div></div>`;
        return str;
      },
    },
    grid,
    xAxis: [
      {
        type: 'category',
        name: '',
        data: xData,
        axisLine: {
          lineStyle: {
            color: textColor,
          },
        },
        axisTick: {
          show: false,
        },
      },
    ],
    yAxis: [{
      type: 'value',
      name: percentText,
      // max: 100,
      axisLabel: {
      },
      axisLine: {
        lineStyle: {
          color: textColor,
        },
      },
      splitLine: {
        lineStyle: {
          color: [textColor],
          width: 1,
          type: 'dashed',
          opacity: 0.2,
        },
      },
    }],
    series: [
      {
        // name: '直接访问',
        type: 'bar',
        barWidth: '24px',
        radius: '45%',
        itemStyle: {
          normal: {
            color: seriesColor,
          },
        },
        data: seriesData,
      },
    ],
  };
  return options;
};

/**
 * @description: 共用echarts图表函数
 * @param {string} xData
 * @param {string} seriesData
 * @param {string} seriesData1
 * @param {string} seriesData2
 * @param {any} legendData
 * @param {any} dataObj
 * @param {number} type
 * @param {number} percent
 * @param {boolean} dataZoomStatus1
 * @param {string} barWidths
 * @param {string} stacks
 * @return {*}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const flowAnalysis = (xData: string[], seriesData: string[], seriesData1: string[], seriesData2: string[], legendData: any[], dataObj: any, type?: number, percent?: number, dataZoomStatus1?: boolean, barWidths?: string, stacks?: string): any => {
  const name = legendData[legendData.length - 1].name;
  let percents = percentText;
  if (type === 1) {
    percents = name;
  }
  const stack = stacks ? stacks : '1';
  const barWidth = barWidths ? barWidths : '25';
  const dataZoomStatus = dataZoomStatus1 ? dataZoomStatus1 : false;
  const options = {
    legend: {
      itemHeight: 14,
      itemWidth: 14,
      itemGap: 20,
      right: '55px',
      icon: 'circle',
      textStyle: {
        color: textColor,
      },
      borderRadius: 10,
      inactiveColor: '#5e5f60',
      data: legendData,
      top: '2%',
    },
    dataZoom: {
      show: dataZoomStatus,
      type: 'slider',
      bottom: '0%',
      start: 0,
      end: 100,
      textStyle: {
        color: 'white',
      },
    },
    grid: {
      bottom: '62px',
      left: '60px',
      right: '60px',
    },
    xAxis: {
      nameTextStyle: {
        fontSize: 12,
        color: textColor,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: textColor,
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        // interval: 0,
        fontSize: 12,
        rich: {
          value: {
            fontsize: 20,
          },
          img0: {
            height: 15,
            backgroundColor: { image: img },
          },
        },
        color(e: string) {
          return colorFunction(dataObj, e);
        },
        formatter(value: any) {
          const date = dayjs(dataObj[value].time).format('YYYY-MM-DD');
          return (dateInfo.find(i => i === date) || dayjs(date).day() === 0 || dayjs(date).day() === 6) && !weekendInfo.find(i => i === date) ? `{img0|}${value}` : `${value}`;
        },
      },
      data: xData,
    },
    yAxis: [{
      name: '人数',
      type: 'value',
      nameTextStyle: {
        fontSize: 12,
        color: textColor,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: textColor,
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: [textColor],
          width: 1,
          type: 'dashed',
          opacity: 0.2,
        },
      },
      axisLabel: {
        show: true,
        color: textColor,
        fontSize: 14,
      },
    }, {
      name: percents,
      nameTextStyle: {
        align: 'right',
      },
      type: 'value',
      axisLabel: {
        show: true,
        formatter: '{value}', // 以百分比显示
      },
    }],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      // backgroundColor: hoverDiscolors,
      textStyle: {
        color: white,
        fontSize: '11px',
      },
      formatter(params: any) {
        let str = '';
        const { axisValue } = params[0];
        const data = dataObj[axisValue];
        let { time } = data;
        time = time.split(' ')[0];
        let privacyStatus = 0;
        params.forEach((item: any) => {
          let { value } = item;

          if (String(value).indexOf('.') + 1) {
            if (percent === 1) {
              value = `${value}`;
            } else {
              value = `${value}%`;
            }
          }

          if (String(value) === '— —') {
            privacyStatus = 1;
          }
          str += `<div style="disply:flex;align-items:center;margin-top:6px;"><span style="display:inline-block;width:8px;height:8px;border-radius:4px;margin-right:10px;background:${item.color};"></span>${item.seriesName}<span style="font-style:normal; float: right;margin-left: 36px;">${value}</span></div>`;
        });
        let privacyStr = '';
        if (privacyStatus === 1) {
          privacyStr = `<div style='word-wrap: break-word;'>${privacy2}</div>`;
        }
        return `<div style="padding:10px;"><div style="">${privacyStr}<span>日期</span><span style="float:right">${time}</span></div>${str}</div>`;
      },
    },
    series: [{
      name: legendData[0].name,
      barWidth,
      type: 'bar',
      stack: '1',
      yAxisIndex: 0,
      itemStyle: {
        normal: {
          color: greenColor,
        },
      },
      data: seriesData1,
    }, {
      name: legendData[1].name,
      barWidth,
      type: 'bar',
      stack,
      itemStyle: {
        normal: {
          color: blueColor,
        },
      },
      data: seriesData2,
    }, {
      name: legendData[2].name,
      yAxisIndex: 1,
      type: 'line',
      itemStyle: {
        normal: {
          color: orangeColor,
        },
      },
      data: seriesData,
    }],
  };
  return options;
};

const frequencyVisits = (xData: string[], seriesData: number[], date?: string): any => {
  const seriesData2: any = [];
  // const colorAry = ['#0062FFFF', '#E34D59FF', '#00A870FF', '#EE952FFF', '#BFDBF7FF', '#70E0F6', '#945FB9FF', '#5D7092FF', '#FF99C3FF', '#FFE0C7'];
  const colorAry = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce', '#e55d8f', '#fec745', '#00c7c6', '#5d7092', '#ff8541'];
  const colorAry2: any = [];
  const legendData: any = [];
  seriesData.forEach((item: number, index: number) => {
    const obj = {
      value: item,
      name: xData[index],
      label: {
        normal: {
          show: true,
          position: 'top',
          formatter: '{d}%',
          color: colorAry[index],
        },
      },
      itemStyle: {
        normal: {
          color: colorAry[index], // 移入前的颜色
        },
        emphasis: {
          color: colorAry[index], // 移入后的颜色
        },
      },
    };
    const obj1 = {
      name: xData[index],
      itemStyle: {
        normal: {
          color: colorAry[index], // 移入前的颜色
        },
        emphasis: {
          color: colorAry[index], // 移入后的颜色
        },
      },
    };
    legendData.push(obj1);
    seriesData2.push(obj);
    colorAry2.push(colorAry[index]);
  });
  const typeLength = (seriesData2.length === 7) ? 7 : 30;
  const options = {
    legend: {
      selectedMode: false,
      textStyle: {
        color: textColor,
      },
      itemWidth: 10,
      left: 'center',
      top: 0,
      icon: 'circle',
      data: legendData,
    },
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      textStyle: {
        color: white,
        fontSize: '11px',
      },
      formatter(params: any) {
        return `<div style="height:70px;padding: 10px;"><div style="height:35px;line-height:35px"><span style='float:left;'>${date}</span></div><div style="height:35px;line-height:35px"><span style="display:inline-block;width:8px;height:8px;border-radius:4px;background:${params.color};margin-right:10px;margin-bottom:1px"></span><span>近${typeLength}天到访${params.name}</span><span style="float:right;margin-left:36px;">${params.percent}%</span></div></div>`;
      },
    },
    series: {
      type: 'pie',
      radius: ['30%', '60%'],
      center: ['50%', '50%'],
      color: colorAry2,
      data: seriesData2,
    },
  };
  return options;
};
const trendCompare = (xData1: string[], xData2: string[], seriesData1: number[], seriesData2: number[], data1Obj: { [x: string]: any; }, data2Obj: { [x: string]: any; }): any => {
  const data1s = Object.values(data1Obj);
  const data2s = Object.values(data2Obj);

  const options = {
    color: [optionColor, orangeColor],
    legend: {
      data: ['时间段1', '时间段2'],
      textStyle: {
        color: greyColor,
        fontSize: 12,
      },
      itemWidth: 10,
      // left: 'r',
      right: 60,
      // padding: [0, 50, 0, 0],
      top: 0,
      icon: 'circle',
    },
    grid,
    tooltip: {
      yAxisIndex: 0,
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      textStyle: {
        color: white,
        fontSize: '11px',
      },
      formatter(params: any) {
        let str = '';
        let privacyStatus = 0;
        let privacyStr = '';
        let { value: value1 } = params[0];
        const axisValue1 = params[0].dataIndex;
        if (value1) {
          if (String(value1).indexOf('.') + 1) {
            value1 = `${value1}%`;
          }
          if (String(value1) === '— —') {
            privacyStatus = 1;
          }
          const data1 = data1s[axisValue1];
          let time1 = data1.time;
          time1 = time1.split(' ')[0];
          str += `<div style="disply:flex;align-items:center;margin-top:10px;"><div>${params[0].seriesName}</div><span style="display:inline-block;width:8px;height:8px;border-radius:4px;margin-right:10px;background:${params[0].color};"></span>${time1}<span style="font-style:normal; float: right;margin-left: 36px;">${value1}</span></div>`;
        }
        if (params.length > 1 && params[1]?.value) {
          let { value: value2 } = params[1];
          const axisValue2 = params[1].dataIndex;
          if (String(value2).indexOf('.') + 1) {
            value2 = `${value2}%`;
          }
          if (String(value2) === '— —') {
            privacyStatus = 1;
          }
          const data2 = data2s[axisValue2];
          let time2 = data2.time;
          time2 = time2.split(' ')[0];
          str += `<div style="disply:flex;align-items:center;margin-top:10px;"><div>${params[1]?.seriesName}</div><span style="display:inline-block;width:8px;height:8px;border-radius:4px;margin-right:10px;background:${params[1].color};"></span>${time2}<span style="font-style:normal; float: right;margin-left: 36px;">${value2}</span></div>`;
        }
        if (privacyStatus === 1) {
          privacyStr = `<div style='word-wrap: break-word;'>${privacy2}</div>`;
        }
        return `<div style="padding:10px;"><div style="">${privacyStr}</div>${str}</div>`;
      },
    },
    xAxis: [
      {
        xAxisIndex: 0,
        yAxisIndex: 0,
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: data2s.length >= 20 || data1s.length >= 20 ? -30 : 0,
          rich: {
            value: {
              fontsize: 20,
            },
            img0: {
              height: 15,
              backgroundColor: { image: img },
            },
          },
          show: true,
          textStyle: {
            color(e: string, ind: number) {
              const data2s = Object.values(data2Obj);
              if (e) {
                const date = dayjs(data2s[ind].time).format('YYYY-MM-DD');
                return (dateInfo.find(i => i === date) || dayjs(date).day() === 0 || dayjs(date).day() === 6) && !weekendInfo.find(i => i === date) ? '#FF284F' : textColor;
              }
            },
            fontSize: 12,
          },
          formatter(value: any, ind: number) {
            const data2s = Object.values(data2Obj);
            if (value) {
              const date = dayjs(data2s[ind].time).format('YYYY-MM-DD');
              return (dateInfo.find(i => i === date) || dayjs(date).day() === 0 || dayjs(date).day() === 6) && !weekendInfo.find(i => i === date) ? `{img0|}${value}` : `${value}`;
            }
          },
        },
        axisTick: {
          show: false,
          alignWithLabel: true,
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: axisColor,
            type: 'dashed',
          },
        },
        axisPointer: {
          label: {
          },
        },
        data: xData2,
      },
      {
        yAxisIndex: 0,
        xAxisIndex: 1,
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: data2s.length >= 20 || data1s.length >= 20 ? -30 : 0,
          rich: {
            value: {
              fontsize: 20,
            },
            img0: {
              height: 15,
              backgroundColor: { image: img },
            },
          },
          show: true,
          textStyle: {
            // color: greyColor,
            color(e: string, ind: number) {
              const data1s = Object.values(data1Obj);
              if (e) {
                const date = dayjs(data1s[ind].time).format('YYYY-MM-DD');
                return (dateInfo.find(i => i === date) || dayjs(date).day() === 0 || dayjs(date).day() === 6) && !weekendInfo.find(i => i === date) ? '#FF284F' : textColor;
              }
            },
            fontSize: 12,
          },
          formatter(value: any, ind: number) {
            const data1s = Object.values(data1Obj);
            if (value) {
              const date = dayjs(data1s[ind].time).format('YYYY-MM-DD');
              return (dateInfo.find(i => i === date) || dayjs(date).day() === 0 || dayjs(date).day() === 6) && !weekendInfo.find(i => i === date) ? `{img0|}${value}` : `${value}`;
            }
          },
        },
        axisTick: {
          show: false,
          alignWithLabel: true,
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            type: 'dashed',
            color: axisColor,
          },
        },
        axisPointer: {
          label: {
          },
        },
        data: xData1,
      },
    ],
    yAxis: [{
      yAxisIndex: 0,
      type: 'value',
      name: '人数',
      nameTextStyle: {
        color: nameColor,
        fontSize: 12,
        fontWeight: 'bold',
        padding: [100, 70, 0, 0],
      },
      axisTick: {
        show: false,
        alignWithLabel: true,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: greyColor,
          fontSize: 12,
        },
      },
      splitLine: {
        lineStyle: {
          color: [textColor],
          width: 1,
          type: 'dashed',
          opacity: 0.2,
        },
      },
    }],
    series: [
      {
        xAxisIndex: 0,
        name: '时间段1',
        type: 'line',
        showSymbol: xData1.length === 1,
        smooth: true,
        emphasis: {
          focus: 'series',
        },
        data: seriesData1,
        itemStyle: {
          color: '#0062ff',
        },
      },
      {
        xAxisIndex: 1,
        name: '时间段2',
        type: 'line',
        showSymbol: xData2.length === 1,
        smooth: true,
        emphasis: {
          focus: 'series',
        },
        data: seriesData2,
        itemStyle: {
          color: '#ee5c3d',
        },
      },
    ],

  };
  return options;
};
const consumeLevel = (): any => {
  const option = {
    title: {
      text: '消费水平占比',
      left: 'center',
      top: 'center',
      textStyle: {
        fontSize: 12,
      },
    },
    color: ['#0062FF', '#EE5C3D', '#40C8F5', '#74D857', '#846BCE'],
    tooltip: {
      trigger: 'item',
    },
    grid,
    legend: {
      selectedMode: false,
      top: '5%',
      left: 'center',
      icon: 'circle',
      textStyle: {
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    series: [
      {
        name: '消费水平',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 1048, name: '高' },
          { value: 735, name: '较高' },
          { value: 580, name: '中' },
          { value: 484, name: '较低' },
          { value: 300, name: '低' },
        ],
      },
    ],
  };
  return option;
};
const shoppingMallArrival = (xData: string[], seriesData: number[], title: string): any => {
  const option = {
    title: {
      text: percentText,
      top: '6%',
      textStyle: {
        color: grayColor,
        fontWeight: 'normal',
        fontSize: '12px',
      },
    },
    grid: portraitGrid,
    color: '#0062FF',
    xAxis: {
      axisLine: {
        // 坐标轴线
        show: false,
      },
      axisTick: {
        // 坐标刻度
        show: false,
      },
      axisLabel: {
        interval: 0,
        // rotate: 25,
      },
      type: 'category',
      data: xData,
    },
    yAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      type: 'value',
    },
    series: [{
      data: seriesData,
      type: 'bar',
      barWidth: 20,
      itemStyle: {    // 图形的形状
        barBorderRadius: [2, 2, 0, 0],
      },
    }],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      formatter(params: any) {
        const str = `<div style="height:70px;padding: 10px;"><div style="height:35px;line-height:35px"><span>${title}</span><span style="float:right;margin-left:56px;">${params[0].name}</span></div><div style="height:35px;line-height:35px">${params[0].marker}<span>占比</span><span style="float:right">${params[0].value}%</span></div></div>`;
        return str;
      },
    },
  };
  return option;
};
const consumeAbility = (xData: string[], seriesData: number[], title: string, titles: string, colorAry: string[]): any => {
  const seriesData2: any = [];
  const colorAry2: any = [];
  const legendData: any = [];
  if (title === '是否有车') {
    seriesData.reverse();
    xData = ['有车', '无车'];
  }

  seriesData.forEach((item: number, index: number) => {
    const obj = {
      value: item,
      name: xData[index],
      itemStyle: {
        normal: {
          color: colorAry[index], // 移入前的颜色
        },
        emphasis: {
          color: colorAry[index], // 移入后的颜色
        },
      },
    };
    const obj1 = {
      name: xData[index],
      itemStyle: {
        normal: {
          color: colorAry[index], // 移入前的颜色
        },
        emphasis: {
          color: colorAry[index], // 移入后的颜色
        },
      },
    };
    legendData.push(obj1);
    seriesData2.push(obj);
    colorAry2.push(colorAry[index]);
  });
  const options = {
    legend: {
      selectedMode: false,
      textStyle: {
        // color: textColor,
        color: '#666666',
      },
      itemWidth: 10,
      left: 'center',
      top: 0,
      icon: 'circle',
      data: legendData,
    },
    title: {
      text: titles,
      left: 'center',
      top: 'center',
      textStyle: {
        fontSize: 12,
      },
    },
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          shawdowColor: hoverDiscolor,
        },
      },
      textStyle: {
        color: white,
        fontSize: '11px',
      },
      formatter(params: any) {
        const str = `<div style="width:158px;height:70px;padding: 10px;"><div style="height:35px;line-height:35px"><span>${title}</span><span style="float:right">${params.name}</span></div><div style="height:35px;line-height:35px">${params.marker}<span>占比</span><span style="float:right">${params.value}%</span></div></div>`;
        return str;
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['32%', '52%'],
        center: ['50%', '50%'],
        color: colorAry2,
        data: seriesData2,
        itemStyle: {
          opacity: 0,
        },
        label: {
          normal: {
            show: true,
            position: 'top',
            formatter: '{d}%',
            // color: colorAry[index],
            color: '#666666',
          },
        },
        labelLine: {
          show: true,
          smooth: 0,
          position: 'outside',
        },
      },
      {
        type: 'pie',
        radius: ['30%', '50%'],
        center: ['50%', '50%'],
        // color: 'transparent',
        data: seriesData2,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
      },
    ],
  };
  return options;
};
const differentSexConsumeDetails = (xData: string[], higherAry: any[], highAry: any[], centerAry: any[], lowerAry: any[], lowAry: any[]): any => {
  const obj: { name: number; num: number; }[] = [];
  for (let i = 0; i < 20; i++) {
    obj.push({ name: i, num: 0 });
  }
  highAry.forEach((i: any, index: number) => {
    if (!i) {
      obj.forEach((j: any) => {
        if (j.name === index) {
          j.num += 1;
        }
      });
    }
  });
  higherAry.forEach((i: any, index: number) => {
    if (!i) {
      obj.forEach((j: any) => {
        if (j.name === index) {
          j.num += 1;
        }
      });
    }
  });
  centerAry.forEach((i: any, index: number) => {
    if (!i) {
      obj.forEach((j: any) => {
        if (j.name === index) {
          j.num += 1;
        }
      });
    }
  });
  lowerAry.forEach((i: any, index: number) => {
    if (!i) {
      obj.forEach((j: any) => {
        if (j.name === index) {
          j.num += 1;
        }
      });
    }
  });
  lowAry.forEach((i: any, index: number) => {
    if (!i) {
      obj.forEach((j: any) => {
        if (j.name === index) {
          j.num += 1;
        }
      });
    }
  });
  const indarr: any[] = [];

  obj.forEach((i: any) => {
    if (i.num === 5) {
      indarr.push(i.name);
    }
  });
  highAry = highAry.map((i: any) => (!i ? '' : i));
  higherAry = higherAry.map((i: any) => (!i ? '' : i));
  centerAry = centerAry.map((i: any) => (!i ? '' : i));
  lowerAry = lowerAry.map((i: any) => (!i ? '' : i));
  lowAry = lowAry.map((i: any) => (!i ? '' : i));
  indarr.forEach((i: any) => {
    highAry[i] = 0;
  });

  const option = {
    color: ['#0062FF', '#EE5C3D', '#40C8F5', '#74D857', '#846BCE'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {            // Use axis to trigger tooltip
        type: 'shadow',        // 'shadow' as default; can also be 'line' or 'shadow'
      },
      formatter(params: any) {
        let str = '';
        let name = '';
        params.forEach((item: any) => {
          name = item.name;
          str += `<span style="width:150px; display:block;margin: 0 5px 0 5px;"><i style="display:inline-block;margin:5px 5px 0 0;width: 12px;height: 12px;background:${item.color};"></i>${item.seriesName}<i style="font-style:normal; float: right;">${item.value ? item.value : 0}%</i> <br /></span>`;
        });
        return `<p style="margin: 5px 5px 10px;">组别 <span style="float:right;">${name}</span></p> <i style="font-style:normal;margin-left:5px;">消费水平分布</i> <br />${str}`;
      },
    },
    legend: {
      data: [
        {
          name: '高',
          // icon:,
        },
        {
          name: '较高',

        },
        {
          name: '中',
          // icon: ‘line‘
        },
        {
          name: '较低',
          // icon: ‘line‘
        },
        {
          name: '低',
          // icon: ‘line‘
        },
      ],
      right: '3%',
      textStyle: {
        fontSize: 12,
      },
      itemWidth: 16,
      itemHeight: 16,
    },
    grid,
    yAxis: {
      axisLine: {
        // 坐标轴线
        show: false,
      },
      axisTick: {
        // 坐标刻度
        show: false,
      },
      type: 'value',
    },
    xAxis: {
      axisLine: {
        // 坐标轴线
        show: false,
      },
      axisTick: {
        // 坐标刻度
        show: false,
      },
      type: 'category',
      data: xData,
    },
    series: [
      {
        name: '高',
        type: 'bar',
        barWidth: 30,
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        data: highAry,
        markArea: {
          itemStyle: {
            color: 'rgba(255, 173, 177, 0)',
          },
          data: [[{
            name: '男性',
            xAxis: '男(≤17)',
          }, {
            xAxis: '男(≤17)',
          }], [{
            name: '女性',
            xAxis: '女(≤17)',
          }, {
            xAxis: '女(≤17)',
          }]],
        },
      },
      {
        name: '较高',
        type: 'bar',
        barWidth: 30,
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        data: higherAry,
        markArea: {
          itemStyle: {
            color: 'rgba(255, 173, 177, 0)',
          },
          data: [[{
            name: '男性',
            xAxis: '男(≤17)',
          }, {
            xAxis: '男(≤17)',
          }], [{
            name: '女性',
            xAxis: '女(≤17)',
          }, {
            xAxis: '女(≤17)',
          }]],
        },
      },
      {
        name: '中',
        type: 'bar',
        barWidth: 30,
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        data: centerAry,
        markArea: {
          itemStyle: {
            color: 'rgba(255, 173, 177, 0)',
          },
          data: [[{
            name: '男性',
            xAxis: '男(≤17)',
          }, {
            xAxis: '男(≤17)',
          }], [{
            name: '女性',
            xAxis: '女(≤17)',
          }, {
            xAxis: '女(≤17)',
          }]],
        },
      },
      {
        name: '较低',
        type: 'bar',
        barWidth: 30,
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        data: lowerAry,
        markArea: {
          itemStyle: {
            color: 'rgba(255, 173, 177, 0)',
          },
          data: [[{
            name: '男性',
            xAxis: '男(≤17)',
          }, {
            xAxis: '男(≤17)',
          }], [{
            name: '女性',
            xAxis: '女(≤17)',
          }, {
            xAxis: '女(≤17)',
          }]],
        },
      },
      {
        name: '低',
        type: 'bar',
        barWidth: 30,
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        data: lowAry,
        markArea: {
          itemStyle: {
            color: 'rgba(255, 173, 177, 0)',
          },
          data: [[{
            name: '男性',
            xAxis: '男(≤17)',
          }, {
            xAxis: '男(≤17)',
          }], [{
            name: '女性',
            xAxis: '女(≤17)',
          }, {
            xAxis: '女(≤17)',
          }]],
        },
      },
    ],
  };
  return option;
};
const differentAgeConsumeDetails = (xData: string[], manData: string[], menData: string[], type: number, formatTitle = '年龄分布', titleText = percentText, nameOne = '男', nameTwo = '女', formatval1 = '男性', formatval2 = '女性'): any => {
  const title = formatTitle;
  const newxData = deepClone(xData);
  const seriesData = [
    {
      name: nameOne,
      type: 'bar',
      barWidth: type === 4 ? 10 : 20,
      itemStyle: {
        barBorderRadius: [2, 2, 0, 0],
      },
      stack: '2',
      data: manData,
    },
    {
      name: nameTwo,
      type: 'bar',
      barWidth: type === 4 ? 10 : 20,
      itemStyle: {
        barBorderRadius: [2, 2, 0, 0],
      },
      stack: '1',
      data: menData,
    },
  ];
  const option = {
    color: type === 4 ? ['#0062FF', '#40C8F5'] : ['#0062FF', '#EE5C3D'],
    title: {
      top: 15,
      left: 40,
      text: titleText,
      textStyle: {
        color: 'rgba(0, 0, 0, 0.4)',
        fontWeight: 'normal',
        fontSize: '12px',
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter(params: any) {
        let str;
        if (type === 1) {
          str = `<div style="width:158px;height:70px;padding: 10px;">
                  <div style="height:35px;line-height:35px">
                    <span>${title}</span>
                    <span style="float:right">${params[0].name}
                  </div>
                  <div style="height:35px;line-height:35px">${params[0].marker}
                    <span>${params[0].seriesName}性占比</span><span style="float:right">${params[0].value}%</span>
                  </div>`;
          if (params.length > 1) {
            str += `<div style="height:35px;line-height:35px">${params[1]?.marker}
                      <span>${params[1]?.seriesName}性占比</span>
                      <span style="float:right">${params[1]?.value}%</span>
                    </div>`;
          }
          str += '</div>';
        } else if (type === 2) {
          str = `
          <div style="width:158px;height:70px;padding: 10px;">
            <div style="height:35px;line-height:35px">
              <span>${title}</span>
              <span style="float:right">${params[0].name}</span>
            </div>
            <div style="height:35px;line-height:35px">${params[0].marker}
              <span>男性总分</span>
              <span style="float:right">${params[0].value}</span>
            </div>
          `;
          if (params.length > 1) {
            str += `<div style="height:35px;line-height:35px">${params[1]?.marker}
            <span>女性总分</span>
            <span style="float:right">${params[1]?.value}</span>
          </div>`;
          }
          str += '</div>';
        } else if (type === 3) {
          const privacyContent = params[0].value && ((params.length > 1 && params[1]?.value) || params.length <= 1) ? '' : privacy2;
          let params0 = params[0].value ? params[0].value : '--';
          let params1 = params[1]?.value ? params[1]?.value : '--';
          if (titleText === '市场渗透率（%)') {
            if (params0 !== '--') {
              params0 = `${params0}%`;
            }
            if (params1 !== '--') {
              params1 = `${params1}%`;
            }
          }
          str = `<div style="width:308px;height:100px;padding:5px 8px">
                  <div style="height:35px;line-height:35px">
                    <span> ${title}</span>
                    <span style="float:right">${params[0].name}</span>
                  </div>
                  ${privacyContent}
                  <div style="height:35px;line-height:35px;">
                    ${params[0] ? params[0].marker : ''}
                    <span>${params[0].seriesName}</span>
                    <span style="float:right">${params0}</span>
                  </div>`;
          if (params.length > 1) {
            str += `<div style="height:35px;line-height:35px">
                    ${params[1]?.marker}
                    <span>${params[1]?.seriesName}</span>
                    <span style="float:right">${params1}</span>
                  </div>`;
          }
          str += '</div>';
        } else if (type === 4) {
          str = `
          <div style="width:308px;height:100px;padding:5px 8px">
            <div style="height:35px;line-height:35px">
              <span style="float:left">${newxData[params[0].dataIndex]}</span>
            </div>
            ${params[0].value && (params[1]?.value || params.length <= 1) ? '' : privacy2}
            <div style="height:35px;line-height:35px;">${params[0] ? params[0].marker : ''}
              <span>主场竞争客流到访率</span>
              <span style="float:right">${params[0].value ? `${params[0].value}%` : '--'}</span>
            </div>`;
          if (params.length > 1) {
            str += `<div style="height:35px;line-height:35px">${params[1]?.marker}
              <span>客场竞争客流到访率</span>
              <span style="float:right">${params[1]?.value ? `${params[1]?.value}%` : '--'}</span>
            </div>`;
          }
          str += '</div>';
        } else {
          str = `
          <div style="width:158px;height:70px;padding:5px 8px">
            <div style="height:35px;line-height:35px">
              <span> ${title}</span>
              <span style="float:right">${params[0].name}</span>\
            </div>
            <div style="height:35px;line-height:35px">${params[0] ? params[0].marker : ''}
              <span>${formatval1}</span>
              <span style="float:right">${params[0].value}</span>
            </div>`;
          if (params.length > 1) {
            str += `<div style="height:35px;line-height:35px">${params[1]?.marker}
            <span>${formatval2}</span>
            <span style="float:right">${params[1]?.value}</span>
          </div>`;
          }
          str += '</div>';
        }
        return str;
      },
    },
    legend: {
      itemHeight: 14,
      itemWidth: 14,
      right: '10%',
      data: [nameOne, nameTwo],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      axisLine: {
        // 坐标轴线
        show: false,
      },
      axisTick: {
        // 坐标刻度
        show: false,
      },
    },
    xAxis: {
      type: 'category',
      data: type === 4 ? xData.map((i: any) => `${Number(dayjs(i).format('DD')).toString()}日`) : xData,
      axisLine: {
        // 坐标轴线
        show: false,
      },
      axisTick: {
        // 坐标刻度
        show: false,
      },
      axisLabel: {
        rotate: 0,
        margin: 8,
      },
    },
    series: seriesData,
  };
  return option;
};
const touristFlowRatio = (blueName: string, orangeName: string, xdata: string[], blueData: string[], orangeData: string[], xAxisName: string): any => {
  if (xAxisName === '周') {
    xdata = xdata.map((i: any) => dayjs(i).subtract(6, 'day')
      .format('YYYY-MM-DD'));
  }
  const option = {
    title: {
      top: 18,
      text: percentText,
      textStyle: {
        fontSize: 12,
        color: greyColor,
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter(params: any) {
        if (xAxisName === '日') {
          let res = ` <div style="width:270px;height:120px;padding: 10px;">
        <div style="height:35px;line-height:35px">
        <span>日期</span><span style="float:right">${params[0].name}</span>
        </div>
        <div style="height:35px;line-height:35px">
        ${params[0].marker}
        <span>${params[0].seriesName}占比</span>
        <span style="float:right">${params[0].value && !isNaN(params[0].value) ? `${params[0].value}%` : '暂无数据'}</span>
        </div>
        `;
          if (params.length > 1) {
            res += `<div style="height:35px;line-height:35px">
          ${params[1]?.marker}
          <span>${params[1]?.seriesName}占比</span>
          <span style="float:right">${params[1]?.value && !isNaN(params[1]?.value) ? `${params[1]?.value}%` : '暂无数据'}</span>`;
          }
          res += '</div></div>';
          return res;
        }
        if (xAxisName === '周') {
          let res = ` <div style="width:270px;height:120px;padding: 10px;">
        <div style="height:35px;line-height:35px">
        <span>周</span><span style="float:right">${params[0].name} ~ ${(dayjs(params[0].name).add(6, 'day') as any).format('YYYY-MM-DD')}</span>
        </div>
        <div style="height:35px;line-height:35px">
        ${params[0].marker}
        <span>${params[0].seriesName}占比</span>
        <span style="float:right">${params[0].value && !isNaN(params[0].value) ? `${params[0].value}%` : '暂无数据'}</span>
        </div>
        <div style="height:35px;line-height:35px">
        `;
          if (params.length > 1) {
            res += `${params[1]?.marker}
          <span>${params[1]?.seriesName}占比</span>
          <span style="float:right">${params[1]?.value && !isNaN(params[1]?.value) ? `${params[1]?.value}%` : '暂无数据'}</span>`;
          }
          res += '</div></div>';
          return res;
        }
        if (xAxisName === '月') {
          let res = ` <div style="width:270px;height:120px;padding: 10px;">
          <div style="height:35px;line-height:35px">
          <span>月份</span><span style="float:right">${params[0].name}</span>
          </div>
          <div style="height:35px;line-height:35px">
          ${params[0].marker}
          <span>${params[0].seriesName}占比</span>
          <span style="float:right">${params[0].value && !isNaN(params[0].value) ? `${params[0].value}%` : '暂无数据'}</span>
          </div>
          <div style="height:35px;line-height:35px">
          `;
          if (params.length > 1) {
            res += `${params[1]?.marker}
            <span>${params[1]?.seriesName}占比</span>
            <span style="float:right">${params[1]?.value && !isNaN(params[1]?.value) ? `${params[1]?.value}%` : '暂无数据'}</span>`;
          }
          res += '</div></div>';
          return res;
        }
        let res = ` <div style="width:270px;height:120px;padding: 10px;">
        <div style="height:35px;line-height:35px">
        <span>日期</span><span style="float:right">${params[0].name}</span>
        </div>
        <div style="height:35px;line-height:35px">
        ${params[0].marker}
        <span>${params[0].seriesName}占比</span>
        <span style="float:right">${params[0].value && !isNaN(params[0].value) ? `${params[0].value}%` : '暂无数据'}</span>
        </div>
        <div style="height:35px;line-height:35px">
        `;
        if (params.length > 1) {
          res += `${params[1]?.marker}
          <span>${params[1]?.seriesName}占比</span>
          <span style="float:right">${params[1]?.value && !isNaN(params[1]?.value) ? `${params[1]?.value}%` : '暂无数据'}</span>`;
        }
        res += '</div></div>';
        return res;
      },
    },
    color: ['#0062FF', '#EE5C3D'],
    legend: {
      data: [blueName, orangeName],
      itemWidth: 10,
      left: 'right',
      padding: [0, 50, 0, 0],
      top: 10,
      icon: 'circle',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        // interval: 0,
        // rotate: -45,
        show: true,
        textStyle: {
          // color: greyColor,
          color(e: string) {
            return (dateInfo.find(i => i === e) || dayjs(e).day() === 0 || dayjs(e).day() === 6) && !weekendInfo.find(i => i === e) ? '#FF284F' : textColor;
          },
          fontSize: 12,
        },
        formatter(value: any) {
          return (dateInfo.find(i => i === value) || dayjs(value).day() === 0 || dayjs(value).day() === 6) && !weekendInfo.find(i => i === value) ? `{img0|}${value}` : `${value}`;
        },
        rich: {
          value: {
            fontsize: 20,
          },
          img0: {
            height: 15,
            backgroundColor: { image: img },
          },
        },
      },
      type: 'category',
      name: xAxisName,
      nameLocation: 'end',
      nameTextStyle: {
      },
      boundaryGap: true,
      data: xAxisName === '月' ? xdata.map((i: any) => dayjs(i).format('YYYY[年]MM[月]')) : xdata,
    },
    yAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      type: 'value',
    },
    series: [
      {
        name: blueName,
        type: 'line',
        // stack: '总量',
        data: blueData,
        symbolSize: 8,
        symbol: 'circle',
      },
      {
        name: orangeName,
        type: 'line',
        // stack: '总量',
        data: orangeData,
        symbolSize: 8,
        symbol: 'circle',
      },
    ],
  };
  return option;
};
const marketPenetration = (xdata: string[], dataOne: string[], dataTwo: string[], nameOne: string, nameTwo: string, text: string, type?: number): any => {
  const option = {
    title: {
      text,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        type: 'shadow',        // 默认为直线，可选为：'line' | 'shadow'
      },
      formatter(params: any) {
        if (type === 1) {
          if (params[0].axisValue.includes('女')) {
            return `${params[0]?.name}<br/>
              ${params[0].value || params[1]?.value || params.length <= 1 ? '' : `${privacy2}<br/>`}
              ${text} : 
              ${params[0].value || params[1]?.value || '--'}`;
          }
          return `${params[0].name}<br/>${params[0].value || params.length <= 1 ? '' : `${privacy2}<br/>`}${text} : ${(params[0].value ? params[0].value : '--')}`;
        } if (type === 2) {
          return `${params[0].name || params[1]?.name}<br/>${text} : ${params[0].value || params[1]?.value || '--'}`;
        }

        return `${params[0].name || params[1]?.name}<br/>
                ${(params[0].name || params[1]?.name).includes('男') ? params[0].seriesName : params[1]?.seriesName || '--'} : 
                ${(params[0].value ? params[0].value : 0) || (params[1]?.value ? params[1]?.value : 0)}`;
      },
    },
    legend: {
      data: [nameOne, nameTwo],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      splitLine: { show: false },
      data: xdata,
      axisLabel: {
        margin: 30,
        align: 'center',
        interval: 0,
        rotate: 50,
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: nameOne,
        type: 'bar',
        stack: '总量',
        data: dataOne,
      },
      {
        name: nameTwo,
        type: 'bar',
        stack: '总量',
        data: dataTwo,
      },
    ],
  };
  return option;
};

const stackBarOption = (xdata: string[], dataOne: string[], dataTwo: string[], nameOne: string, nameTwo: string, text: string, type?: number): any => {
  const typeText = type === 6 ? '竞品排名' : '主场排名';
  const option = {
    title: {
      text,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        type: 'shadow',        // 默认为直线，可选为：'line' | 'shadow'
      },
      formatter(value: any) {
        const name1 = value[0].seriesName;
        const rank = value[0].axisValueLabel;
        const value1 = value[0].value;
        const marker1 = value[0].marker;
        const name2 = value[1].seriesName;
        const value2 = value[1].value;
        const marker2 = value[1].marker;
        const str = `<div style='padding:10px;'>
          <div>${typeText}:${rank}</div>
          <div style='display:flex;justify-content: space-between;margin-top:10px;'>
            <div>
              ${marker1}
              ${name1}
            </div>
            <div style='margin-left:30px;'>
              ${value1}人
            </div>
          </div>
          <div style='display:flex;justify-content: space-between;margin-top:10px;'>
            <div>
              ${marker2}
              ${name2}
            </div>
            <div style='margin-left:30px;'>
              ${value2}人
            </div>
          </div>
        </div>`;
        return str;
      },
    },
    dataZoom: [
      {
        show: true,
        type: 'slider',
        bottom: '0%',
        start: 0,
        end: 10,
        textStyle: {
          color: 'white',
        },
      },
    ],
    legend: {
      // inactiveColor: '#5e5f60',
      align: 'left',
      right: '16%',
      top: 20,
      // textStyle: {
      //   color: '#fff',
      // },
      itemWidth: 20,
      itemHeight: 20,
      itemGap: 20,
      data: [nameOne, nameTwo],
    },
    grid: {
      left: '0%',
      right: '12%',
      top: '10%',
      minWidth: '1100px',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      splitLine: { show: false },
      data: xdata,
      axisLabel: {
        align: 'center',
      },
      name: typeText,
      nameTextStyle: {
        fontSize: 16,
      },
      triggerEvent: true,
    },
    yAxis: {
      type: 'value',
      name: '客流人数',
    },
    series: [
      {
        name: nameOne,
        type: 'bar',
        itemStyle: {
          color: '#0062FF',
          barBorderRadius: [2, 2, 0, 0],
        },
        stack: '1',
        data: dataOne,
      },
      {
        name: nameTwo,
        type: 'bar',
        itemStyle: {
          color: '#40C8F5',
          barBorderRadius: [2, 2, 0, 0],
        },
        stack: '2',
        data: dataTwo,
        barGap: 0,
      },
    ],
  };
  return option;
};
export {
  marketPenetration,
  touristFlowRatio,
  activePassengerFlow,
  lengthStayHour,
  frequencyVisits,
  flowAnalysis,
  flowCityDistribution,
  flowCityDistributionArray,
  numRankTrend,
  trendCompare,
  consumeLevel,
  shoppingMallArrival,
  consumeAbility,
  differentSexConsumeDetails,
  differentAgeConsumeDetails,
  stackBarOption,
};
