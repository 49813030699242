























import { Vue, Prop, Component } from 'vue-property-decorator';


@Component({
  components: {},
})
export default class GradientCard extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '1平方公里',
  })
  text!: string;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  titleDisplay!: boolean;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  singleColor!: string;
  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  gradientList!: [];
  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  gradientValue!: [];
}
