import { RouterConfig } from '@/types/router';
const intensity: RouterConfig = {
  path: '/system/intensity',
  name: '竞争烈度',
  meta: {
    id: '6',
    isShow: true,
  },
  component: () => import('@/pages/systemPages/blank/index.vue'),
  children: [
    {
      path: '/system/intensity/regional',
      name: '竞争区域分析',
      icon: 'm-icon-a-Group6397',
      meta: {
        id: '6-1',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/intensity/regional/index.vue'),
    },
    {
      path: '/system/intensity/analysis1',
      name: '竞争客流分析',
      icon: 'm-icon-a-Group6340',
      meta: {
        id: '6-2',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/intensity/analysis1/index.vue'),
      children: [
        {
          path: '/system/intensity/analysis1/comparison',
          name: '竞争客流对比',
          meta: {
            id: '6-2-1',
            isShow: true,
          },
          component: () => import('@/pages/systemPages/intensity/analysis1/comparison/index.vue'),
        },
        {
          path: '/system/intensity/analysis1/portrait',
          name: '竞争客流画像',
          meta: {
            id: '6-2-2',
            isShow: true,
          },
          component: () => import('@/pages/systemPages/intensity/analysis1/portrait/index.vue'),
        },
        {
          path: '/system/intensity/analysis1/distributed',
          name: '竞争客流分布',
          meta: {
            id: '6-2-3',
            isShow: true,
          },
          component: () => import('@/pages/systemPages/intensity/analysis1/distributed/index.vue'),
        },
      ],
    },
    {
      path: '/system/intensity/map',
      name: '暂无竞品',
      icon: 'icon-platform',
      meta: {
        id: '6-3',
        isShow: false,
      },
      component: () => import('@/pages/systemPages/intensity/map/index.vue'),
    },
  ],
};
export default intensity;
