/* eslint-disable @typescript-eslint/naming-convention */
import { getAxiosDatas, getAxiosDataNode } from '@/services/apiConfig';
import { Result } from '@/types/services';
const baseUrl = 'login_api/';
/*
* 接口类型：Post
* 编码类型：Content-Type:application/json
*/

// 检测是否登录成功 重构后已废弃！
// async function judingLogin(params = {}): Promise<any> {
//   const url = 'judingLogin';
//   const getData = await getAxiosData(params, url, baseUrl);
//   const res = getData as Result<any>;
//   return res;
// }

// 注销登录接口
async function logout(params = {}): Promise<any> {
  const url = 'logout';
  const getData = await getAxiosDatas(params, url, baseUrl);
  const res = getData as Result<any>;
  return res;
}

// 当前用户登录信息查询接口 & 检测是否正常登录
// 后端php
async function loginUser(): Promise<any> {
  const params2 = {
    user_log_status: 0,
  };
  const url = 'loginUser';
  const getData = await getAxiosDatas(params2, url, baseUrl);
  const res = getData as Result<any>;
  return res;
}
// 当前用户登录信息查询接口 & 检测是否正常登录
// 后端node后
async function loginUserNode(): Promise<any> {
  const params2 = {
    user_log_status: JSON.parse(localStorage.getItem('loginStatus') || '0'),
  };
  const url = 'login/success';
  const getData = await getAxiosDataNode(params2, url);
  const res = getData as Result<any>;
  return res;
}

export {
  logout,
  loginUser,
  loginUserNode,
};
