







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SpinCard extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  spinShow!: false;
}
