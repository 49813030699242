import { RouterConfig } from '@/types/router';
import sourcePage from '@/pages/systemPages/source/index.vue';
const source: RouterConfig = {
  path: '/system/source',
  name: '客流来源',
  meta: {
    id: '4',
    isShow: true,
  },
  component: () => import('@/pages/systemPages/blank/index.vue'),
  children: [
    {
      path: '/system/source/tourist',
      name: '客源地排行',
      icon: 'm-icon-a-Group6396',
      meta: {
        id: '4-1',
        isShow: true,
      },
      component: sourcePage,
      children: [
        {
          path: '/system/source/tourist/live',
          name: '居住地排行',
          meta: {
            id: '4-1-1',
            isShow: true,
          },
          component: sourcePage,
        },
        {
          path: '/system/source/tourist/work',
          name: '工作地排行',
          meta: {
            id: '4-1-2',
            isShow: true,
          },
          component: sourcePage,
        },
        {
          path: '/system/source/tourist/crossing',
          name: '过路地排行',
          meta: {
            id: '4-1-3',
            isShow: true,
          },
          component: sourcePage,
        },
      ],
    },
    {
      path: '/system/source/depression',
      name: '洼地排行',
      icon: 'm-icon-a-Group6293',
      meta: {
        id: '4-2',
        isShow: true,
      },
      component: sourcePage,
      children: [
        {
          path: '/system/source/depression/live',
          name: '居住地排行-洼地排行',
          meta: {
            id: '4-2-1',
            isShow: true,
          },
          component: sourcePage,
        },
        {
          path: '/system/source/depression/work',
          name: '工作地排行-洼地排行',
          meta: {
            id: '4-2-2',
            isShow: true,
          },
          component: sourcePage,
        },
        {
          path: '/system/source/depression/crossing',
          name: '过路地排行-洼地排行',
          meta: {
            id: '4-2-3',
            isShow: true,
          },
          component: sourcePage,
        },
      ],
    },
    {
      path: '/system/source/touristTop',
      name: 'TOP客源地分析',
      icon: 'm-icon-a-Group6398',
      meta: {
        id: '4-3',
        isShow: true,
      },
      component: sourcePage,
      children: [
        {
          path: '/system/source/touristTop/city',
          name: '本市客流排行',
          meta: {
            id: '4-3-1',
            isShow: true,
          },
          component: sourcePage,
        },
        {
          path: '/system/source/touristTop/portrait',
          name: '自定义客流画像',
          meta: {
            id: '4-3-2',
            isShow: true,
          },
          component: sourcePage,
        },
      ],
    },
  ],
};
export default source;
