






























/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Vue, Component } from 'vue-property-decorator';
import arrival from '@/components/portrait/arrival/index.vue';
import economy from '@/components/portrait/economy/index.vue';
import natural from '@/components/portrait/natural/index.vue';
import portraitMenu from '@/components/portrait/portraitMenu/index.vue';
import exportJsonDataExcel from '@/utils/exportToExcel';
@Component({
  components: {
    portraitMenu,
    arrival,
    economy,
    natural,
  },
})
export default class extends Vue {
  list = [
    {
      name: '自然属性',
      buriedPoint: 'aoi_competition_image_basic_click',
      component: natural,
    },
    {
      name: '财富属性',
      buriedPoint: 'aoi_image_wealth_click',
      component: economy,
    },
    {
      name: '到访偏好',
      buriedPoint: 'aoi_competition_image_visit_click',
      component: arrival,
    },
  ];
  currentVal = 0;
  currentItem = this.list[this.currentVal];
  downloadStatus = false;
  checkMenulist(val: number): void{
    const item = this.list[val];
    const { name, buriedPoint } = item;
    this.currentItem = item;
    this.buriedPointHandler(buriedPoint, { type: name });
  }

  get portraitComponent(): any {
    return this.currentItem.component;
  }

  closeClickHandler(): void {
    this.$emit('updateCloseStatus', false);
  }

  buriedPointHandler(buriedPoint: string, params?: any): void{
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  // 导出公共代码
  downloadHandlerExcel(data: any): void {
    const routeParentName = this.$route.matched[1].name;
    const routeName = this.$route.name;
    const { mallName, durationText, dates, aoiTypeCurrentObj } = this.$store.state.mall;
    const { name } = this.currentItem;
    const name2 = aoiTypeCurrentObj.name ? aoiTypeCurrentObj.name : '居住地';
    const file = `${routeName}-${name}-${name2}`;
    const fileName = `${routeParentName}-${file}-${dates}数据导出`;

    this.buriedPointHandler('aoi_portrait_export', {
      fileName,
      submenu: name,
    });
    const dateStr = `${durationText}:${dates}`;
    const title = `数据项:客流来源-${file}`;
    data.forEach((item: any, index: number) => {
      const mallNames = `区域：${mallName}`;
      if (!data[index].sheetMultiHeader) {
        // eslint-disable-next-line no-param-reassign
        data[index].sheetMultiHeader = [];
      }
      const { sheetMultiHeader } = data[index];

      sheetMultiHeader.push([title], ['']);
      sheetMultiHeader.push([dateStr], ['']);
      sheetMultiHeader.unshift([mallNames]);
    });
    const option = data;
    if (option) {
      exportJsonDataExcel(option, fileName);
    }
  }

  // 导出excel
  downloadHandler(): void {
    this.downloadStatus = true;
    this.$stores.global.setAoiDownloadStatus(this.downloadStatus);
    this.$root.$data.event.$off('getAoiData');
    this.$root.$data.event.$on('getAoiData', (data: any) => {
      this.downloadHandlerExcel(data);
    });
    setTimeout(() => {
      this.downloadStatus = false;
      this.$stores.global.setAoiDownloadStatus(this.downloadStatus);
    }, 20);
  }
}
