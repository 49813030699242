/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import dayjs, { Dayjs } from 'dayjs';

export function deepClone(obj: any): any {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  // 根据obj的类型判断是新建一个数组还是对象
  const newObj = obj instanceof Array ? [] : {};
  // eslint-disable-next-line
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      (newObj as any)[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key];
    }
  }
  return newObj;
}

/**
 * @date 2020-06-22
 * @desc   输入一个数返回千位符形式
 * @param  {any} number
 * @return {any} number
 * @author kuashimu@tencent.com
 */
export function kiloBitNum(val: number): any {
  if (typeof val === 'number') {
    return val < 1000 ? val : String(val).replace(/(?=(\B\d{3})+$)/g, ',');
  }
  return 0;
}


// 将每周的开始时间设置为周一
export const startOfWeek = function (date: Dayjs, format = 'YYYYMMDD') {
  const tempDay = dayjs(date);
  let monday = tempDay.startOf('week')
    .add(1, 'day');
  if (tempDay.day() === 0) {
    monday = monday.subtract(7, 'day');
  }
  return monday.format(format);
};

// 设置每周的结束时间为周日
export const endOfWeek = function (date: Dayjs, format = 'YYYYMMDD') {
  const tempDay = dayjs(date);
  let sunday = tempDay.endOf('week')
    .add(1, 'day');
  if (tempDay.day() === 0) {
    sunday = sunday.subtract(7, 'day');
  }
  return sunday.format(format);
};
