






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { getContainer } from '@/components';
import { mallRankListInfo } from '@/config/global';
@Component({
  components: {},
})
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
export default class extends Vue {
  @Prop({
    type: Array,
    required: false,
  })
  data!: [];

  @Prop({
    type: Number,
    required: true,
  })
  mallId!: number;

  @Prop({
    type: String,
    required: true,
  })
  mallName!: string;

  @Prop({
    type: String,
    required: true,
  })
  privacyInfo!: string;

  tableData: any = [];
  columns1: any = [];
  columns: any = [];
  height = 0;
  changeData(): void {
    const height = getContainer();
    this.height = height - 162;
    const masterData: any[] = [];
    const datas: any[] = [];
    this.data.forEach((item: any) => {
      // eslint-disable-next-line radix, no-param-reassign
      item.mall_id = parseInt(item.mall_id);
      const { mall_id: mallId } = item;
      if (mallId === this.mallId) {
        masterData.push(item);
      } else {
        datas.push(item);
      }
    });
    if (masterData.length === 0) {
      // 异常情况处理
      const objs: any = {};
      for (let index = 1; index <= 33; index++) {
        objs[index] = { rank: 0, value: 0, type: index };
      }
      const obj = {
        mall_id: this.mallId,
        name: this.mallName,
        obj: objs,
      };
      masterData.push(obj);
    }
    const data: any = [...masterData, ...datas];
    const competedData: any = [];
    const columnsObj: any = {};
    // 处理横向数据，变为表格接受的数据
    for (let index = 1; index <= 33; index++) {
      const objTmp: any = {};
      data.forEach((item: any, indexs: number) => {
        const { obj, name, mall_id } = item;
        const data = obj[index];
        const index2 = indexs + 1;
        const rankField = `rank${index2}`;
        const valueField = `value${index2}`;
        objTmp[rankField] = data.rank;
        objTmp[valueField] = data.value;
        columnsObj[rankField] = { rankField, valueField, name, mall_id };
      });
      const titleData = mallRankListInfo[index - 1];
      const { category, name, displayType } = titleData;
      objTmp.category = category;
      objTmp.name = name;
      objTmp.displayType = displayType;
      competedData.push(objTmp);
    }
    // 生成表头
    const cloumns: any = [
      {
        title: '分析维度',
        align: 'center',
        fixed: 'left',
        ellipsis: true,
        children: [
          {
            title: '1222222',
            key: 'category',
            align: 'center',
            width: 70,
            fixed: 'left',
            renderHeader: (h: any) => h(
              'div',
              '',
            ),
            render: (h: any, params: any) => {
              const { category } = params.row;
              return h(
                'div',
                {
                  attrs: {
                    class: 'writing-mode',
                  },
                  domProps: {
                    innerText: category,
                  },
                },
                // category,
              );
            },
          },
          {
            title: '维度名称',
            key: 'name',
            align: 'left',
            width: 270,
            fixed: 'left',
          },
        ],
      },
    ];
    const rankWidth = 100;
    const valueWidth = 200;
    const lth = Object.values(columnsObj).length;
    Object.values(columnsObj).forEach((item: any) => {
      const { rankField, valueField, name, mall_id } = item;
      const fixed = mall_id === this.mallId ? 'left' : '';
      const obj: any = {
        title: name,
        align: 'center',
        fixed,
        children: [
          {
            title: '全市排名',
            key: rankField,
            align: 'center',
            fixed,
          },
          {
            title: '数据详情',
            key: valueField,
            align: 'center',
            fixed,
            render: (h: any, params: any) => {
              const { displayType } = params.row;
              const valueField2 = params.row[valueField];
              // eslint-disable-next-line no-nested-ternary
              const str = displayType === 0 ? `${valueField2}人`
                : displayType === 1 ? `${valueField2}%` : `${valueField2}平方公里`;

              return h(
                'div',
                {
                  style: 'text-align:center',
                  domProps: {
                    innerText: str,
                  },
                },
              );
            },
          },
        ],
      };
      if (lth > 4) {
        obj.children[0].width = rankWidth;
        obj.children[1].width = valueWidth;
      }
      cloumns.push(obj);
    });
    this.columns = cloumns;
    this.tableData = competedData;
  }

  handleSpan(data: any) {
    const { rowIndex, columnIndex } = data;
    let obj = {};
    if (rowIndex === 0 && columnIndex === 0) {
      obj = {
        rowspan: 4,
        colspan: 1,
      };
    } else if (rowIndex === 4 && columnIndex === 0) {
      obj = {
        rowspan: 7,
        colspan: 1,
      };
    } else if (rowIndex === 11 && columnIndex === 0) {
      obj = {
        rowspan: 3,
        colspan: 1,
      };
    } else if (rowIndex === 14 && columnIndex === 0) {
      obj = {
        rowspan: 3,
        colspan: 1,
      };
    } else if (rowIndex === 17 && columnIndex === 0) {
      obj = {
        rowspan: 3,
        colspan: 1,
      };
    } else if (rowIndex === 20 && columnIndex === 0) {
      obj = {
        rowspan: 7,
        colspan: 1,
      };
    } else if (rowIndex === 27 && columnIndex === 0) {
      obj = {
        rowspan: 6,
        colspan: 1,
      };
    } else if (columnIndex === 0) {
      obj = {
        rowspan: 0,
        colspan: 0,
      };
    }
    return obj;
  }

  closeClickHandler(): void {
    this.$emit('updateMallInfoStatus', false);
  }
}
