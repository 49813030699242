const oldDomainObj = {
  formal: {
    domain: 'heat.qq.com',
    url: '/api/v1/',
    // url: 'https://lbsconsole.map.qq.com/api/v1/',
    platFormUrl: 'https://heat.qq.com/mall/index.php?login_type=new',
    dmpUrl: 'https://lbs.qq.com/dmp/index.php',
  },
  develop: {
    domain: 'mall.sparta.html5.qq.com',
    url: '/api/v1/',
    // url: 'http://test-mall.innovation.qq.com/api/v1/',
    platFormUrl:
      'https://mall.sparta.html5.qq.com/mall/index.php?login_type=new',
    dmpUrl: 'https://lbs.cs0309.3g.qq.com/dmp/index.php',
  },
};

const domainName = {
  formal: 'mall.lbs.qq.com',
  pre: 'mall.innovation.qq.com',
  develop: 'test-mall.innovation.qq.com',
  local: 'local.qq.com',
};
const { formal, pre, develop, local } = domainName;
const { formal: formalObj, develop: testObj } = oldDomainObj;

const domainObj = {
  [formal]: {
    domain: formalObj.domain,
    server: formal,
    test: '正式',
    local: 'formal',
    url: formalObj.url,
    platFormUrl: formalObj.platFormUrl,
    dmpUrl: formalObj.dmpUrl,
    env: 'web_online',
  },
  [pre]: {
    domain: formalObj.domain,
    server: pre,
    test: '预发布',
    local: 'formal',
    url: formalObj.url,
    platFormUrl: formalObj.platFormUrl,
    dmpUrl: formalObj.dmpUrl,
    env: 'web_pre',
  },
  [develop]: {
    domain: testObj.domain,
    server: develop,
    test: '测试',
    local: 'test',
    url: testObj.url,
    platFormUrl: testObj.platFormUrl,
    dmpUrl: testObj.dmpUrl,
    env: 'web_dev',
  },
  [local]: {
    domain: testObj.domain,
    server: local,
    test: '测试',
    local: 'test',
    url: testObj.url,
    platFormUrl: testObj.platFormUrl,
    dmpUrl: testObj.dmpUrl,
    env: 'web_local',
  },
};
const hostName = window.location.hostname;
const domainConfiNode = domainObj[hostName];
export { domainConfiNode };
