/* eslint-disable @typescript-eslint/naming-convention */
import summary from './summary';
import portrait from './portrait';
import business from './business';
import source from './source';
import environment from './environment';
import intensity from './intensity';
import report from './report';

const systemRouter = [
  summary,
  portrait,
  business,
  source,
  environment,
  intensity,
  report,
];

export default systemRouter;
