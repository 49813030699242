import route, { resetRouter } from './router';
import { loginUserNode, loginUser } from '@/services/loginApi';
import { isJudgmentType } from '@/components/valiDate';
import './store';
import { Vue } from 'vue-property-decorator';
import { permissionModule } from '@/store/modules/permission';
import { userModule } from '@/store/modules/user';
import mappingRouter from './router/mappingRouter';
const that = Vue.prototype;

async function initPermission(): Promise<any> {
  const path = window.location.href;
  const res1: any = await loginUserNode();
  const res = await loginUser();
  if (res1.status === 0) {
    localStorage.setItem('authorization', res1.data.authorization);
  }
  console.log('loginUserNode', res1);
  const typeObj = isJudgmentType(res);
  if (typeObj.type === 2) {
    // 对象
    if (res.status === 0 && res1.status === 0) {
      const { data } = res;
      const { page_url: pageUrl, privilege, mid } = data;
      const pageUrlObj = pageUrlHandler(pageUrl);
      if (Object.values(pageUrlObj).length === 0) {
        if (!path.includes('/index')) {
          route.push('/index');
        }
        const title = '客留通';
        const content = '抱歉，该账号尚未配置权限，请联系我们配置后再使用。';
        that.$Modal.info({
          title,
          content,
          onOk: async () => {
            console.log(content);
          },
        });
      } else if (mid !== 1) {
        if (!path.includes('/index')) {
          route.push('/index');
        }
        that.$Modal.info({
          title: '客留通',
          content: '企业服务已到期，如需开通，请联系商务同学',
          onOk: async () => {
            console.log('企业服务已到期，如需开通，请联系商务同学');
          },
        });
      } else {
        // 根据用户角色，创建角色路由
        if (Number(privilege) === 1) {
          pageUrlObj.details = 'details';
          pageUrlObj.user = 'user';
          pageUrlObj.monitor = 'monitor';
          pageUrlObj.system = 'system';
          pageUrlObj.service = 'service';
          pageUrlObj.services = 'services';
        } else if (Number(privilege) === 2) {
          pageUrlObj.details = 'details';
        } else if (Number(privilege) === 3) {
          pageUrlObj.details = 'details';
        } else {
          console.log('角色异常，暂无该角色');
        }
        // 客流来源-客源地分析
        // 特殊权限鉴权
        const lbsDmp = pageUrlObj.lbsDmp ? 1 : 0;
        const aoi = pageUrlObj.source ? 1 : 0;
        const competition = pageUrlObj.competition ? 1 : 0;
        if (Object.prototype.hasOwnProperty.call(pageUrlObj, 'regional') || Object.prototype.hasOwnProperty.call(pageUrlObj, 'analysis1')) pageUrlObj.map = 'map';
        permissionModule.setLbsDmp(lbsDmp);
        permissionModule.setAddAoi(aoi);
        permissionModule.setCompetition(competition);
        permissionModule.generateRoutes(pageUrlObj);
        resetRouter(permissionModule.routes);
      }
      userModule.loginUsers(data);
    } else {
      userModule.LogOut();
    }
  } else if (typeObj.type === 3) {
    // 字符串
    if (!path.includes('/index')) {
      route.push('/index');
    }
  } else {
    userModule.LogOut();
  }
}

// 路由获取
function pageUrlHandler(pageUrl: any) {
  const pageUrlObj: any = {};
  for (const [key, val] of Object.entries(pageUrl)) {
    if (Number(val) === 1) {
      const path = mappingRouter[key];
      pageUrlObj[path] = path;
    }
  }
  return pageUrlObj;
}

export { initPermission };
