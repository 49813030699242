import Vue from 'vue';
import VueBus from 'vue-bus';
import App from './App.vue';
import router from './router';
import store from './store';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import tdesign from '@tencent/tdesign-vue';
import '@tencent/tdesign-vue/dist/tdesign.css';
import VueEcharts from 'vue-echarts-ts';
import axios from 'axios';
import global from './config/global';
import dayjs from 'dayjs';
import Element from 'element-ui';
import jQuery from 'jquery';
import 'element-ui/lib/theme-chalk/index.css';
import card from '@/components/CompareCard/index.vue';
import md5 from 'js-md5';
import VueCookies from 'vue-cookies';
import VueRouter from 'vue-router';
import Mall from '@/store/modules/mall';
import Globals from '@/store/modules/global';
import PageConfig from '@/store/modules/page-config';
import { userModule } from '@/store/modules/user';
import { getModule } from 'vuex-module-decorators';
import { domainConfig } from '@/config/domain';
import beaconAction from '@/utils/beaconAction';
import htmlToPdf from '@/utils/pdfInfo';
import * as directives from '@/directives/index';
Vue.use(htmlToPdf);

const globals = getModule(Globals);
const mall = getModule(Mall);
const pageConfig = getModule(PageConfig);
Vue.component('mall-card', card);
Vue.use(VueBus);
Vue.use(VueCookies);
Vue.use(ViewUI);
Vue.use(tdesign);
Vue.use(VueEcharts);
Vue.use(Element);
axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
  try {
    // 带授权信息
    const data = localStorage.getItem('authorization') || '';
    if (data && config.url && !config.url.includes('https://lbsconsole.sparta.html5.qq.com') && !config.url.includes('https://lbsconsole.map.qq.com/')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = data;
    }
  } catch (e) {
    // todo
  }
  return config;
});
Vue.prototype.$axios = axios;
Vue.prototype.$GLOBAL = global;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$jQ = jQuery;
Vue.prototype.$md5 = md5;
Vue.prototype.$routers = router;
Vue.prototype.$domainConfig = domainConfig;
Vue.prototype.$stores = {
  global: globals,
  mall,
  pageConfig,
  userModule,
};
Vue.prototype.$beaconAction = beaconAction;
console.log(Vue.prototype, 'Vue.prototype');

// 禁止用浏览器修改storage
window.addEventListener('storage', (e: any) => {
  sessionStorage.setItem(e.key, e.oldValue);
});

// 路由push报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: any) {
  return (originalPush.call(this, location) as any).catch((err: any) => err);
};
console.log(directives);
// Register global directives
Object.keys(directives).forEach((key) => {
  Vue.directive(key, (directives as any)[key]);
});

new Vue({
  router,
  store,
  render: h => h(App),
  data: {
    event: new Vue(),
  },
}).$mount('#app');

