


































import { Vue, Component, Prop } from 'vue-property-decorator';
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

@Component({
  components: {},
})
export default class ShowMenu extends Vue {
  @Prop({
    type: Array,
    required: false,
  })
  data!: [];
  @Prop({
    type: String,
    required: false,
  })
  dataName!: '';
  @Prop({
    type: String,
    required: false,
  })
  searchName!: '';
  @Prop({
    type: String,
    required: false,
  })
  buriedPoint!: '';
  searchArr = [];
  value = '';
  search = true;
  searchMallName(val: string): void {
    this.searchArr = [];
    if (val.trim()) {
      this.data.forEach((i) => {
        if ((i as any)[this.dataName].includes(val)) {
          this.searchArr.push(i);
        }
      });
    }
  }
  selectval(val = {}): void {
    this.value = (val as any)[this.dataName];
    this.searchArr = [];
    this.$bus.emit('rowClick', val);
    if (this.buriedPoint) {
      this.$emit('buriedPointHandler', this.buriedPoint);
    }
    this.$emit('rowClick', val);
  }
  clearValue(): void {
    this.searchArr = [];
  }
}
