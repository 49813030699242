/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { RouteConfig } from 'vue-router';
import { asyncRouterMap, abnormalRouterMap, constantRouterMap } from '@/router';
import store from '@/store';

const hasPermission = (route: any, roles: any) => {
  const ary: any = [];
  route.forEach((item: any) => {
    const paths = item.path.split('/');
    const path = paths[paths.length - 1];
    if (roles[path]) {
      ary.push(item);
    }
  });
  return ary;
};

export const filterAsyncRoutes = (routes: any, roles: string[]) => {
  const systemRouter = routes[0].children;
  const manageRouter = routes[1].children;
  const system: any = [];
  systemRouter.forEach((item: any) => {
    const newChildren = hasPermission(item.children, roles);
    item.children = newChildren;
    if (newChildren.length > 0) {
      system.push(item);
    }
  });
  const manage: any = hasPermission(manageRouter, roles);
  routes[0].children = system;
  routes[1].children = manage;
  return routes;
};

export interface IPermissionState {
  routes: RouteConfig[]
  dynamicRoutes: RouteConfig[]
  initPage: string;
  lbsDmp: number;
  addAoi: number;
  competition: number;
}

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule implements IPermissionState {
  public routes: RouteConfig[] = [];
  public dynamicRoutes: RouteConfig[] = [];
  public initPage = '';
  public lbsDmp = 0;
  public addAoi = 0;
  public competition = 0;

  @Action
  public generateRoutes(roles: any): void {
    const accessedRoutes = filterAsyncRoutes(asyncRouterMap, roles);
    this.setRoutes(accessedRoutes);
  }

  @Mutation
  public setRoutes(routes: any) {
    this.routes = constantRouterMap.concat(routes, abnormalRouterMap);
    this.dynamicRoutes = routes;
  }
  @Mutation
  public setLbsDmp(lbsDmp: number) {
    this.lbsDmp = lbsDmp;
  }
  @Mutation
  public setAddAoi(addAoi: number) {
    this.addAoi = addAoi;
  }
  @Mutation
  public setCompetition(competition: number) {
    this.competition = competition;
  }
}

export const permissionModule = getModule(Permission);
