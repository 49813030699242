/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
import html2Canvas from 'html2canvas';
import JsPDF from 'jspdf';
export default {
  install(Vue: { prototype: { $getPdf: (name: any, htmlTitle: string) => any; }; }) {
    Vue.prototype.$getPdf = function (name: any, htmlTitle: string) {
      const title = htmlTitle;
      // document.querySelector(name)
      html2Canvas(name, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        const pageHeight = contentWidth / 592.28 * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        const imgWidth = 595.28;

        const imgHeight = 592.28 / contentWidth * contentHeight;
        const pageData = canvas.toDataURL('image/jpeg', 1.0);
        const PDF = new JsPDF('l', 'pt', 'a4');
        if (leftHeight < pageHeight) {
          console.log(pageData, 'pageData');

          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(`${title}.pdf`);
      });
    };
  },
};
