/* eslint-disable @typescript-eslint/naming-convention */
// 新老路由映射关系
const mappingRouter: any = {
  // 客流概括
  data_report_statistics: 'statistics',
  data_report_analysis: 'analysis',
  data_report_comparison: 'compare',

  // 客流画像
  natural_quality: 'natural',
  property_of_wealth: 'economy',
  visit_preference: 'arrival',

  // 商圈覆盖
  business_circle_cores: 'core',
  market_permeate: 'marketPenetration',
  group_distribution: 'distribution',

  // 竞争环境
  bussiness_popularity: 'environment1',
  bussiness_effect: 'environment2',
  man_customer_heat: 'environment3',
  men_customer_heat: 'environment4',
  all_age_customer_heat: 'environment5',
  all_ethnic_groups_customer_heat: 'environment6',
  different_consumption_groups: 'environment7',

  // 竞争烈度
  competition_intensity: 'regional',
  competition_intensity_passenger: 'analysis1',

  // 统计报告
  data_report_presentation: 'report',

  // 低客流
  low_passenger_flow: 'depression',

  // dmp跳转
  lbs_dmp: 'lbsDmp',

  // 申请aoi
  aoi_region_status: 'source',

  // 添加竞品
  competitive_products: 'competition',

  // 无竞品
  map: 'map',


  // 角色权限，新标签
  details: 'details',
  monitor: 'monitor',
  service: 'service',
  system: 'system',
  user: 'user',
  services: 'services',

  // 新平台新标签
  tourist: 'tourist',
  city: 'city',
  touristTop: 'touristTop',
  // 老平台界面权限，暂时不用
  population_distribution: '1',
  purchasing_power: '1',
  visiting_community_rank: '1',
  community_crowd_rank: '1',
  depression_rank: '1',
  passenger_source_sort: '1',
  bussiness_analysis: '1',
  passenger_depressions: '1',
};
export default mappingRouter;
