/* eslint-disable @typescript-eslint/naming-convention */
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { logout } from '@/services/loginApi';
import { removeToken } from '@/utils/cookies';
import router from '@/router';
import store from '@/store';
import { permissionModule } from './permission';


export interface IUserState {
  token: string
  roles: string[]
}
@Module({ dynamic: true, name: 'user', store })
class User extends VuexModule implements IUserState {
  public token = '';
  public roles: string[] = [];
  public privilege = 3;
  public userKey = '';
  public serviceName = '';
  public tel = '';
  public mid = 0;


  @Action
  public resetToken(): void {
    removeToken();
    this.setToken('');
    this.setRoles([]);
  }

  @Action({ rawError: true })
  async loginUsers(res: any) {
    const { page_url: pageUrl, lbs_u_id: lbsUId, user_key: userKey, privilege,
      service_name: serviceName, tel, mid } = res;
    this.setRoles(pageUrl);
    this.setToken(lbsUId);
    this.setPrivilege(privilege);
    this.setUserKey(userKey);
    this.setServiceName(serviceName);
    this.setTel(tel);
    this.setMid(Number(mid));
  }

  @Action
  public async changeRoles() {
    permissionModule.generateRoutes(this.roles);
    router.addRoutes(permissionModule.dynamicRoutes);
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!');
    }
    await logout();
    this.LogOuts();
  }

  @Action
  public async LogOuts() {
    removeToken();
    this.setRoles([]);
    this.setToken('');
    this.setPrivilege(3);
    this.setUserKey('');
    this.setServiceName('');
    this.setMid(0);
    try {
      localStorage.deleteItem('authorization');
      localStorage.setItem('loginStatus', '0');
    // eslint-disable-next-line no-empty
    } catch {}
  }
  @Mutation
  public setMid(mid: number) {
    this.mid = mid;
  }
  @Mutation
  public setToken(token: string) {
    this.token = token;
  }
  @Mutation
  private setTel(tel: string) {
    this.tel = tel;
  }
  @Mutation
  private setPrivilege(privilege: number) {
    this.privilege = privilege;
  }
  @Mutation
  private setServiceName(serviceName: string) {
    this.serviceName = serviceName;
  }
  @Mutation
  private setUserKey(userKey: string) {
    this.userKey = userKey;
  }

  @Mutation
  private setRoles(roles: string[]) {
    this.roles = roles;
  }
}

export const userModule = getModule(User);
