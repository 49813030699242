import { RouterConfig } from '@/types/router';
const business: RouterConfig = {
  path: '/system/business',
  name: '商圈覆盖',
  meta: {
    id: '3',
    isShow: true,
  },
  component: () => import('@/pages/systemPages/blank/index.vue'),
  children: [
    {
      path: '/system/business/city',
      name: '本市覆盖',
      icon: 'm-icon-a-Group6345',
      meta: {
        id: '3-1',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/business/city/index.vue'),
    },
    {
      path: '/system/business/core',
      name: '核心商圈',
      icon: 'm-icon-a-Group6400',
      meta: {
        id: '3-2',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/business/core/index.vue'),
    },
    {
      path: '/system/business/marketPenetration',
      name: '市场渗透',
      icon: 'm-icon-a-Group6401',
      meta: {
        id: '3-3',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/business/marketPenetration/index.vue'),
    },
    {
      path: '/system/business/distribution',
      name: '客群分布',
      icon: 'm-icon-a-Group6263',
      meta: {
        id: '3-4',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/business/distribution/index.vue'),
    },
  ],
};
export default business;
