/* eslint-disable @typescript-eslint/naming-convention */
import { RouterConfig } from '@/types/router';
import systemRouter from './system/index';
import manageRouter from './manage/index';

export const asyncRouterMap: Array<RouterConfig> = [
  {
    path: '/system',
    name: '进入系统',
    meta: {
      isShow: true,
    },
    component: () => import('@/layouts/systemLayout/index.vue'),
    children: systemRouter,
  },
  {
    path: '/manage',
    name: '我的后台',
    meta: {
      isShow: true,
    },
    component: () => import('@/layouts/manageLayout/index.vue'),
    children: manageRouter,
  },
];

export const constantRouterMap: Array<any> = [
  {
    path: '/',
    name: '重定向',
    meta: {
      isShow: true,
    },
    redirect: '/index',
    component: () => import('@/layouts/layout.vue'),
  },
  {
    path: '/index',
    name: '首页',
    meta: {
      isShow: true,
    },
    component: () => import('@/pages/login/index.vue'),
  },
];

export const abnormalRouterMap: Array<any> = [
  {
    path: '/404',
    name: '404',
    meta: {
      isShow: true,
    },
    component: () => import('@/pages/abnormalPages/notFound/index.vue'),
  },
  {
    path: '*',
    redirect: '/404',
    meta: {
      isShow: false,
    },
  },
];
