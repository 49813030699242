/* eslint-disable @typescript-eslint/no-require-imports */
import { getCityAdcodeBycity } from '@/services/sourceApi';
import { geocoder } from '@/services/bigdata';
/* eslint-disable @typescript-eslint/no-var-requires */
function getWindow(): any {
  return (window as any);
}
const windows = getWindow();
function getMapCenter(center: string): any {
  const latlng = center.split(',');
  const centers = new windows.qq.maps.LatLng(latlng[0], latlng[1]);
  return centers;
}

function initMap(el: string, zoom: number, center: string): any {
  const center1 = center ? center : '40.04035538823994,116.27348184585571';
  const centers = getMapCenter(center1);
  const map = new windows.qq.maps.Map(document.getElementById(el), {
    center: centers,
    mapStyleId: 'style1',
    panControl: false,
    zoomControl: false,
    mapTypeControl: false,
    zoom,
  });
  return map;
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types*/
function drawMall(map: any, center: any, type = 0): any {
  let img;
  if (type === 1) {
    img = require('@/assets/mall/cmall.png');
  } else if (type === 2) {
    img = require('@/assets/mall/emall.png');
  } else {
    img = require('@/assets/mall/mall.png');
  }
  // const img = type === 0 ? require('@/assets/mall/mall.png') : require('@/assets/mall/cmall.png');
  const anchor = new windows.qq.maps.Point(8, 16);
  const size = new windows.qq.maps.Size(30, 30);
  const origin = new windows.qq.maps.Point(0, 0);
  const icon = new windows.qq.maps.MarkerImage(img, size, origin, anchor);
  const marker = new windows.qq.maps.Marker({
    icon,
    position: center,
    map,
  });
  return marker;
}

// 普通版marker
function drawMarkers(map: any, center: any): any {
  const marker = new windows.qq.maps.Marker({
    position: center,
    map,
  });
  return marker;
}

function clearMall(marker: any): void {
  if (marker) {
    marker.setMap(null);
  }
}

function setFillColor(step: number, weight: number, max: number, min: number, colorAry: string[]): string {
  const colorLen = colorAry.length;
  if (step !== 0) {
    const index = (weight - min) / step > (colorLen - 1) ? (colorLen - 1) : Math.floor((weight - min) / step);
    return colorAry[index];
  }
  return colorAry[3];
}

function getSectionAry(data: any, weightFiled: string, colorAry: string[], weightType: number) {
  const colorLen = colorAry.length;
  const maths = (Math as any);
  let max = maths.max.apply(Math, data.map((item: any) => item[weightFiled]));
  // let min = maths.min.apply(Math, data.map((item: any) => item[weightFiled]));
  if (data.length === 0) {
    max = 0;
    // min = 0;
  }
  const sectionVal = max / colorLen;
  let minVal = 0;
  let maxVal = sectionVal;
  const arr: any = [];
  if (weightType === 1) {
    colorAry.forEach((item: string) => {
      const objTmp = {
        min: Math.round(minVal),
        max: Math.round(maxVal),
        color: item,
      };
      arr.push(objTmp);
      minVal += sectionVal;
      maxVal += sectionVal;
    });
  } else {
    colorAry.forEach((item: string) => {
      const objTmp = {
        min: Math.round(minVal * 100) / 100,
        max: Math.round(maxVal * 100) / 100,
        color: item,
      };
      arr.push(objTmp);
      minVal += sectionVal;
      maxVal += sectionVal;
    });
  }
  return arr;
}

function fillColorPage(weight: number, sectionAry: any) {
  // TODO 网格最终绘制颜色
  let colors = '';
  sectionAry.forEach((item: any) => {
    const { min, max, color } = item;
    if (weight >= min && weight <= max) {
      colors = color;
    }
  });
  return colors;
}

function processDataByStartEnd(start: number, end: number, data: any) {
  const lth = data.length;
  const startTmps = String(start / 100 * lth);
  const endTmps = String(end / 100 * lth);
  // eslint-disable-next-line radix
  const startTmp = parseInt(startTmps);
  // eslint-disable-next-line radix
  const endTmp = parseInt(endTmps);
  const dataAry: any = [];
  let rank = 0;
  let sum = 0;
  data.forEach((item: any, index: number) => {
    if (index >= startTmp && index < endTmp) {
      // $val = $data[$i][$str];
      const { value } = item;
      if (value !== 0) {
        rank = rank + 1;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sum += value;
        // eslint-disable-next-line no-param-reassign
        item.rank = rank;
        if (item.cover_rank) {
          // eslint-disable-next-line no-param-reassign
          item.cover_rank = rank;
        }
        dataAry.push(item);
      }
    }
  });
  return dataAry;
}

function processDepressionData(start: number, end: number, data: any) {
  let sum = 0;
  const res: any = [];
  data.forEach((item: any) => {
    const { cover, num, cover_rank: coverRank } = item;
    if ((cover >= start && cover <= end) || coverRank === 0) {
      sum += num;
      res.push(item);
    }
  });
  const area = res.length;
  return { area, sum };
}

function zoomUp(map: any) {
  if (map.getZoom() < 18) {
    const zoom = map.getZoom() + 1;
    map.zoomTo(zoom);
  }
}

function zoomDown(map: any) {
  if (map.getZoom() > 3) {
    const zoom = map.getZoom() - 1;
    map.zoomTo(zoom);
  }
}

function drawPolygon(map: any, path: any) {
  const polygon = new windows.qq.maps.Polygon({
    map,
    path,
    strokeColor: new windows.qq.maps.Color(69, 131, 255, 1),
    fillColor: windows.qq.maps.Color.fromHex('#4583FF', 0.25),
  });
  return polygon;
}

function processPolygonData(boundary: string) {
  const path: any[] = [];
  const boundaryAry = boundary.split('|');
  boundaryAry.pop();
  boundaryAry.forEach((item: string) => {
    const latLng = new (window as any).qq.maps.LatLng(
      parseFloat(item.split(',')[0]),
      parseFloat(item.split(',')[1]),
    );
    path.push(latLng);
  });
  return path;
}

function overlayCenter(center: string) {
  const latLng = center.split(',');
  const lat = latLng[0];
  const lng = latLng[1];
  return new windows.qq.maps.LatLng(parseFloat(lat), parseFloat(lng));
}

function clearOverlay(overlay: any) {
  if (overlay) {
    overlay.setMap(null);
  }
}

function drawMarker(map: any, center: any) {
  const img = require('@/assets/basics/aoicommuntiy.png');
  const anchor = new windows.qq.maps.Point(8, 8);
  const size = new windows.qq.maps.Size(39, 43);
  const origin = new windows.qq.maps.Point(0, 0);
  const icon = new windows.qq.maps.MarkerImage(img, size, origin, anchor);
  const marker = new windows.qq.maps.Marker({
    map,
    position: center,
    icon,
  });
  return marker;
}

// 根据状态显示区域颜色
function regionPolygonStyle(status: string, polygon: any) {
  switch (status) {
    case '1': // 已通过
      polygon.setStrokeColor('#4583ff');
      polygon.setFillColor(windows.qq.maps.Color.fromHex('#4583ff', 0.25));
      // #4583ff
      return;
    case '2': // 待审核
      polygon.setStrokeDashStyle('dash');
      return;
    case '0': // 已删除
      polygon.setStrokeColor('#8D97AE');
      polygon.setFillColor(windows.qq.maps.Color.fromHex('#8D97AE', 0.5));
      polygon.setStrokeDashStyle('dash');
      return;
    case '-1': // 已驳回
      polygon.setStrokeColor('#DC0000');
      polygon.setFillColor(windows.qq.maps.Color.fromHex('#DC0000', 0.5));
      polygon.setStrokeDashStyle('dash');
      return;
    default:
      // 默认
      return;
  }
}

function drawLabel(
  map: any,
  position: string,
  content: string,
  index: number,
  aoiId: number,
  style: any,
  visible: boolean,
): any {
  const label = new (window as any).qq.maps.Label({
    map,
    position,
    content,
    style,
    zIndex: 2,
    index,
    aoiId,
    visible,
  });
  return label;
}

function drawWingManager(map: any, typeMode: any) {
  // drawingMode: windows.qq.maps.drawing.OverlayType.MARKER, marker
  // POLYGON
  const draw = new windows.qq.maps.drawing.DrawingManager({
    map,
    drawingMode: typeMode,
  });
  return draw;
}

function clearDrawWingManager(drawingManager: any) {
  if (drawingManager) {
    drawingManager.setMap(null);
    // eslint-disable-next-line no-param-reassign
    drawingManager = null;
  }
}

function isPointInPolygon(point: any, polygon: any) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const N = polygon.length;
  const boundOrVertex = true; // 如果点位于多边形的顶点或边上，也算做点在多边形内，直接返回true
  let intersectCount = 0; // cross points count of x
  const precision = 2e-10; // 浮点类型计算时候与0比较时候的容差
  let p1;
  let p2; // neighbour bound vertices
  const p = point; // 测试点

  // eslint-disable-next-line prefer-destructuring
  p1 = polygon[0]; // left vertex

  for (let i = 1; i <= N; ++i) {
    // check all rays
    const obj = checkBoundary(
      p,
      p1,
      p2,
      i,
      N,
      boundOrVertex,
      polygon,
      intersectCount,
      precision,
    );
    p1 = obj.p2;
    intersectCount = obj.intersectCount;
  }

  // eslint-disable-next-line eqeqeq
  if (intersectCount % 2 == 0) {
    // 偶数在多边形外
    return false;
  } // 奇数在多边形内
  return true;
}

function checkBoundary(
  p: any,
  p1: any,
  p2: any,
  i: any,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  N: any,
  boundOrVertex: any,
  polygon: any,
  intersectCount: any,
  precision: any,
) {
  /* eslint-disable eqeqeq */
  /* eslint-disable no-param-reassign */
  /* eslint-disable no-plusplus */

  if (p.x == p1.x && p.y == p1.y) {
    return boundOrVertex; // p is an vertex
  }

  p2 = polygon[i % N]; // right vertex
  if (p.y < Math.min(p1.y, p2.y) || p.y > Math.max(p1.y, p2.y)) {
    // ray is outside of our interests
    p1 = p2;
    return {
      p2,
      intersectCount,
    };
  }

  if (p.y > Math.min(p1.y, p2.y) && p.y < Math.max(p1.y, p2.y)) {
    // ray is crossing over by the algorithm (common part of)
    if (p.x <= Math.max(p1.x, p2.x)) {
      // x is before of ray
      if (p1.y == p2.y && p.x >= Math.min(p1.x, p2.x)) {
        // overlies on a horizontal ray
        return boundOrVertex;
      }

      if (p1.x == p2.x) {
        // ray is vertical
        if (p1.x == p.x) {
          // overlies on a vertical ray
          return boundOrVertex;
        } // before ray
        ++intersectCount;
      } else {
        // cross point on the left side
        const xinters = ((p.y - p1.y) * (p2.x - p1.x)) / (p2.y - p1.y) + p1.x; // cross point of x
        if (Math.abs(p.x - xinters) < precision) {
          // overlies on a ray
          return boundOrVertex;
        }

        if (p.x < xinters) {
          // before ray
          ++intersectCount;
        }
      }
    }
  } else {
    // special case when ray is crossing through the vertex
    if (p.y == p2.y && p.x <= p2.x) {
      // p crossing over p2
      const p3 = polygon[(i + 1) % N]; // next vertex
      if (p.y >= Math.min(p1.y, p3.y) && p.y <= Math.max(p1.y, p3.y)) {
        // p.y lies between p1.y & p3.y
        ++intersectCount;
      } else {
        intersectCount += 2;
      }
    }
  }
  return {
    p2,
    intersectCount,
  };
}

// 判断面面是否重叠
function isSegmentsIntersectant(segA: any, segB: any) {
  // 线线
  const abc = (segA[0].x - segB[0].x) * (segA[1].y - segB[0].y)
    - (segA[0].y - segB[0].y) * (segA[1].x - segB[0].x);
  const abd = (segA[0].x - segB[1].x) * (segA[1].y - segB[1].y)
    - (segA[0].y - segB[1].y) * (segA[1].x - segB[1].x);
  if (abc * abd >= 0) {
    return false;
  }
  const cda = (segB[0].x - segA[0].x) * (segB[1].y - segA[0].y)
    - (segB[0].y - segA[0].y) * (segB[1].x - segA[0].x);
  const cdb = cda + abc - abd;
  return !(cda * cdb >= 0);
}

function isPolygonsIntersectant(plyA: any, plyB: any) {
  // 面面
  for (let i = 0, il = plyA.length; i < il; i++) {
    for (let j = 0, jl = plyB.length; j < jl; j++) {
      const segA = [plyA[i], plyA[i === il - 1 ? 0 : i + 1]];
      const segB = [plyB[j], plyB[j === jl - 1 ? 0 : j + 1]];
      if (isSegmentsIntersectant(segA, segB)) {
        return true;
      }
    }
  }
  return false;
}

// 判断两多变形是否存在点与区域的包含关系(A的点在B的区域内或B的点在A的区域内)
function isPointInPolygonBidirectional(plyA: any, plyB: any) {
  // 面面
  let a = false;
  let b = false;
  a = plyA.some((item: any) => isPointInPolygon(item, plyB));
  if (!a) {
    b = plyB.some((item: any) => isPointInPolygon(item, plyA));
  }
  return a || b;
}

// 判断多边形
function isPolygonsOverlap(plyA: any, plyB: any) {
  for (const item of plyB) {
    if (typeof (item.x) === 'string') {
      item.x = parseFloat(item.x);
    }
    if (typeof (item.y) === 'string') {
      item.y = parseFloat(item.y);
    }
  }
  return (
    isPolygonsIntersectant(plyA, plyB) || isPointInPolygonBidirectional(plyA, plyB)
  );
}

function getBoundaryAry(boundary: string) {
  const boundaryTmp = boundary.split('|');
  const boundaryAry: any = [];
  boundaryTmp.forEach((item1: any) => {
    if (item1) {
      const latlng = item1.split(',');
      const obj = {
        x: latlng[1],
        y: latlng[0],
      };
      boundaryAry.push(obj);
    }
  });
  return boundaryAry;
}

function processBoundary(points: any) {
  const pathAry: any = [];
  points.forEach((item: any) => {
    pathAry.push(`${item.lat},${item.lng}`);
  });
  return pathAry;
}

function processBoundary2(points: any) {
  const pathAry: any = [];
  points.forEach((item: any) => {
    pathAry.push([item.lng, item.lat]);
  });
  return pathAry;
}


function processPath(path: any) {
  const pathAry: any = [];
  path.forEach((item: any) => {
    const obj = {
      x: item.lng,
      y: item.lat,
    };
    pathAry.push(obj);
  });
  return pathAry;
}

function getCorner(points: any) {
  const corner = {
    upper: null,
    right: null,
    left: null,
    lower: null,
  };
  if (!points) {
    return corner;
  }
  const pointsLat: any = [];
  const pointsLng: any = [];
  points.forEach((item: any) => {
    const { lat, lng } = item;
    pointsLat.push(lat);
    pointsLng.push(lng);
  });

  pointsLat.sort(sortByAsc);
  pointsLng.sort(sortByAsc);

  corner.lower = pointsLat.shift();
  corner.upper = pointsLat.pop();
  corner.left = pointsLng.shift();
  corner.right = pointsLng.pop();

  return corner;
}

function sortByAsc(a: any, b: any) {
  return a - b;
}

function types(val: any) {
  return (val as any);
}

function computeArea(path: any) {
  const areas = windows.qq.maps.geometry.spherical.computeArea(path);
  const num = areas / 1000 / 1000;
  const area = Math.round(num * 10000) / 10000;
  return area;
}

function escapeHtml(str: string) {
  const entityMap: any = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#39;',
    '/': '&#x2F;',
  };
  // eslint-disable-next-line no-useless-escape
  return String(str).replace(/[&<>"'\/]/g, s => entityMap[s]);
}

async function getCityAdcodeBycitys(params: any): Promise<void> {
  const res = await getCityAdcodeBycity(params);
  return res;
}

// aoi相关详细信息计算
async function getGeocoder(aoiName: string, currentPathAry: any, mallId: number, callback: any) {
  const pointsBoundaryArr = processBoundary(currentPathAry);
  const latLng = getCorner(currentPathAry);
  // 左上、左下、右上、右下边界点计算
  const { lower, upper, left, right } = latLng;
  const lowers = types(lower);
  const uppers = types(upper);
  const lefts = types(left);
  const rights = types(right);
  const centerLat = (lowers + uppers) / 2;
  const centerLng = (lefts + rights) / 2;
  let maxLon: any;
  let minLon: any;
  let maxLat: any;
  let minLat: any;
  lowers > uppers
    ? (maxLat = lower)
    : (maxLat = upper);
  lefts > rights
    ? (maxLon = left)
    : (maxLon = right);
  lowers < uppers
    ? (minLat = lower)
    : (minLat = upper);
  lefts < rights
    ? (minLon = left)
    : (minLon = right);
  const area = computeArea(currentPathAry);
  const aoiNames = escapeHtml(aoiName);
  /* eslint-disable @typescript-eslint/naming-convention */
  const centreLl = `${centerLat},${centerLng}`;
  const boundary = `${pointsBoundaryArr.join('|')}|`;
  let aoiInfo: any = {
    aoi_name: aoiNames,
    province: '',
    city: '',
    district: '',
    boundary,
    centre_ll: centreLl,
    min_lon: minLon,
    min_lat: minLat,
    max_lon: maxLon,
    max_lat: maxLat,
    aoi_area: area,
    mall_id: mallId,
    adcode: 0,
  };
  aoiInfo = await geocoderData({
    location: `${centerLat},${centerLng}`,
  }, aoiInfo, currentPathAry);
  callback(aoiInfo);
}

async function geocoderData(params: any, aoiInfo: any, currentPathAry: any, onlyFirstPoint = true) {
  const result = await geocoder(params);
  const cityInfo = result.result.address_component;
  const { province, city, district } = cityInfo;
  aoiInfo.province = province;
  aoiInfo.city = city;
  aoiInfo.district = district;
  if (aoiInfo.city) {
    const res: any = await getCityAdcodeBycitys({
      city: aoiInfo.city,
    });
    if (res.status === 0 && res.data) {
      const { data } = res;
      const adcode = data[0].id;
      aoiInfo.adcode = Number(adcode);
    }
  } else if (onlyFirstPoint) {
    aoiInfo = await geocoderData({
      location: `${currentPathAry[0].lat},${currentPathAry[0].lng}`,
    }, aoiInfo, currentPathAry, false);
  }
  return aoiInfo;
}

function isXss(value: string) {
  return value.replace(/[`~!#$%^&*()_+=<>?:"{}|~！#￥%……&*（）={}|《》？：“”【】、；;,.[/'':``|‘’，。、\s+]/g, '');
}
function isKey(val: string) {
  if (!val || val == '' || val == null) {
    return false;
  }
  const reg = new RegExp(/^[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}$/);
  if (reg.test(val)) {
    return true;
  }
  return false;
}

function getPolygonAreaCenter(path: any) {
  const points = processBoundary2(path);
  let sumX = 0;
  let sumY = 0;
  let sumArea = 0;
  let p1 = points[1];
  for (let i = 2; i < points.length; i++) {
    const p2 = points[i];
    const area = Area(points[0], p1, p2);
    sumArea += area;
    sumX += (points[0][0] + p1[0] + p2[0]) * area;
    sumY += (points[0][1] + p1[1] + p2[1]) * area;
    p1 = p2;
  }
  const xx = sumX / sumArea / 3;
  const yy = sumY / sumArea / 3;
  return {
    lat: yy,
    lng: xx,
  };
}


function Area(p0: any, p1: any, p2: any) {
  let area = 0.0;
  area = p0[0] * p1[1] + p1[0] * p2[1] + p2[0] * p0[1] - p1[0] * p0[1] - p2[0] * p1[1] - p0[0] * p2[1];
  return area / 2;
}

function drawCircle(
  map: any,
  center: string,
  index: number,
  aoiId: number,
  radius: number,
  strokeWeight: number,
  item: any,
  zIndex: number,
  fillColor: string,
): any {
  const circle = new (window as any).qq.maps.Circle({
    map,
    center,
    radius,
    fillColor,
    strokeWeight,
    strokeColor: '#fff',
    zIndex,
    index,
    aoiId,
    item,
  });
  return circle;
}

export {
  getMapCenter,
  initMap,
  drawMall,
  clearMall,
  setFillColor,
  getSectionAry,
  fillColorPage,
  processDataByStartEnd,
  processDepressionData,
  zoomUp,
  zoomDown,
  drawPolygon,
  processPolygonData,
  overlayCenter,
  clearOverlay,
  regionPolygonStyle,
  drawMarker,
  drawMarkers,
  drawLabel,
  drawWingManager,
  clearDrawWingManager,
  isPolygonsOverlap,
  getBoundaryAry,
  computeArea,
  getGeocoder,
  processBoundary,
  processBoundary2,
  processPath,
  isXss,
  isKey,
  getPolygonAreaCenter,
  drawCircle,
};


