/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function isArray(data: any) {
  let status = 0;
  if (Object.prototype.toString.call(data) === '[object Array]') {
    // console.log('数据为数组类型');
    status = 1;
  }
  return status;
}

function isObject(data: any) {
  let status = 0;
  if (Object.prototype.toString.call(data) === '[object Object]') {
    // console.log('数据为对象类型');
    status = 1;
  }
  return status;
}

function isString(data: any) {
  let status = 0;
  if (Object.prototype.toString.call(data) === '[object String]') {
    // console.log('数据为字符串类型');
    status = 1;
  }
  return status;
}

function isJudgmentType(data: any) {
  let obj = {
    type: 0,
    status: 0,
    info: '',
  };
  if (isArray(data) === 1) {
    obj = {
      type: 1,
      status: 1,
      info: '数据为数组类型',
    };
  } else if (isObject(data) === 1) {
    obj = {
      type: 2,
      status: 1,
      info: '数据为对象类型',
    };
  } else if (isString(data) === 1) {
    obj = {
      type: 3,
      status: 1,
      info: '数据为字符串类型',
    };
  }
  return obj;
}

export {
  isArray,
  isObject,
  isString,
  isJudgmentType,
};
