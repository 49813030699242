import { userModule } from '@/store/modules/user';
import router from '.';

export default function beforeEachRouter(): void {
  router.beforeEach(async (to, from, next) => {
    const { token } = userModule;
    const { path } = to;
    if (!path.includes('/index')) {
      if (!token) {
        next('/index');
      } else {
        next();
      }
    } else {
      next();
    }
  });
}
