/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */

// 路由逻辑：
//  首次进入系统，跳转数组的第一项；
//  刷新权限有更改，现在所在那项不存在，也跳转到数组的第一项；
//  再次进入系统，缓存中寻找上一项，上一项不存在，也跳转到数组的第一项；
//  跳转使用 routerId这一项 作为唯一条件，没用名字是因为 名字有重复项；
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { constantRouterMap, asyncRouterMap, abnormalRouterMap } from '@/router/modules/routes';
Vue.use(VueRouter);
const routes: Array<RouteConfig> = constantRouterMap;
const createRouter = (routes: any) => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
const router = createRouter(routes);

export function resetRouter(routes: any): void {
  router.addRoutes(routes);
}

export default router;
export {
  asyncRouterMap,
  abnormalRouterMap,
  constantRouterMap,
};

