


























































































































































/* eslint-disable @typescript-eslint/naming-convention */
import { Vue, Component, Watch } from 'vue-property-decorator';
import compareCard from '@/components/CompareCard/index.vue';
import portraitPrivcar from '@/components/portraitPrivcar/index.vue';
import {
  shoppingMallArrival,
  differentSexConsumeDetails,
  differentAgeConsumeDetails,
} from '@/utils/echarts/echarts';
import {
  PassengerFlowTopsParams,
  PerAgePortraitSParams,
} from '@/types/store';
import {
  getAoiPortrait,
  mallAnalysisConsumptionAbility,
} from '@/services/api';
import tdesignFooter from '@/components/GlobalFooter/index.vue';

@Component({
  components: {
    compareCard,
    portraitPrivcar,
    tdesignFooter,
  },
})
export default class PortraitEconomy extends Vue {
  slotEcharts = true;
  slotcontent = false;
  $dayjs: any;
  excelArr: any[]=[];
  show = true;
  show1 = true;
  show2 = true;
  show3 = true;
  show4 = true;
  tips = true;
  duration = this.$store.state.mall.duration;
  mall_id = this.$store.state.mall.mallId;
  aoiId = this.$store.state.mall.aoiId;
  numType = this.$store.state.mall.numType;
  // login = this.$store.state.global.login;
  date = this.$store.state.mall.date;
  consumptionlevel = {};
  frequencyOfVisits = {};
  frequencyOfLeisure = {};
  frequencyOfSports = {};
  frequencyOfCar = {};
  vehicle = {};
  housingPrice = {};
  ageConsumeDetails = {};
  ageConsumeDistribution = {};
  pravcar = Vue.prototype.$GLOBAL.privacyInfo;
  mallTotalStatus = false;
  mallTotal: any;
  echartsShow = false;

  activated(): void{
    this.echartsShow = true;
  }

  deactivated(): void{
    this.echartsShow = false;
  }

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }
  mounted(): void{
    this.pageHandler();
  }

  async pageHandler(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.show = true;
    this.show1 = true;
    this.show2 = true;
    this.show3 = true;
    this.show4 = true;
    this.mall_id = this.$store.state.mall.mallId;
    this.date = this.$store.state.mall.date;
    this.duration = this.$store.state.mall.duration;
    this.aoiId = this.$store.state.mall.aoiId;
    this.numType = this.$store.state.mall.numType;
    // const mallTotalFlowParams: TotalPassengerFlowParams = {
    //   mall_id: this.mall_id,
    //   duration: this.duration,
    //   date: this.date,
    //   type: 1,
    //   property: 1,
    // };
    // const mallTotalRes = await mallTotalFlow(mallTotalFlowParams);
    // this.mallTotal = mallTotalRes.sum;
    // this.mallTotalStatus = this.mallTotal < 100;
    // if (this.mallTotalStatus) {
    //   return;
    // }
    const PerAgePortraitSParams: any = {
      aoi_id: this.aoiId,
      mall_id: this.mall_id,
      date: this.date,
      duration: this.duration,
      num_type: this.numType,
      type: 501,
      j_type: 0,
      sort: true,
      sum_type: 1,
    };

    const colorAry = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
    const title1s = '消费水平占比';
    // 餐厅
    PerAgePortraitSParams.type = 501;
    const title2 = '到访占比';
    const portraitRes2 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title2,
      title1s,
      shoppingMallArrival,
      [''],
    );
    this.frequencyOfVisits = portraitRes2.option;
    this.show = false;
    // 商场
    PerAgePortraitSParams.type = 502;
    const title3 = '到访占比';
    const title3s = '';
    const portraitRes3 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title3,
      title3s,
      shoppingMallArrival,
      colorAry,
    );
    this.vehicle = portraitRes3.option;
    this.show1 = false;
    // 餐厅
    PerAgePortraitSParams.type = 503;
    const title4 = '到访占比';
    const title4s = '';
    const portraitRes4 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title4,
      title4s,
      shoppingMallArrival,
      [''],
    );
    this.frequencyOfLeisure = portraitRes4.option;
    this.show2 = false;
    // 运动健身
    PerAgePortraitSParams.type = 504;
    const title5 = '到访占比';
    const title5s = '';
    const portraitRes5 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title5,
      title5s,
      shoppingMallArrival,
      [''],
    );
    this.frequencyOfSports = portraitRes5.option;
    this.show3 = false;
    // car
    PerAgePortraitSParams.type = 505;
    const title6 = '到访占比';
    const title6s = '';
    const portraitRes6 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title6,
      title6s,
      shoppingMallArrival,
      [''],
    );
    this.frequencyOfCar = portraitRes6.option;
    this.show4 = false;
    this.excelArr = [
      portraitRes2.datas,
      portraitRes3.datas,
      portraitRes4.datas,
      portraitRes5.datas,
      portraitRes6.datas,
    ];
    this.$stores.global.setDownAble(true); // 数据全部加载完全后允许下载
  }

  async getGridMallAnalysisConsumptionAbility(params: PassengerFlowTopsParams): Promise<any> {
    const res = await mallAnalysisConsumptionAbility(params);
    const { totalConsumption, consumptionIndex, datas } = res;
    const { manData, menData, texts } = totalConsumption;
    const {
      centerAry,
      highAry,
      higherAry,
      lowAry,
      lowerAry,
      texts: texts2,
    } = consumptionIndex;
    const option2 = differentSexConsumeDetails(
      texts2,
      higherAry,
      highAry,
      centerAry,
      lowerAry,
      lowAry,
    );
    const age = '年龄分布';
    const option = differentAgeConsumeDetails(texts, manData, menData, 2, age);
    return {
      option,
      option2,
      datas,
    };
  }

  async getPerAgePortrait(
    params: PerAgePortraitSParams,
    titile: string,
    title1s: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    callback: any,
    colorAry?: string[],
  ): Promise<any> {
    const res = await getAoiPortrait(params);
    const { data, datas } = res;
    const { percents, texts } = data;
    const option = callback(texts, percents, titile, title1s, colorAry);

    return {
      option,
      datas,
    };
  }

  @Watch('$store.state.mall', { deep: true })
  changeMall(): void {
    this.pageHandler();
  }

  @Watch('$store.state.global.aoiDownloadStatus')
  changeExport(): void {
    const datas = [];
    const status = this.$store.state.global.aoiDownloadStatus;
    if (status === true && this.echartsShow) {
      const dateObj = {
        d: this.date,
        xw: `${this.$dayjs(this.date).subtract(6, 'day')
          .format('YYYY-MM-DD')}~${this.date}`,
        xm: this.$dayjs(this.date).format('YYYY-MM'),
      };
      this.excelArr.forEach((i: any) => {
        i.forEach((j: any) => {
        // eslint-disable-next-line no-param-reassign
          j.time = (dateObj as any)[this.duration];
        });
      });
      datas.push({
        sheetData: this.excelArr[0],
        sheetName: '餐厅',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '餐厅', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[1],
        sheetName: '购物',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '购物', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[2],
        sheetName: '休闲娱乐',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '休闲娱乐', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[3],
        sheetName: '运动健身',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '运动健身', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[4],
        sheetName: '汽车',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '汽车', '占比'],
        columnWidths: [10, 8, 8],
      });
      this.$root.$data.event.$emit('getAoiData', datas);
    }
  }
}
