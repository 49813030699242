/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// 埋点文件
import { domainConfig } from '@/config/domain';
import { userModule } from '@/store/modules/user';
import BeaconAction from '@tencent/beacon-web-sdk';

// 埋点初始化
const beacon = new BeaconAction({
  appkey: '0WEB05ESD3DX34SI', // 系统或项目id, 必填
  versionCode: '1.0.0', // 项目版本,选填
  channelID: `web_${domainConfig.env}`, // 渠道,选填
  // openid: 'openid', // 用户id, 选填
  // isDebug: false, // 是否开启实时联调, 默认关闭,选填
  strictMode: false, // 严苛模式开关, 打开严苛模式会主动抛出异常, 上线请务必关闭!!!
  delay: 3000, // 普通事件延迟上报时间(单位毫秒), 默认3000(3秒),选填
  sessionDuration: 60 * 1000, // session变更的时间间隔, 一个用户持续30分钟(默认值)没有任何上报则算另一次 session,
  // 每变更一次session上报一次启动事件(rqd_applaunched),使用毫秒(ms),最小值30秒,选填
  onReportSuccess: () => { // 上报成功回调,选填
    // console.log('onReportSuccess');
  },
  onReportFail: () => { // 上报失败回调,选填
    // console.log('onReportFail');
  },
});

// 埋点方法加工处理器
const onUserAction = function (type: string, value: any = {}): void {
  const { tel, userKey } = userModule;
  const params = { ...value, tel, key: userKey };
  beacon.onUserAction(type, params);
};

// 埋点代理对象
const beaconAction = {
  onUserAction,
};
export default beaconAction;

