/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
const getDetailedAnalysis = (
  title: string,
  xName: string,
  company: string,
  valueSectionData: number[],
  valueCountSectionData: number[],
  markAreaData: any,
  markPointData: any,
): any => {
  const option = {
    title: {
      text: title,
      left: 'center',
    },
    tooltip: {
      show: true,
      trigger: 'item',
      axisPointer: {
        type: 'none',
      },
      triggerOn: 'mousemove',
      formatter(params: any) {
        const { componentType, data, dataIndex } = params;
        markAreaData.forEach((item: any, index: number) => {
          markAreaData[index][0].itemStyle.color = 'rgba(216,216,216,0)';
        });
        if (componentType === 'markArea') {
          const { value, nameText, count } = data;
          const str = `<div style="padding:10px;"><div style="display:flex;justify-content:space-between;"><div>${nameText}</div><div style="margin-left:100px;">${value}${company}</div></div><div style="display:flex;justify-content:space-between;margin-top:16px;"><div>商场分布数量</div><div style="margin-left:100px;">${count}</div></div></div>`;
          markAreaData[dataIndex][0].itemStyle.color = 'rgba(216,216,216,0.4)';
          return str;
        }
        if (componentType === 'markPoint') {
          const { value2, nameText } = data;
          const str = `<div style="padding:10px;"><div style="display:flex;justify-content:space-between;"><div>${nameText}</div><div style="margin-left:100px;">${value2}${company}</div></div></div>`;
          return str;
        }
      },
    },
    xAxis: {
      name: xName,
      nameTextStyle: {
        color: '#000000',
        fontSize: 18,
        padding: [0, 0, 15, 0],
      },
      nameGap: 10,
      type: 'category',
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: '#000000',
        fontSize: 16,
        interval: 0,
      },
      boundaryGap: false,
      data: valueSectionData,
    },
    yAxis: [
      {
        name: '商场分布数量',
        nameTextStyle: {
          color: '#000000',
          fontSize: 18,
        },
        axisLabel: {},
        axisTick: {
          show: false,
        },
      },
      {
        axisLine: {
          show: false,
        },
        width: 12,
      },
    ],
    series: [
      {
        symbol: 'none',
        smooth: true,
        type: 'line',
        areaStyle: {
          normal: {
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              type: 'linear',
              global: false,
              colorStops: [
                {
                  offset: 0,
                  color: '#23BEFF',
                },
                {
                  offset: 1,
                  color: '#665EFF',
                },
              ],
            },
          },
        },
        lineStyle: {
          color: 'none',
        },
        markArea: {
          data: markAreaData,
        },
        markPoint: {
          symbol: 'circle',
          data: markPointData,
          symbolSize: 12,
        },
        data: valueCountSectionData,
      },
      {
        type: 'bar',
        barWidth: 1,
        data: valueCountSectionData,
        itemStyle: {
          normal: {
            color: ['#fff'],
            barBorderColor: 'white',
            barBorderWidth: 1,
            barBorderRadius: 0,
            borderType: 'dashed',
          },
        },
      },
    ],
    animation: false,
  };
  return option;
};

export {
  getDetailedAnalysis,
};
