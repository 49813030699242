import { RouterConfig } from '@/types/router';
import environment from '@/pages/systemPages/environment/index.vue';
const environments: RouterConfig = {
  path: '/system/environment',
  name: '竞争环境',
  meta: {
    id: '5',
    isShow: true,
  },
  component: () => import('@/pages/systemPages/blank/index.vue'),
  children: [
    {
      path: '/system/environment/environment1',
      name: '商场人气热度',
      icon: 'm-icon-a-Group6254',
      meta: {
        id: '5-1',
        isShow: true,
      },
      component: environment,
      children: [
        {
          path: '/system/environment/environment1/1',
          name: '客流人数排行',
          meta: {
            id: '5-1-1',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment1/2',
          name: '客流人次排行',
          meta: {
            id: '5-1-2',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment1/3',
          name: '本地客流人数排行',
          meta: {
            id: '5-1-3',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment1/4',
          name: '外地客流人数排行',
          meta: {
            id: '5-1-4',
            isShow: true,
          },
          component: environment,
        },
      ],
    },
    {
      path: '/system/environment/environment2',
      name: '商圈影响力',
      icon: 'm-icon-a-Group6395',
      meta: {
        id: '5-2',
        isShow: true,
      },
      component: environment,
      children: [
        {
          path: '/system/environment/environment2/5',
          name: '商圈辐射力',
          meta: {
            id: '5-2-1',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment2/6',
          name: '商圈渗透率',
          meta: {
            id: '5-2-2',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment2/7',
          name: '商圈人口',
          meta: {
            id: '5-2-3',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment2/8',
          name: '9平方公里商圈渗透率',
          meta: {
            id: '5-2-4',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment2/9',
          name: '25平方公里商圈渗透率',
          meta: {
            id: '5-2-5',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment2/10',
          name: '9平方公里商圈客流占比',
          meta: {
            id: '5-2-6',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment2/11',
          name: '25平方公里商圈客流占比',
          meta: {
            id: '5-2-7',
            isShow: true,
          },
          component: environment,
        },
      ],
    },
    {
      path: '/system/environment/environment3',
      name: '男性客群热度',
      icon: 'm-icon-a-Group6243',
      meta: {
        id: '5-3',
        isShow: true,
      },
      component: environment,
      children: [
        {
          path: '/system/environment/environment3/12',
          name: '青年男性最喜欢的购物中心',
          meta: {
            id: '5-3-1',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment3/13',
          name: '中年男性最喜欢的购物中心',
          meta: {
            id: '5-3-2',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment3/14',
          name: '老年男性最喜欢的购物中心',
          meta: {
            id: '5-3-3',
            isShow: true,
          },
          component: environment,
        },
      ],
    },
    {
      path: '/system/environment/environment4',
      name: '女性客群热度',
      icon: 'm-icon-a-Group6348',
      meta: {
        id: '5-4',
        isShow: true,
      },
      component: environment,
      children: [
        {
          path: '/system/environment/environment4/15',
          name: '青年女性最喜欢的购物中心',
          meta: {
            id: '5-4-1',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment4/16',
          name: '中年女性最喜欢的购物中心',
          meta: {
            id: '5-4-2',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment4/17',
          name: '老年女性最喜欢的购物中心',
          meta: {
            id: '5-4-3',
            isShow: true,
          },
          component: environment,
        },
      ],
    },
    {
      path: '/system/environment/environment5',
      name: '各年龄段客群热度',
      icon: 'm-icon-a-Group6245',
      meta: {
        id: '5-5',
        isShow: true,
      },
      component: environment,
      children: [
        {
          path: '/system/environment/environment5/18',
          name: '00后最喜欢的购物中心',
          meta: {
            id: '5-5-1',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment5/19',
          name: '90后最喜欢的购物中心',
          meta: {
            id: '5-5-2',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment5/20',
          name: '70后80后最喜欢的购物中心',
          meta: {
            id: '5-5-3',
            isShow: true,
          },
          component: environment,
        },
      ],
    },
    {
      path: '/system/environment/environment6',
      name: '各族群客群热度',
      icon: 'm-icon-a-Group6246',
      meta: {
        id: '5-6',
        isShow: true,
      },
      component: environment,
      children: [
        {
          path: '/system/environment/environment6/21',
          name: '银发族最喜欢的购物中心',
          meta: {
            id: '5-6-1',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment6/22',
          name: '准妈妈新妈妈最喜欢的购物中心',
          meta: {
            id: '5-6-2',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment6/23',
          name: '单身族最喜欢的购物中心',
          meta: {
            id: '5-6-3',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment6/24',
          name: '中学生最喜欢的购物中心',
          meta: {
            id: '5-6-4',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment6/25',
          name: '大学生最喜欢的购物中心',
          meta: {
            id: '5-6-5',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment6/26',
          name: '高学历人群最喜欢的购物中心',
          meta: {
            id: '5-6-6',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment6/27',
          name: '上班族白领最喜欢的购物中心',
          meta: {
            id: '5-6-7',
            isShow: true,
          },
          component: environment,
        },
      ],
    },
    {
      path: '/system/environment/environment7',
      name: '不同消费属性客群',
      icon: 'm-icon-a-Group6247',
      meta: {
        id: '5-7',
        isShow: true,
      },
      component: environment,
      children: [
        {
          path: '/system/environment/environment7/28',
          name: '高消费能力人群最喜欢的购物中心',
          meta: {
            id: '5-7-1',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment7/29',
          name: '中等消费能力人群最喜欢的购物中心',
          meta: {
            id: '5-7-2',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment7/30',
          name: '苹果手机用户最喜欢的购物中心',
          meta: {
            id: '5-7-3',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment7/31',
          name: '华为手机用户最喜欢的购物中心',
          meta: {
            id: '5-7-4',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment7/32',
          name: 'OV手机用户最喜欢的购物中心',
          meta: {
            id: '5-7-5',
            isShow: true,
          },
          component: environment,
        },
        {
          path: '/system/environment/environment7/33',
          name: '小米手机用户最喜欢的购物中心',
          meta: {
            id: '5-7-6',
            isShow: true,
          },
          component: environment,
        },
      ],
    },
  ],
};
export default environments;
