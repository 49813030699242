import router from '.';
import Vue from 'vue';
const that = Vue.prototype;

export default function afterEachRouter(): void {
  router.beforeEach(async (to, from, next) => {
    const { name } = to;
    that.$beaconAction.onUserAction('submenu_click', {
      submenu: name,
    });
    next();
  });
}

