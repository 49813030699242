import { RouterConfig } from '@/types/router';
const report: RouterConfig = {
  path: '/report',
  name: '统计报告',
  meta: {
    id: '7',
    isShow: true,
  },
  component: () => import('@/pages/systemPages/blank/index.vue'),
  children: [
    {
      path: '/report/report',
      name: '统计报告2',
      icon: 'icon-platform',
      meta: {
        id: '7-1',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/report/index.vue'),
    },
  ],
};
export default report;
