


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class RankFilter extends Vue {
  sliderValue=[0, 0];
  @Prop({
    type: String,
    required: false,
    default: '',
  })  title !: string;
  @Prop({
    type: Array,
    required: false,
    default: [0, 0],
  })  sliderVal!: [0, 0];
  step = 1;
  min = 0;
  max = 100;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })  type !: number;

  inputEvent(): void {
    this.sliderValue.forEach((i) => {
      Number(i);
    });
    this.$emit('sliderChange', this.sliderValue);
  }
  mounted(): void {
    setTimeout(() => {
      this.sliderValue = this.sliderVal;
    }, 0);
  }
  sliderEvent(): void {
    this.$emit('sliderChange', this.sliderValue);
  }
}
