


















import { Vue, Component } from 'vue-property-decorator';
import { initPermission } from './permission';
import beforeEachRouter from '@/router/beforeEachRoute';
import afterEachRouter from '@/router/afterEachRoute';
@Component({
  components: {
  },
})
export default class App extends Vue {
  created(): void{
    this.clearRouter();
    initPermission();
    beforeEachRouter();
    afterEachRouter();

    const store = sessionStorage.getItem('store');
    // 在页面加载时读取sessionStorage里的状态信息
    if (store) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(store)));
    }
  }
  mounted(): void {
    // 在页面刷新之前将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      this.setStorage();
    });
  }

  clearRouter(): void{
    // feat: 路由文件名称更新，路由缓冲处理
    const analysis1 = '/system/intensity/analysis1';
    const regional = '/system/intensity/regional';
    const intensityRouterPath = sessionStorage.getItem('intensityRouterPath');
    const initSystemPath = sessionStorage.getItem('initSystemPath');
    if (intensityRouterPath === regional) return;
    if (intensityRouterPath?.indexOf(analysis1) === -1) {
      const ary = intensityRouterPath?.split('/');
      const path = `${analysis1}/${ary[ary.length - 1]}`;
      sessionStorage.setItem('intensityRouterPath', path);
      if (initSystemPath?.indexOf('/system/intensity/analysis/') !== -1) {
        sessionStorage.setItem('initSystemPath', path);
      }
    }
  }

  setStorage(): void{
    const { state } = this.$store;
    const states = JSON.stringify(state);
    sessionStorage.setItem('store', states);
  }
}
