



































































































































































































/* eslint-disable @typescript-eslint/naming-convention */
import { Vue, Component, Watch } from 'vue-property-decorator';
import compareCard from '@/components/CompareCard/index.vue';
import portraitPrivcar from '@/components/portraitPrivcar/index.vue';
import { deepClone } from '@/utils';
import {
  shoppingMallArrival,
  consumeAbility,
  differentAgeConsumeDetails,
  marketPenetration,
} from '@/utils/echarts/echarts';
import {
  getAoiPortrait,
  perAgeNumTheSum,
} from '@/services/api';
import {
  PerAgePortraitSParams,
  PerAgeNumTheSumParams,
} from '@/types/store';
import tdesignFooter from '@/components/GlobalFooter/index.vue';


@Component({
  components: {
    compareCard,
    portraitPrivcar,
    tdesignFooter,
  },
})
export default class PortraitEconomy extends Vue {
  $dayjs: any;
  slotEcharts = true;
  slotcontent = false;
  show = true;
  show1 = true;
  show2 = true;
  show3 = true;
  show4 = true;
  show5 = true;
  show6 = true;
  order = true;
  reset = false;
  excelArr: any = [];
  displayData = [{}];
  duration = this.$store.state.mall.duration;
  mall_id = this.$store.state.mall.mallId;
  aoiId = this.$store.state.mall.aoiId;
  numType = this.$store.state.mall.numType;
  date = this.$store.state.mall.date;
  consumeLevel = {};
  shoppingMallArrival = {};
  consumeAbility = {};
  differentAgeConsumeDetails = {};
  lifeStage = {};
  maritalStatus = {};
  childStatus = {};
  education = {};
  mallTotal = 0;
  mallTotalStatus = false;
  pravcar = Vue.prototype.$GLOBAL.privacyInfo;

  echartsShow = false;

  activated(): void{
    this.echartsShow = true;
  }

  deactivated(): void{
    this.echartsShow = false;
  }

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }
  mounted(): void{
    this.pageHandler();
  }
  async pageHandler(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后允许下载
    this.show = true;
    this.show1 = true;
    this.show2 = true;
    this.show3 = true;
    this.show4 = true;
    this.show5 = true;
    this.show6 = true;
    this.mall_id = this.$store.state.mall.mallId;
    this.date = this.$store.state.mall.date;
    this.duration = this.$store.state.mall.duration;
    this.aoiId = this.$store.state.mall.aoiId;
    this.numType = this.$store.state.mall.numType;
    // 年龄分布
    const PerAgePortraitSParams: any = {
      aoi_id: this.aoiId,
      mall_id: this.mall_id,
      date: this.date,
      duration: this.duration,
      num_type: this.numType,
      type: 102,
      j_type: 1,
      sum_type: 0,
    };
    const title = '年龄';
    const agePortraitRes = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title,
      '',
      shoppingMallArrival,
      [''],
    );
    this.shoppingMallArrival = agePortraitRes.option;
    this.show = false;
    // 性别
    PerAgePortraitSParams.type = 101;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry = ['#0062ff', '#ee5c3d'];
    const title1 = '性别';
    const title1s = '男女性别占比';
    const sexPortraitRes = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title1,
      title1s,
      consumeAbility,
      colorAry,
    );
    this.consumeAbility = sexPortraitRes.option;
    this.show1 = false;
    // 人生阶段
    PerAgePortraitSParams.type = 106;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry2 = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
    const title2 = '人生阶段';
    const title2s = '人生阶段占比';
    const portraitRes1 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title2,
      title2s,
      consumeAbility,
      colorAry2,
    );
    this.lifeStage = portraitRes1.option;
    this.show4 = false;
    // 婚姻状态
    PerAgePortraitSParams.type = 105;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry3 = colorAry;
    const title3 = '婚姻状态';
    const title3s = '婚姻状态占比';
    const portraitRes2 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title3,
      title3s,
      consumeAbility,
      colorAry3,
    );
    this.maritalStatus = portraitRes2.option;
    this.show5 = false;
    // 子女状态
    PerAgePortraitSParams.type = 104;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const colorAry4 = colorAry2;
    const title4 = '子女状态';
    const title4s = '子女年龄占比';
    const portraitRes4 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title4,
      title4s,
      consumeAbility,
      colorAry4,
    );

    this.childStatus = portraitRes4.option;
    this.show6 = false;
    // 学历
    PerAgePortraitSParams.type = 103;
    PerAgePortraitSParams.j_type = 0;
    PerAgePortraitSParams.sum_type = 0;
    const title5 = '学历';

    const portraitRes5 = await this.getPerAgePortrait(
      PerAgePortraitSParams,
      title5,
      title1s,
      shoppingMallArrival,
      [''],
    );
    portraitRes5.datas.reverse();
    this.education = portraitRes5.option;
    this.show3 = false;
    // this.differentSexConsumeDetails = differentSexConsumeDetails();

    // 客群性别年龄人数分布
    // perAgeNumTheSum
    // const params: PerAgeNumTheSumParams = {
    //   mall_id: this.mall_id,
    //   date: this.date,
    //   duration: this.duration,
    //   num_type: 20,
    //   pop_type: 21,
    // };
    // this.reset = false;
    // const ageNumRes = await this.getperAgeNumTheSum(params);

    // this.differentAgeConsumeDetails = ageNumRes.option;
    this.show2 = false;
    this.excelArr = [
      agePortraitRes.datas,
      sexPortraitRes.datas,
      portraitRes5.datas,
      portraitRes1.datas,
      portraitRes2.datas,
      portraitRes4.datas,
    ];
    this.$stores.global.setDownAble(true); // 数据全部加载完全后允许下载
  }

  async getperAgeNumTheSum(params: PerAgeNumTheSumParams): Promise<any> {
    const res = await perAgeNumTheSum(params);
    const { data, datas } = res;
    this.displayData = res;
    const { manData, menData, texts } = data;
    const option = differentAgeConsumeDetails(texts, manData, menData, 1);

    return {
      option,
      datas,
    };
  }

  async getPerAgePortrait(
    params: PerAgePortraitSParams,
    titile: string,
    title1s: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    callback: any,
    colorAry?: string[],
  ): Promise<any> {
    const res = await getAoiPortrait(params);
    const { data, datas } = res;
    const { percents, texts } = data;
    const option = callback(texts, percents, titile, title1s, colorAry);
    return {
      option,
      datas,
    };
  }
  inOrder(): void {
    this.reset = !this.reset;
    if (this.reset) {
      const displaypercent = deepClone(this.displayData);
      const displaypercentTwo = deepClone(this.displayData);
      this.orderDataRender(displaypercent, displaypercentTwo, 'num_percent');
      this.differentAgeConsumeDetails = marketPenetration(
        displaypercent.datas.map((i: any) => i.text),
        displaypercent.datas.map((i: any) => i.num_percent),
        displaypercentTwo.datas.map((i: any) => i.num_percent),
        '男性占比',
        '女性占比',
        '市场渗透率(%)',
      );
    } else {
      this.differentAgeConsumeDetails = differentAgeConsumeDetails(
        (this.displayData as any).data.texts,
        (this.displayData as any).data.manData,
        (this.displayData as any).data.menData,
        1,
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  orderDataRender(val1: any, val2: any, sortVal: any): void {
    val1.datas.sort((a: any, b: any) => (b as any)[sortVal] - (a as any)[sortVal]);
    val2.datas.sort((a: any, b: any) => (b as any)[sortVal] - (a as any)[sortVal]);
    val1.datas.forEach((i: any) => {
      if (i.text.includes('男')) {
        // eslint-disable-next-line no-param-reassign
        i[sortVal] = '';
      }
    });
    val2.datas.forEach((i: any) => {
      if (i.text.includes('女')) {
        // eslint-disable-next-line no-param-reassign
        i[sortVal] = '';
      }
    });
  }
  @Watch('$store.state.mall', { deep: true })
  changeMall(): void {
    this.pageHandler();
  }

  @Watch('$store.state.global.aoiDownloadStatus')
  changeExport(): void {
    const datas = [];
    const status = this.$store.state.global.aoiDownloadStatus;
    if (status === true && this.echartsShow) {
      const dateObj = {
        d: this.date,
        xw: `${this.$dayjs(this.date).subtract(6, 'day')
          .format('YYYY-MM-DD')}~${this.date}`,
        xm: this.$dayjs(this.date).format('YYYY-MM'),
      };
      this.excelArr.forEach((i: any) => {
        i.forEach((j: any) => {
        // eslint-disable-next-line no-param-reassign
          j.time = (dateObj as any)[this.duration];
        });
      });
      datas.push({
        sheetData: this.excelArr[0],
        sheetName: '年龄人数分布',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '年龄分布', '占比'],
        columnWidths: [10, 10, 8],
      });
      datas.push({
        sheetData: this.excelArr[1],
        sheetName: '性别',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '性别', '占比'],
        columnWidths: [10, 10, 8],
      });
      datas.push({
        sheetData: this.excelArr[2],
        sheetName: '学历',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '学历', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[3],
        sheetName: '人生阶段',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '人生阶段', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[4],
        sheetName: '婚姻状态',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '婚姻状态', '占比'],
        columnWidths: [10, 8, 8],
      });
      datas.push({
        sheetData: this.excelArr[5],
        sheetName: '子女状态',
        sheetFilter: ['time', 'text', 'percent'],
        sheetHeader: ['日期', '子女状态', '占比'],
        columnWidths: [10, 8, 8],
      });
      this.$root.$data.event.$emit('getAoiData', datas);
    }
  }
}
