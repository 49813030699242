import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import store from '../index';
// import { getAxiosData } from '@/services/apiConfig';
// import { Result } from '@/types/services';
/* eslint-disable @typescript-eslint/naming-convention*/
// type MallObj = {
//   value: string;
//   label: string;
//   children: any;
// };

@Module({ dynamic: true, name: 'global', store })
class Global extends VuexModule {
  mallId = NaN;
  date = '';
  duration = 'd';
  downloadStatus = false;
  isChecked = false;
  isDownloadPPT = false;
  name = '';
  aoiDownloadStatus = false;
  downAble = false;

  @Mutation
  setMallId(mallId: number): void {
    if (this.mallId !== mallId) {
      this.mallId = mallId;
    }
  }

  @Mutation
  setDate(date: string): void {
    if (this.date !== date) {
      this.date = date;
    }
  }

  @Mutation
  setDownloadStatus(downloadStatus: boolean): void {
    if (this.downloadStatus !== downloadStatus) {
      this.downloadStatus = downloadStatus;
    }
  }
  @Mutation
  setDownAble(downAble: boolean): void {
    this.downAble = downAble;
  }
  @Mutation
  checkDetails(isCheck: boolean): void {
    this.isChecked = isCheck;
  }

  @Mutation
  checkDownloadPPT(isDownloadPPT: boolean): void {
    this.isDownloadPPT = isDownloadPPT;
  }

  @Mutation
  reportName(val: string): void {
    this.name = val;
  }

  @Mutation
  setAoiDownloadStatus(downloadStatus: boolean): void {
    if (this.aoiDownloadStatus !== downloadStatus) {
      this.aoiDownloadStatus = downloadStatus;
    }
  }
  // @Action
  // async getMallByUserId(): Promise<any> {
  //   const params = {};
  //   const url = 'getMallByUserId';
  //   const getData = await getAxiosData(params, url);
  //   const res = getData as Result<any>;
  //   const datas: any = Object.values(res.data);

  //   const mallObj: any = {};
  //   /* eslint-disable no-restricted-syntax */
  //   datas.forEach((item: any) => {
  //     const { mall_id, name, province, city, status } = item;
  //     if (!mallObj[province]) {
  //       mallObj[province] = {};
  //     }
  //     if (!mallObj[province][city]) {
  //       mallObj[province][city] = {};
  //     }
  //     mallObj[province][city][mall_id] = {
  //       name,
  //       status,
  //     };
  //   });

  //   const mallObjs = [];
  //   // todo 推荐使用 JavaScript 的高阶函数代替
  //   for (const province in mallObj) {
  //     const cityItem = mallObj[province];

  //     const provinceObj: MallObj = {
  //       value: province,
  //       label: province,
  //       children: [],
  //     };
  //     for (const city in cityItem) {
  //       const mallItem = cityItem[city];
  //       const cityObj: MallObj = {
  //         value: city,
  //         label: city,
  //         children: [],
  //       };
  //       for (const mall_id in mallItem) {
  //         const mall = mallItem[mall_id];
  //         const mallObj = {
  //           value: mall_id,
  //           label: mall.name,
  //           status: mall.status,
  //         };
  //         cityObj.children.push(mallObj);
  //       }
  //       provinceObj.children.push(cityObj);
  //     }
  //     mallObjs.push(provinceObj);
  //   }
  //   const initProvince = mallObjs[0].value;
  //   const initCity = mallObjs[0].children[0].value;
  //   const initMall = mallObjs[0].children[0].children[0].value;
  //   const value = [initProvince, initCity, initMall];
  //   return {
  //     option: mallObjs,
  //     value,
  //   };
  // }
}

export default Global;
