



























































import { aoiInfoObj, prefix } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { AoiParams, LabelStyle } from '@/types/store';
import { aoiDepressionRank } from '@/services/api';
import rankFilter from '@/components/rankFilter/index.vue';
import rankTable from '@/components/rankTable/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
import { ConfigTypeMap } from 'dayjs';
import gradientCard from '@/components/GradientCard/index.vue';
import aoiPortrait from '@/components/portrait/aoiPortrait/index.vue';
import {
  clearMall,
  drawMall,
  getMapCenter,
  getSectionAry,
  fillColorPage,
  zoomUp,
  zoomDown,
} from '@/components/map/map';
import mallName from '@/components/mallName/index.vue';
import { getContainer } from '@/components';

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
@Component({
  components: {
    rankFilter,
    rankTable,
    gradientCard,
    mallName,
    spinCard,
    aoiPortrait,
  },
})
export default class TouristSource extends Vue {
  prefix: string = prefix;
  tableWh = 481;
  $stores: any;
  $dayjs: any;
  text = '1平方公里';
  singleColor = 'blue';
  titleDisplay = false;
  colorAry = ['#B23597', '#DD498B', '#F47694', '#FA9FA0'].reverse();
  gradientWidth = 62;
  spinShow = true;
  gradientList = [
    {
      id: 1,
      color: this.colorAry[0],
      width: this.gradientWidth,
    },
    {
      id: 2,
      color: this.colorAry[1],
      width: this.gradientWidth,
    },
    {
      id: 3,
      color: this.colorAry[2],
      width: this.gradientWidth,
    },
    {
      id: 4,
      color: this.colorAry[3],
      width: this.gradientWidth,
    },
  ];
  gradientValue = [
    {
      id: 1,
      num: 0,
    },
    {
      id: 2,
      num: 0,
    },
    {
      id: 3,
      num: 0,
    },
    {
      id: 4,
      num: 0,
    },
  ];
  table = [];
  data = [];
  map: any;
  filterValue: number[] = [0, 100];
  polygon: any;
  polygonColor = '#468ee6';
  activePolygonColor = '#e24d59';
  duration = '';
  mallId = NaN;
  date = '';
  adcode = '';
  dates = '';
  boundary = '';
  center = '';
  title = '';
  params: any = [];
  circleAry: any = [];
  polygonAry: any = [];
  rankLabelAry: any = [];
  nameLabelAry: any = [];
  activePolygon!: any;
  rowActive = 0;
  pageType = '';
  pageType2 = '';
  sourceType = '';
  labelStyle: LabelStyle = {
    color: 'white',
    border: 'none',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    transform: 'translateY(-50%) translateX(-50%)',
    zIndex: 10,
  };
  infoStyle = {
    zIndex: 10,
    transform: 'translateY(-120%) translateX(-53%)',
    backgroundColor: '#fff',
    color: '#030227',
    borderRadius: '6px',
    border: '0px',
  };
  moveStyle =
  'display:flex;justify-content:space-between;align-items:center;padding:6px 0;font-weight:bolder;';
  moveLabel!: any;
  closeStatus = false;
  mapOverlayConfig: any = {
    18: {
      radius: 10,
      polygonVisible: true,
      nameVisible: true,
      rankVisible: true,
      strokeWeight: 5,
    },
    17: {
      radius: 20,
      polygonVisible: true,
      nameVisible: true,
      rankVisible: true,
      strokeWeight: 5,
    },
    16: {
      radius: 40,
      polygonVisible: true,
      nameVisible: true,
      rankVisible: true,
      strokeWeight: 5,
    },
    15: {
      radius: 80,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: true,
      strokeWeight: 5,
    },
    14: {
      radius: 100,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 5,
    },
    13: {
      radius: 120,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    12: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    11: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    10: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    9: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    8: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    7: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    6: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    5: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    4: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    3: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    2: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
    1: {
      radius: 200,
      polygonVisible: false,
      nameVisible: false,
      rankVisible: false,
      strokeWeight: 3,
    },
  };
  initZom = 16;
  initRadius = 40;
  mallMarker!: any;
  mapHeight = '0';
  $jQ: any;
  buriedPointRank = '';
  buriedPointSidler = '';
  aoiTypeCurrent = 1;

  buriedPointObj = {
    city: 'local_passenger_source_rank_click',
    portrait: 'aoi_top_image_rank_click',
  };
  aoiTypeList = [
    {
      id: 1,
      name: '居住地',
      type: 1,

    },
    {
      id: 2,
      name: '工作地',
      type: 2,
    },
  ];
  aoiTypeCurrentObj = this.aoiTypeList[0];
  depressionObj = {
    filter: ['date', 'rank', 'aoi_name', 'num'],
    header: ['日期', '排名', '区域名称', '区域人口'],
  };
  commonObj = {
    filter: ['date', 'cover_rank', 'aoi_name', 'source_num', 'pop', 'cover'],
    header: ['日期', '排名', '区域名称', '输送客流', '区域人口', '渗透率'],
  };
  aoiType = 1;
  get touristStatus(): boolean {
    return this.pageType === '1';
  }

  get depressionStatus(): boolean {
    return this.pageType === '2';
  }

  get touristTopStatus(): boolean {
    return this.pageType === '3';
  }

  get cityStatus(): boolean {
    return this.pageType2 === '3-1';
  }

  get portraitStatus(): boolean {
    return this.pageType2 === '3-2';
  }

  get tableStatus(): boolean {
    return this.touristStatus || this.depressionStatus || this.cityStatus;
  }

  get coverStatus(): boolean {
    return this.touristStatus || this.cityStatus;
  }

  get aoiHoverStatus(): boolean {
    return this.touristStatus || this.depressionStatus || this.cityStatus;
  }

  get polygonStatus(): boolean {
    return this.touristStatus || this.depressionStatus || this.portraitStatus;
  }

  get buriedPointVal(): string {
    const { city, portrait } = this.buriedPointObj;
    return  this.cityStatus ? city : portrait;
  }

  get titleExtend(): boolean {
    return this.touristTopStatus;
  }

  get markerStatus(): boolean {
    return this.touristStatus || this.depressionStatus || this.portraitStatus;
  }

  get cityCenterStatus(): boolean {
    return this.cityStatus;
  }

  mounted(): void {
    this.initRadius = this.mapOverlayConfig[16].radius;
    this.mapHeight = `${getContainer()}px`;
    this.initMap('map-container', this.initZom);
    this.pageHandler();
  }

  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  // 埋点
  filterClickHandler(item: any): void{
    const { id, type, name } = item;
    if (this.aoiTypeCurrent === id) return;
    this.aoiTypeCurrent = id;
    this.aoiType = type;
    this.aoiTypeCurrentObj = item;
    this.$stores.mall.setAoiNumType(type);
    this.$stores.mall.setAoiTypeCurrentObj(item);
    this.pageHandler();
    this.buriedPointHandler(this.buriedPointVal, {
      type: name,
    });
  }
  mapCenter(center: string): void {
    const latLng = center.split(',');
    const centers = new (window as any).qq.maps.LatLng(latLng[0], latLng[1]);
    this.map.setCenter(centers);
    this.map.panBy(206, 0);
  }


  updateCloseStatus(status: boolean): void{
    this.closeStatus = status;
  }

  getTableByType(type: string): any {
    let table;
    if (type === '1') {
      table = [
        {
          title: '排名',
          key: 'cover_rank',
          sortable: true,
          width: 70,
        },
        {
          title: '区域名称',
          key: 'aoi_name',
          tooltip: true,
          width: 110,
        },
        {
          title: '输送客流',
          key: 'source_num',
          sortable: true,
        },
        {
          title: '区域人口',
          key: 'pop',
          sortable: true,
        },
        {
          title: '渗透率(%)',
          key: 'cover',
          sortable: true,
        },
      ];
    } else if (type === '2') {
      table = [
        {
          title: '排名',
          key: 'rank',
          sortable: true,
        },
        {
          title: '区域名称',
          key: 'aoi_name',
          sortable: true,
          tooltip: true,
        },
        {
          title: '区域人口',
          key: 'num',
          sortable: true,
        },
      ];
    } else {
      alert('未配置');
    }
    return table;
  }

  buriedPointHandler(buriedPoint: string, params?: any): void{
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  async pageHandler(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.data = [];
    const { mall, pageConfig } = this.$store.state;
    const { boundary, center, date, dates, mallId, duration, adcode, numType,
      aoiTypeCurrentObj, cityAdcode, cityCenter } = mall;
    this.boundary = boundary;
    this.date = date;
    this.dates = dates;
    this.mallId = mallId;
    this.duration = duration;
    this.adcode = adcode;
    this.aoiTypeCurrent = numType;
    this.aoiType = numType;
    this.aoiTypeCurrentObj = aoiTypeCurrentObj;
    this.$stores.mall.setAoiTypeCurrentObj(aoiTypeCurrentObj);
    this.filterValue = pageConfig.aoiFilterValue;
    const { path } = this.$route;
    const pathAry = path.split('/');
    const pageTypes = pathAry[3];
    const types = pathAry[4];
    const pageObj = aoiInfoObj[pageTypes][types];
    const { pageType, type, title, buriedPointRank, buriedPointSidler, pageType2 } = pageObj;
    this.buriedPointRank = buriedPointRank;
    this.buriedPointSidler = buriedPointSidler;
    this.pageType = pageType;
    this.pageType2 = pageType2;
    this.sourceType = type;
    this.title = title;
    this.center = this.cityCenterStatus ? cityCenter : center;
    this.clearOverlay();
    if (this.center) {
      const center = getMapCenter(this.center);
      this.map.setCenter(center);
      this.map.panBy(206, 0);
      clearMall(this.mallMarker);
      if (this.markerStatus) {
        this.mallMarker = drawMall(this.map, center);
      }
    }
    this.params = {
      mall_id: this.mallId,
      date: this.date,
      duration: this.duration,
      start: this.filterValue[0],
      end: this.filterValue[1],
      type,
      url: '',
    };
    // touristStatus,depressionStatus,touristTopStatus
    if (this.touristStatus) {
      this.params.url = 'getAoiDepressionRank';
      this.tableWh = 481;
      this.$jQ('.map-zoom').css('right', this.tableWh + 20);
      this.table = this.getTableByType(this.pageType);
    } else if (this.depressionStatus) {
      this.params.url = 'getLowPassengerFlow';
      this.tableWh = 340;
      this.$jQ('.map-zoom').css('right', this.tableWh + 20);
      this.table = this.getTableByType(this.pageType);
    } else if (this.touristTopStatus) {
      console.log('客源地分析top');
      this.map.setZoom(13);
      this.$jQ('.map-zoom').css('right', 20);
      this.params.url = 'getAoiDepressionRank';
      if (this.cityStatus) {
        this.adcode = cityAdcode;
        this.params = {
          adcode: this.adcode,
          date: this.date,
          duration: this.duration,
          type: this.aoiType,
          mall_id: this.mallId,
          url: 'getAoiDataByCity',
        };
        this.tableWh = 520;
        this.table = this.getTableByType('1');
      } else if (this.portraitStatus) {
        this.params = {
          mall_id: this.mallId,
          date: this.date,
          duration: this.duration,
          type: this.aoiType,
          url: 'getTopAoiPassengerFlowRank',
        };
      }
    } else {
      console.error('未配置界面，请查看配置后再试');
      return;
    }
    this.data = await this.getAoiDepressionRank(this.params);
    if (!this.data) {
      return;
    }
    const circleAry: any = [];
    const polygonAry: any = [];
    const rankLabelAry: any = [];
    const nameLabelAry: any = [];
    const zoom = this.map.getZoom() ? this.map.getZoom() : 16;
    const {
      nameVisible,
      rankVisible,
      polygonVisible,
      radius,
      strokeWeight,
    } = this.mapOverlayConfig[zoom];
    let weightType = 1;
    let weightField = 'num';
    if (this.coverStatus) {
      weightType = 2;
      weightField = 'cover';
    }
    const sectionAry = getSectionAry(
      this.data,
      weightField,
      this.colorAry,
      weightType,
    );
    this.gradientValue = [];
    sectionAry.forEach((item: any, index: number) => {
      const obj = {
        id: index + 1,
        num: item.max,
      };
      this.gradientValue.push(obj);
    });
    this.data.forEach((item: any, index: number) => {
      const {
        center_gcj: centerGcj,
        aoi_id: aoiId,
        boundary,
        aoi_name: aoiName,
      } = item;
      let rank!: number;
      let weight!: number;
      if (this.coverStatus) {
        rank = item.cover_rank;
        weight = item.cover;
      } else {
        rank = item.rank;
        weight = item.num;
      }
      (this.data[index] as any).index = index;
      const latLng = centerGcj.split(',');
      const center = this.overlayCenter(latLng[0], latLng[1]);

      const fillColor = fillColorPage(weight, sectionAry);
      // 圆形覆盖物
      const circle = this.drawCircle(
        this.map,
        center,
        index,
        aoiId,
        radius,
        0,
        item,
        3,
        fillColor,
      );

      // 排名覆盖物
      const rankContent = `<div style="width: 100%;height:100%;text-align:center;line-height: 1.8em;font-size: 18px;">${rank}</div>`;
      const rankLabel = this.drawLabel(
        this.map,
        center,
        rankContent,
        index,
        aoiId,
        this.labelStyle,
        rankVisible,
      );
      // 名称覆盖物
      const nameContent = `<div class="theme-text-color" style="width: 100%;height:100%;text-align:center;font-size: 14px;margin-top:54px;">${aoiName}</div>`;
      const nameLabel = this.drawLabel(
        this.map,
        center,
        nameContent,
        index,
        aoiId,
        this.labelStyle,
        nameVisible,
      );
      this.circlesClick(circle);

      if (this.aoiHoverStatus) {
        this.circlesHover(circle);
      }

      circleAry.push(circle);

      let path = [];
      if (this.polygonStatus) {
        path = this.processPolygonData(boundary);
        // 边界覆盖物
        const polygon = this.drawPolygon(
          this.map,
          path,
          index,
          aoiId,
          this.polygonColor,
          polygonVisible,
          1,
        );
        polygonAry.push(polygon);
      }
      rankLabelAry.push(rankLabel);
      nameLabelAry.push(nameLabel);
      if (index === 0) {
        this.clearPolygon(this.activePolygon);
        circle.setStrokeWeight(strokeWeight);
        circle.active = 1;
        if (this.polygonStatus) {
          this.activePolygon = this.drawPolygon(
            this.map,
            path,
            1,
            aoiId,
            this.activePolygonColor,
            polygonVisible,
            2,
          );
        }
      }
    });
    this.rowActive = 0;
    this.circleAry = circleAry;
    this.polygonAry = polygonAry;
    this.rankLabelAry = rankLabelAry;
    this.nameLabelAry = nameLabelAry;
    this.spinShow = false;
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }

  clearOverlay(): void {
    if (this.circleAry.length === 0) {
      return;
    }
    this.clearPolygon(this.activePolygon);
    this.circleAry.forEach((item: any, index: number) => {
      this.circleAry[index].setMap(null);
      if (this.polygonStatus) this.polygonAry[index].setMap(null);
      this.rankLabelAry[index].setMap(null);
      this.nameLabelAry[index].setMap(null);
    });
    this.circleAry = [];
    this.polygonAry = [];
    this.rankLabelAry = [];
    this.nameLabelAry = [];
    this.activePolygon = '';
  }

  overlayCenter(lat: string, lng: string): string {
    return new (window as any).qq.maps.LatLng(parseFloat(lat), parseFloat(lng));
  }

  overlayPath(lat: string, lng: string): string {
    return new (window as any).qq.maps.LatLng(parseFloat(lat), parseFloat(lng));
  }

  clearStrokeWeight(): void {
    this.circleAry.forEach((item: any) => {
      if (item.active === 1) {
        item.setStrokeWeight(0);
        // eslint-disable-next-line no-param-reassign
        item.active = 0;
      }
    });
  }

  circlesClick(circle: ConfigTypeMap): void {
    (window as any).qq.maps.event.addListener(circle, 'click', (event: any) => {
      const { index, aoiId } = event.target;
      this.clearStrokeWeight();
      const zoom = this.map.getZoom();
      const { strokeWeight, polygonVisible, rank } = this.mapOverlayConfig[zoom];
      event.target.setStrokeWeight(strokeWeight);
      // eslint-disable-next-line no-param-reassign
      event.target.active = 1;
      this.rowActive = index;
      if (this.polygonStatus) {
        this.clearPolygon(this.activePolygon);
        const { path } = this.polygonAry[index];
        this.activePolygon = this.drawPolygon(
          this.map,
          path,
          1,
          aoiId,
          this.activePolygonColor,
          polygonVisible,
          2,
        );
      }

      this.buriedPointHandler('aoi_ranking_click', { type: rank });
      const row = event.target.item;
      if (this.tableStatus) {
        (this.$refs.tables as any).tableClickHandler(row, 1);
      } else {
        this.portraitCallBack(aoiId);
      }
    });
  }

  portraitCallBack(aoiId: string): void {
    this.$stores.mall.setAoiId(aoiId);
    this.updateCloseStatus(true);
    this.buriedPointHandler('aoi_competition_image_basic_click');
  }

  circlesHover(circle: ConfigTypeMap): void {
    (window as any).qq.maps.event.addListener(
      circle,
      'mouseover',
      (event: Event) => {
        const { item } = event.target as any;
        const {
          aoi_id: aoiId,
          aoi_name: aoiName,
          cover,
          source_num: sourceNum,
          pop,
          center_gcj: centerGcj,
        } = item;
        let content: string;
        if (this.coverStatus) {
          const { cover_rank: rank } = item;
          content = `<div style='border:none !important;user-select:none;text-align:center;font-size: 14px;padding:10px 20px;position:relative;'>
      <ul class='move-info'>
        <li style=${this.moveStyle}><span style="margin-right:140px;">${aoiName}</span><span>排名：${rank}</span></li>
        <li style=${this.moveStyle}><span style="margin-right:140px;">输送客流</span><span>${sourceNum}</span></li>
        <li style=${this.moveStyle}><span style="margin-right:140px;">区域人口</span></span><span>${pop}</span></li>
        <li style=${this.moveStyle}><span style="margin-right:140px;">渗透率(%)</span><span>${cover}%</span></li>
      </ul>
      <div style='position: absolute;width: 0px;height: 0px;left:50%;bottom:-6%;line-height: 0px;border-width: 20px 16px 0;border-style: solid dashed dashed dashed;border-left-color: transparent;border-right-color: transparent;color: #fff;'></div></div>`;
        } else {
          const { rank } = item;
          const { num } = item;
          content = `<div style='border:none !important;user-select:none;text-align:center;font-size: 14px;padding:10px 20px;position:relative;'>
      <ul class='move-info'>
        <li style=${this.moveStyle}><span style="margin-right:140px;">${aoiName}</span><span>排名：${rank}</span></li>
        <li style=${this.moveStyle}><span style="margin-right:140px;">居住人口数</span><span>${num}</span></li>
      </ul>
      <div style='position: absolute;width: 0px;height: 0px;left:50%;bottom:-6%;line-height: 0px;border-width: 20px 16px 0;border-style: solid dashed dashed dashed;border-left-color: transparent;border-right-color: transparent;color: #fff;'></div></div>`;
        }

        const latLng = centerGcj.split(',');
        const center = this.overlayCenter(latLng[0], latLng[1]);
        this.moveLabel = this.drawLabel(
          this.map,
          center,
          content,
          0,
          aoiId,
          this.infoStyle,
          true,
        );
      },
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (window as any).qq.maps.event.addListener(circle, 'mouseout', () => {
      this.clearLabel(this.moveLabel);
    });
  }

  @Watch('$store.state.global.downloadStatus')
  changeExport(): void {
    const dateObj = {
      d: this.date,
      xw: `${this.$dayjs(this.date).subtract(6, 'day')
        .format('YYYY-MM-DD')}~${this.date}`,
      xm: this.$dayjs(this.date).format('YYYY-MM'),
    };
    this.data.forEach((i: any) => {
      // eslint-disable-next-line no-param-reassign
      i.date = (dateObj as any)[this.duration];
    });


    const datas = [];
    const status = this.$store.state.global.downloadStatus;


    const sheetFilter = this.depressionStatus ?  this.depressionObj.filter : this.commonObj.filter;
    const sheetHeader = this.depressionStatus ?  this.depressionObj.header : this.commonObj.header;
    let titles = `数据项:客流来源-${this.title}`;
    if (this.titleExtend) {
      titles = `${titles}-${this.aoiTypeCurrentObj.name}`;
    }
    const sheetMultiHeader0 = titles;
    if (status === true) {
      datas.push({
        sheetData: this.data,
        sheetName: this.title,
        sheetFilter,
        sheetHeader,
        sheetMultiHeader: [
          [sheetMultiHeader0],
        ],
        columnWidths: [8, 8, 8, 8, 8, 8],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }

  initMap(el: string, zoom: number): void {
    this.map = new (window as any).qq.maps.Map(document.getElementById(el), {
      center: new (window as any).qq.maps.LatLng(22.53793, 114.0683),
      mapStyleId: 'style1',
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      zoom,
      offset: {
        // 中心点偏移
        x: 0,
        y: 0,
      },
    });
    this.mapZoomEvent();
  }

  drawPolygon(
    map: ConfigTypeMap,
    path: string[],
    index: number,
    aoiId: number,
    color: string,
    visible: boolean,
    zIndex: number,
  ): any {
    const polygon = new (window as any).qq.maps.Polygon({
      map,
      path,
      strokeWeight: 3,
      editable: false,
      strokeColor: color,
      // fillColor: '#FFF',
      cursor: 'default',
      zIndex,
      visible,
      index,
      aoiId,
    });
    return polygon;
  }

  drawCircle(
    map: ConfigTypeMap,
    center: string,
    index: number,
    aoiId: number,
    radius: number,
    strokeWeight: number,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    item: any,
    zIndex: number,
    fillColor: string,
  ): any {
    const circle = new (window as any).qq.maps.Circle({
      map,
      center,
      radius,
      fillColor,
      strokeWeight,
      strokeColor: '#fff',
      zIndex,
      index,
      aoiId,
      item,
    });
    return circle;
  }

  drawLabel(
    map: ConfigTypeMap,
    position: string,
    content: string,
    index: number,
    aoiId: number,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    style: any,
    visible: boolean,
  ): any {
    const label = new (window as any).qq.maps.Label({
      map,
      position,
      content,
      style,
      zIndex: 2,
      index,
      aoiId,
      visible,
    });
    return label;
  }
  /* eslint-disable no-param-reassign */

  mapZoomEvent(): void {
    (window as any).qq.maps.event.addListener(
      this.map,
      'zoom_changed',
      (event: Event) => {
        const { zoom } = event.target as any;
        let config = this.mapOverlayConfig[zoom];
        if (!config) {
          config = {
            radius: 200,
            polygonVisible: false,
            nameVisible: false,
            rankVisible: false,
          };
        }
        const {
          radius,
          nameVisible,
          rankVisible,
          polygonVisible,
          strokeWeight,
        } = config;
        if (this.activePolygon) {
          this.activePolygon.setVisible(polygonVisible);
        }
        this.circleAry.forEach((item: any, index: number) => {
          // this.circleAry[index].setRadius(radius);
          item.setRadius(radius);
          if (item.active === 1) {
            item.setStrokeWeight(strokeWeight);
          }
          const nameLabelItem = this.nameLabelAry[index];
          const rankLabelItem = this.rankLabelAry[index];

          nameLabelItem.setVisible(nameVisible);
          rankLabelItem.setVisible(rankVisible);
          if (this.polygonStatus) {
            const polygonItem = this.polygonAry[index];
            polygonItem.setVisible(polygonVisible);
          }
        });
      },
    );
  }

  processPolygonData(boundary: string): any {
    const path: any[] = [];
    const boundaryAry = boundary.split('|');
    boundaryAry.pop();
    boundaryAry.forEach((item: string) => {
      const latLng = new (window as any).qq.maps.LatLng(
        parseFloat(item.split(',')[0]),
        parseFloat(item.split(',')[1]),
      );
      path.push(latLng);
    });
    return path;
  }

  async getAoiDepressionRank(params: AoiParams): Promise<any> {
    const data = await aoiDepressionRank(params);
    return data;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  clearPolygon(polygon: any): void {
    if (polygon) {
      polygon.setMap(null);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  clearLabel(label: any): void {
    if (label) {
      label.setMap(null);
    }
  }

  sliderChange(val: number[]): void {
    // 进度条数据变化返回
    this.filterValue = val;
    this.$stores.pageConfig.setAoiFilterValue(val);
    this.$stores.pageConfig.setMallUpdateKey(`aoiMallUpdate${val}`);
    this.buriedPointHandler(this.buriedPointSidler);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  rowClick(val: any): void {
    // 列表点击事件返回数据
    this.rowActive = val.index;
    const { center_gcj: centerGcj, boundary, aoi_id: aoiId } = val;
    const latLng = centerGcj.split(',');
    const center = this.overlayCenter(latLng[0], latLng[1]);
    this.map.setCenter(center);
    this.map.setZoom(16);
    this.map.panBy(206, 0);
    const zoom = this.map.getZoom();
    const { polygonVisible, strokeWeight } = this.mapOverlayConfig[zoom];
    if (this.polygonStatus) {
      const path = this.processPolygonData(boundary);
      this.clearPolygon(this.activePolygon);
      this.activePolygon = this.drawPolygon(
        this.map,
        path,
        1,
        aoiId,
        this.activePolygonColor,
        polygonVisible,
        2,
      );
    }
    this.circleAry.forEach((item: any) => {
      item.setStrokeWeight(0);
      item.active = 0;
      if (item.aoiId === val.aoi_id) {
        item.setStrokeWeight(strokeWeight);
        item.active = 1;
      }
    });
  }
}
