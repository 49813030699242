








import { Vue, Component } from 'vue-property-decorator';
import { prefix } from '@/config/global';

@Component({
  components: {},
})
export default class GlobalFooter extends Vue {
  prefix: string = prefix;
}
