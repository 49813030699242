
































































import { Vue, Component, Prop } from 'vue-property-decorator';
import spinCard from '@/components/SpinCard/index.vue';
import { prefix } from '@/config/global';

/*
 *图表卡片
 */

@Component({
  components: {
    spinCard,
  },
})
export default class CompareCard extends Vue {
  prefix: string = prefix;
  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  spin!: false;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  showSpin!: false;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  cardHeight!: string;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  padding!: string;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  topHeight!: string;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  toppadding!: string;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  tips!: boolean;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  order!: boolean;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  orderBottom!: boolean;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  tipValue!: string;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  echartspadding!: string;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  echartsHeight!: string;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  title!: string;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  slotcontent!: boolean;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  slotEcharts!: boolean;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  line!: boolean;
  @Prop({
    type: String,
    required: false,
    default: 'right',
  })
  placement!: string;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  comparisonStatus!: boolean;
  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  dataStatus!: number;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  showSystemTips!: boolean;
}
