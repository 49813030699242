/* eslint-disable @typescript-eslint/naming-convention */
import { MallRankListParams } from '@/types/router';
export const prefix = 'mall-pro';
export const theme = 'light';
export const innersApi = 'https://lbsconsole.sparta.html5.qq.com/mall/inners_api/';
export const privacy = '数据量过低，由于数据统计样本失衡及用户数据隐私策略保护，该条指标将不展示具体数值。';
export const privacy2 = '数据量过低，由于数据统计样本失衡及用户数据<br>隐私策略保护，该条指标将不展示具体数值。';
export const privacyMallInfo = '客流人数过低，由于数据统计样本失衡及客流数据隐私保护，该项目暂无法显示竞争环境排名数据';
export const privacyInfo = '数据量过低，由于数据统计样本失衡及用户数据隐私策略保护，画像标签不展示。';
export const aoiRegionStatus: any = {
  1: '已通过',
  2: '待审核',
  0: '已删除',
  '-1': '已驳回',
};
export const aoiType: any = {
  1: '居住地',
  2: '工作地',
  3: '过路地',
};

export const reportObj: any = {
  w: {
    value: 'w',
    name: '周统计报告',
    reportName: '周客流报告',
    title: '报告类型(周)',
    type: '周',
    excelTitle: '周',
    dataType: 'xw',
  },
  m: {
    value: 'm',
    name: '月统计报告',
    reportName: '月客流报告',
    title: '报告类型(月)',
    type: '月',
    excelTitle: '月',
    dataType: 'xm',
  },
  s: {
    value: 's',
    name: '季度统计报告',
    reportName: '季度客流报告',
    title: '报告类型(季度)',
    type: '季度',
    excelTitle: '季度',
    dataType: 's',
  },
  y: {
    value: 'y',
    name: '年度统计报告',
    reportName: '年度客流报告',
    title: '报告类型(年度)',
    type: '年',
    excelTitle: '年',
    dataType: 'y',
  },
  h: {
    value: 'h',
    name: '节假日统计报告',
    reportName: '节假日客流报告',
    title: '报告类型(节假日)',
    type: '节假日',
    excelTitle: '年',
    dataType: 'xh',
  },
};

export const portraitCoding = {
  101: '性别',
  102: '年龄分布',
  103: '学历',
  104: '子女状态',
  105: '婚姻状态',
  106: '人生阶段',
  107: '消费水平',
  108: '购物商场到访频次',
  109: '餐饮消费频次',
  110: '餐饮消费水平',
  111: '居住社区房价',
  112: '是否有车',
  115: '手机品牌',
  116: '手机价格',
  501: '餐饮',
  502: '购物',
  503: '休闲娱乐',
  504: '运动健身',
  505: '汽车',
};

const touristAoiObj: any = {
  live: {
    pageType: '1',
    type: '1',
    title: '居住地排行',
    buriedPointRank: 'passenger_depression_residence_rank_click',
    buriedPointSidler: 'passenger_source_depression_residence_rank_click',
  },
  work: {
    pageType: '1',
    type: '2',
    title: '工作地排行',
    buriedPointRank: 'passenger_passenger_source_depression_workplace_rank_click',
    buriedPointSidler: 'passenger_source_depression_residence_rank_click',
  },
  crossing: {
    pageType: '1',
    type: '3',
    title: '过路地排行',
    buriedPointRank: 'passenger_passenger_source_depression_pass_by_rank_click',
    buriedPointSidler: 'passenger_source_depression_residence_rank_click',
  },
};

const depressionAoiObj: any = {
  live: {
    pageType: '2',
    type: '1',
    title: '居住地排行',
    buriedPointRank: 'depression_depression_residence_rank_click',
    buriedPointSidler: 'depression_rank_select',
  },
  work: {
    pageType: '2',
    type: '2',
    title: '工作地排行',
    buriedPointRank: 'depression_passenger_source_depression_workplace_rank_click',
    buriedPointSidler: 'depression_rank_select',
  },
  crossing: {
    pageType: '2',
    type: '3',
    title: '过路地排行',
    buriedPointRank: 'depression_passenger_source_depression_pass_by_rank_click',
    buriedPointSidler: 'depression_rank_select',
  },
};

const touristAoiTopObj: any = {
  city: {
    pageType: '3',
    pageType2: '3-1',
    type: '1',
    title: '本市客源排行',
    buriedPointRank: 'passenger_depression_residence_rank_click',
    buriedPointSidler: 'passenger_source_depression_residence_rank_click',
  },
  portrait: {
    pageType: '3',
    pageType2: '3-2',
    type: '1',
    title: '客流画像',
    buriedPointRank: 'passenger_passenger_source_depression_workplace_rank_click',
    buriedPointSidler: 'passenger_source_depression_residence_rank_click',
  },
};
export const aoiInfoObj: any = {
  tourist: touristAoiObj,
  depression: depressionAoiObj,
  touristTop: touristAoiTopObj,
};

export const mallRankListInfo: MallRankListParams[] = [
  {
    type: 1,
    name: '客流人数排行',
    column: '到访客流(人数)',
    tip: '按到mall人数排名',
    displayType: 0,
    category: '商场人气热度',
    buriedPointRank: 'passenger_flow_rank_click',
    tipInfo: '按到访购物中心的人数进行排名',
  },
  {
    type: 2,
    name: '客流人次排行',
    column: '到访客流(人次)',
    displayType: 0,
    tip: '按到mall人次排名',
    category: '商场人气热度',
    buriedPointRank: 'passenger_population_rank_click',
    tipInfo: '按到访购物中心的人次进行排名',
  },
  {
    type: 3,
    name: '本地客流人数排行',
    displayType: 1,
    column: '客流占比(%)',
    category: '商场人气热度',
    tip: '按到mall人次排名',
    buriedPointRank: 'local_passenger_population_rank_click',
    tipInfo: '按到访购物中心客流中本地客流的占比进行排名',
  },
  {
    type: 4,
    name: '外地客流人数排行',
    column: '客流占比(%)',
    displayType: 1,
    category: '商场人气热度',
    tip: '按到mall客流中外地客流占比排名',
    buriedPointRank: 'foreign_passenger_population_rank_click',
    tipInfo: '按到访购物中心客流中外地客流的占比进行排名',
  },
  {
    type: 5,
    name: '商圈辐射力',
    column: '商圈面积(平方公里)',
    displayType: 2,
    category: '商圈影响力',
    tip: '按mall的商圈覆盖面积排名',
    buriedPointRank: 'circle_radiation_rank_click',
    tipInfo: '按购物中心的商圈覆盖面积进行排名',
  },
  {
    type: 6,
    name: '商圈渗透率',
    column: '渗透率(%)',
    tip: '按mall的商圈渗透率排名',
    displayType: 1,
    category: '商圈影响力',
    buriedPointRank: 'circle_penetration_rank_click',
    tipInfo: '按购物中心的商圈渗透率进行排名',
  },
  {
    type: 7,
    name: '商圈人口',
    column: '商圈人口(人数)',
    tip: '按mall的商圈人口数排名',
    displayType: 0,
    category: '商圈影响力',
    buriedPointRank: 'circle_population_rank_click',
    tipInfo: '按购物中心的商圈人口数进行排名',
  },
  {
    type: 8,
    name: '9平方公里商圈渗透率',
    column: '渗透率(%)',
    tip: '按到mall客流在周边9平方公里商圈的渗透率排名',
    displayType: 1,
    category: '商圈影响力',
    buriedPointRank: '9_circle_penetration_rank_click',
    tipInfo: '按购物中心客流在周边9平方公里商圈的渗透率进行排名',
  },
  {
    type: 9,
    name: '25平方公里商圈渗透率',
    column: '渗透率(%)',
    tip: '按到mall客流在周边25平方公里商圈的渗透率排名',
    displayType: 1,
    category: '商圈影响力',
    buriedPointRank: '25_circle_penetration_rank_click',
    tipInfo: '按购物中心客流在周边25平方公里商圈的渗透率进行排名',
  },
  {
    type: 10,
    name: '9平方公里商圈客流占比',
    column: '客流占比(%)',
    tip: '按到mall客流中9平方公里商圈内的客流占比排名',
    displayType: 1,
    category: '商圈影响力',
    buriedPointRank: '9_circle_flow_rank_click',
    tipInfo: '按购物中心客流在周边9平方公里商圈的客流占比进行排名',
  },
  {
    type: 11,
    name: '25平方公里商圈客流占比',
    column: '客流占比(%)',
    tip: '按到mall客流中25平方公里商圈内的客流占比排名',
    displayType: 1,
    category: '商圈影响力',
    buriedPointRank: '25_circle_flow_rank_click',
    tipInfo: '按购物中心客流在周边25平方公里商圈的客流占比进行排名',
  },
  {
    type: 12,
    name: '青年男性最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中20-39岁男性客流占比排名',
    displayType: 1,
    category: '男性客群热度',
    type2: 36,
    buriedPointRank: 'young_man_rank_click',
    tipInfo: '按到访购物中心客流中20-39岁男性客流的人数占比进行排名',
  },
  {
    type: 13,
    name: '中年男性最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中40-59岁男性客流占比排名',
    displayType: 1,
    category: '男性客群热度',
    type2: 37,
    buriedPointRank: 'mid_man_rank_click',
    tipInfo: '按到访购物中心客流中40-59岁男性客流的人数占比进行排名',
  },
  {
    type: 14,
    name: '老年男性最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中60岁及以上男性客流占比排名',
    displayType: 1,
    category: '男性客群热度',
    type2: 38,
    buriedPointRank: 'old_man_rank_click',
    tipInfo: '按到访购物中心客流中60岁及以上男性客流的人数占比进行排名',
  },
  {
    type: 15,
    name: '青年女性最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中20-39岁女性客流占比排名',
    displayType: 1,
    category: '女性客群热度',
    type2: 39,
    buriedPointRank: 'young_woman_rank_click',
    tipInfo: '按到访购物中心客流中20-39岁女性客流的人数或占比进行排名',
  },
  {
    type: 16,
    name: '中年女性最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中40-59岁女性客流占比排名',
    displayType: 1,
    category: '女性客群热度',
    type2: 40,
    buriedPointRank: 'mid_woman_rank_click',
    tipInfo: '按到访购物中心客流中40-59岁女性客流的人数或占比进行排名',
  },
  {
    type: 17,
    name: '老年女性最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中60岁及以上女性客流占比排名',
    displayType: 1,
    category: '女性客群热度',
    type2: 41,
    buriedPointRank: 'old_woman_rank_click',
    tipInfo: '按到访购物中心客流中60岁及以上女性客流的人数或占比进行排名',
  },
  {
    type: 18,
    name: '00后最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中2000年以后出生的客流占比排名',
    displayType: 1,
    category: '各年龄段客群热度',
    type2: 42,
    buriedPointRank: '00_rank_click',
    tipInfo: '按到访购物中心客流中2000年以后出生客流的人数或占比进行排名',
  },
  {
    type: 19,
    name: '90后最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中1990-1999年出生的客流占比排名',
    displayType: 1,
    category: '各年龄段客群热度',
    type2: 43,
    buriedPointRank: '90_rank_click',
    tipInfo: '按到访购物中心客流中1990-1999年出生客流的人数或占比进行排名',
  },
  {
    type: 20,
    name: '70后80后最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中1970-1989年出生的客流占比排名',
    displayType: 1,
    category: '各年龄段客群热度',
    type2: 44,
    buriedPointRank: '70_80_rank_click',
    tipInfo: '按到访购物中心客流中1970-1989年出生客流的人数或占比进行排名',
  },
  {
    type: 21,
    name: '银发族最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中60岁及以上客流占比排名',
    displayType: 1,
    category: '各族群客群热度',
    type2: 45,
    buriedPointRank: 'seniors_rank_click',
    tipInfo: '按到访购物中心客流中60岁及以上客流的人数或占比进行排名',
  },
  {
    type: 22,
    name: '准妈妈新妈妈最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中子女状态为“孕婴”的客流占比排名',
    displayType: 1,
    category: '各族群客群热度',
    type2: 46,
    buriedPointRank: 'expectant_mothers_rank_click',
    tipInfo: '按到访购物中心客流中子女状态为“孕婴”客流的人数或占比进行排名',
  },
  {
    type: 23,
    name: '单身族最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中婚姻状态为“未婚”的客流占比排名',
    displayType: 1,
    category: '各族群客群热度',
    type2: 47,
    buriedPointRank: 'single_rank_click',
    tipInfo: '按到访购物中心客流中婚姻状态为“未婚”客流的人数或占比进行排名',
  },
  {
    type: 24,
    name: '中学生最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中学历为“初中/高中”的客流占比排名',
    displayType: 1,
    category: '各族群客群热度',
    type2: 48,
    buriedPointRank: 'middle_school_students_rank_click',
    tipInfo: '按到访购物中心客流中学历为“初中/高中”客流的人数或占比进行排名',
  },
  {
    type: 25,
    name: '大学生最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中学历为“大专/本科”的客流占比排名',
    displayType: 1,
    category: '各族群客群热度',
    type2: 49,
    buriedPointRank: 'college_students_rank_click',
    tipInfo: '按到访购物中心客流中学历为“大专/本科”客流的人数或占比进行排名',
  },
  {
    type: 26,
    name: '高学历人群最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中学历为“硕士/博士及以上”的客流占比排名',
    displayType: 1,
    category: '各族群客群热度',
    type2: 50,
    buriedPointRank: 'highly_educated_rank_click',
    tipInfo: '按到访购物中心客流中学历为“硕士/博士及以上”客流的人数或占比进行排名',
  },
  {
    type: 27,
    name: '上班族白领最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中人生阶段为“上班族”的客流占比排名',
    displayType: 1,
    category: '各族群客群热度',
    type2: 51,
    buriedPointRank: 'white_collar_rank_click',
    tipInfo: '按到访购物中心客流中人生阶段为“上班族”客流的人数或占比进行排名',
  },
  {
    type: 28,
    name: '高消费能力人群最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中消费能力为“高/较高”的客流占比排名',
    displayType: 1,
    category: '不同消费属性客群',
    type2: 34,
    buriedPointRank: 'high_consumption_rank_click',
    tipInfo: '按到访购物中心客流中消费能力为“高”客流的人数或占比进行排名',
  },
  {
    type: 29,
    name: '中等消费能力人群最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中消费能力为“中”的客流占比排名',
    displayType: 1,
    category: '不同消费属性客群',
    type2: 35,
    buriedPointRank: 'mid_consumption_rank_click',
    tipInfo: '按到访购物中心客流中消费能力为“中”客流的人数或占比进行排名',
  },
  {
    type: 30,
    name: '苹果手机用户最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中手机品牌为“苹果”的客流占比排名',
    displayType: 1,
    category: '不同消费属性客群',
    type2: 52,
    buriedPointRank: 'apple_rank_click',
    tipInfo: '按到访购物中心客流中手机品牌为“苹果”客流的人数或占比进行排名',
  },
  {
    type: 31,
    name: '华为手机用户最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中手机品牌为“华为”的客流占比排名',
    displayType: 1,
    category: '不同消费属性客群',
    type2: 53,
    buriedPointRank: 'huawei_rank_click',
    tipInfo: '按到访购物中心客流中手机品牌为“华为”客流的人数或占比进行排名',
  },
  {
    type: 32,
    name: 'OV手机用户最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中手机品牌为“OPPO/VIVO”的客流占比排名',
    displayType: 1,
    category: '不同消费属性客群',
    type2: 54,
    buriedPointRank: 'ov_rank_click',
    tipInfo: '按到访购物中心客流中手机品牌为“oppo/vivo”客流的人数或占比进行排名',
  },
  {
    type: 33,
    name: '小米手机用户最喜欢的购物中心',
    column: '客流占比(%)',
    tip: '按到mall客流中手机品牌为“小米”的客流占比排名',
    displayType: 1,
    category: '不同消费属性客群',
    type2: 55,
    buriedPointRank: 'mi_rank_click',
    tipInfo: '按到访购物中心客流中手机品牌为“小米”客流的人数或占比进行排名',
  },
];

const competeTip = [
  {
    value: 1,
    label: '全部区域',
    tips: '主场&竞品的全部客流覆盖网格。',
  },
  {
    value: 2,
    label: '主场占优区域',
    tips: '主场客流与竞品客流相比，主场人数多于竞品，为主场占优区域。',
  },
  {
    value: 3,
    label: '竞品占优区域',
    tips: '竞品客流与主场客流相比，竞品人数多于主场，为竞品占优区域。',
  },
  {
    value: 4,
    label: '双方持平区域',
    tips: '主场客流等于竞品客流，为双方持平区域。',
  },
  {
    value: 5,
    label: '主场独占区域',
    tips: '网格中，到访主场但未到访竞品，为主场独占区域。',
  },
  {
    value: 6,
    label: '竞品独占区域',
    tips: '网格中，到访竞品但未到访主场，为竞品独占区域。',
  },
];

export const mallRankListObj: any = {};
mallRankListInfo.forEach((item: MallRankListParams) => {
  const { type } = item;
  mallRankListObj[type] = item;
});

export default {
  prefix,
  theme,
  innersApi,
  privacy,
  privacyInfo,
  aoiType,
  mallRankListInfo,
  privacyMallInfo,
  competeTip,
};
