





















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getContainer } from '..';
import helpCircleFilled from '@/components/HelpCircleFilled/index.vue';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
@Component({
  components: {
    helpCircleFilled,
  },
})
export default class RankTable extends Vue {
  height = 0;
  tableHg = 0;
  @Prop({
    type: String,
    required: false,
    default: '由于数据隐私风险，客流过低的区域网格未做可视化展示。区域内网格按照“竞争客流人数”进行排名，同时展示每个网格内的“区域竞争客流占比（区域竞争客流人数/区域客流总数）',
  })
  tipInfo!: string;
  cardConfig = {
    titleInfo: this.tipInfo,
    cardInfoStatus: true,
    helpSize: 19,
  };
  @Prop({
    type: Array,
    required: false,
    default: [{}],
  })
  tableTitle!: [];
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  dateShow!: string;
  @Prop({
    type: Array,
    required: false,
    default: [{}],
  })
  data!: any;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  title!: string;
  @Prop({
    type: String,
    default: new Date(),
    required: false,
  })
  date!: string;
  @Prop({
    type: Number,
    default: 0,
    required: false,
  })
  titleInfoStatus !: number;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  titleInfo!: string;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  tipStatus!: string;
  @Prop({
    type: String,
    required: false,
    default: 'business',
  })
  pageType!: string;
  // tableWh = 450;
  @Prop({
    type: Number,
    required: false,
    default: 450,
  })
  tableWh!: number;
  @Prop({
    type: String,
    required: false,
  })
  buriedPoint!: '';
  @Prop({
    type: Object,
    required: false,
    default: () => ({
      dates: '',
      mArea: 0,
      mSum: 0,
      nArea: 0,
      nTotal: 0,
      nSum: 0,
    }),
  })
  asyncData!: any;
  @Prop({
    type: Boolean,
    required: false,
  })
  tipInfoStatus!: false;
  lockActive = 0;
  lockMallId = 0;
  $jQ: any;
  sortDataId: any = {};
  rowActiveMallId = 0;
  field = 'mall_id';
  setTime!: any;


  beforeDestroy(): void{
    this.$bus.off('rowClick', this.rowClickValue);
    this.setTime = null;
    this.$jQ('.table').unbind();
    this.$jQ('.ivu-table-row').unbind();
  }
  destroy(): void{
    (this.$el.parentNode as any).removeChild(this.$el);
  }
  getSortData(): void{
    // 解决异步队列中动态拿不到data问题解决
    const table = (this.$refs.table as any);
    let { data } = this;
    if (data && table) {
      data = table.$data;
      if (data) {
        data = data.rebuildData;
      }
    }
    const sortData = data ? data : this.data;
    const obj: any = {};
    sortData.forEach((item: any, index: number) => {
      sortData[index].sort_index = index;
      // const { mall_id } = item;
      const mall_id = item[this.field];
      obj[mall_id] = item;
    });
    this.sortDataId = obj;
    if (this.lockMallId !== 0) {
      this.$jQ('.region-locks').hide();
      this.$jQ('.ivu-table-row').removeClass('active-lock');
      this.$jQ('.ivu-table-row').removeClass('active-unlock');
    }
    const lockItem = this.sortDataId[this.lockMallId];
    const activeItem = this.sortDataId[this.rowActiveMallId];
    if (lockItem) {
      const { index } = lockItem;
      const mall_id = lockItem[this.field];
      const item = this.sortDataId[mall_id];
      let indexs = index;
      if (item) {
        indexs = this.sortDataId[mall_id].sort_index;
      }
      this.$jQ('.ivu-table-row')
        .removeClass('active-row');
      const target = this.$jQ('.ivu-table-row').eq(indexs);
      target.addClass('active-row');
      target.addClass('active-lock');
      target.find('.region-lock').show();
    } else {
      const target = this.$jQ('.ivu-table-row').eq(0);
      target.addClass('active-row');
      target.find('.region-unlock').show();
    }
    if (this.rowActiveMallId !== this.lockMallId && activeItem) {
      const { index } = activeItem;
      const mall_id = activeItem[this.field];
      const item = this.sortDataId[mall_id];
      let indexs = index;
      if (item) {
        indexs = this.sortDataId[mall_id].sort_index;
      }
      this.$jQ('.ivu-table-row')
        .removeClass('active-row');
      this.$jQ('.region-unlock')
        .hide();
      const target = this.$jQ('.ivu-table-row').eq(indexs);
      target.addClass('active-row');
      target.addClass('active-unlock');
      target.find('.region-unlock').show();
      this.tableScrollTop(indexs);
    }
    if (!activeItem) {
      this.$jQ('.ivu-table-row')
        .removeClass('active-row');
      this.$jQ('.region-unlock')
        .hide();
      const target = this.$jQ('.ivu-table-row').eq(0);
      target.addClass('active-row');
      target.addClass('active-unlock');
      target.find('.region-unlock').show();
    }
  }
  sortHandler(): void{
    this.getSortData();
  }

  rowClickHandler(row: any): void {
    this.tableClickHandler(row);
    this.$emit('rowClick', row);
    const rank = row.rank ? row.rank : row.cover_rank;
    if (this.buriedPoint) {
      this.$emit('buriedPointHandler', this.buriedPoint, { rank });
    }
  }
  created(): void{
    this.tableHg = getContainer(300);
  }
  updated(): void{
    this.$nextTick(() => {
      this.cardConfig.titleInfo = this.tipInfo;
    });
    this.getSortData();
  }

  mounted(): void{
    this.$bus.on('rowClick', this.rowClickValue);
    let { field } = this;
    let hg = 122;
    if (this.pageType === 'business') {
      field = 'grid_id';
    } else if (this.pageType === 'aoi') {
      field = 'aoi_id';
    } else if (this.pageType === 'city') {
      field = 'mall_id';
    } else if (this.pageType === 'intensity') {
      field = 'grid_id';
      hg = 139;
    }
    this.tableHg = getContainer(164);
    if (this.titleInfoStatus === 1) {
      this.height = this.tableHg - hg;
    } else {
      this.height = this.tableHg - 74;
    }
    this.field = field;

    this.$jQ('.table').on('mouseenter', '.ivu-table-row', (e: Event) => {
      const { currentTarget } = e;
      this.$jQ(currentTarget).addClass('ivu-table-row-hover');
    });
    this.$jQ('.table').on('mouseleave', '.ivu-table-row', () => {
      this.$jQ('.ivu-table-row').removeClass('ivu-table-row-hover');
    });
    this.$jQ('.table').on('click', '.region-locks', (e: any) => {
      const target = this.$jQ(e.target);
      const mallId: number = target.parents('.ivu-table-row').find('.ivu-table-cell')
        .eq(0)
        .children()
        .attr('ids');
      this.lockHandler(target, mallId);
    });
    const lockMallId = parseInt(sessionStorage.getItem('lockMallId') || '', 10);
    const rowActiveMallId = parseInt(sessionStorage.getItem('rowActiveMallId') || '', 10);
    this.lockMallId = lockMallId ? lockMallId : this.lockMallId;
    this.rowActiveMallId = rowActiveMallId ? rowActiveMallId : this.rowActiveMallId;
    this.rowActiveMallId = 0;
  }
  rowClickValue(e: any): void{
    this.tableClickHandler(e);
    this.$emit('rowClick', e);
  }
  lockHandler(target: any, mallId: number): void{
    const lock = target.hasClass('region-lock');
    if (lock) {
      target.parents('.ivu-table-row').removeClass('active-lock');
      target.parents('.ivu-table-row').addClass('active-unlock');
      target.hide();
      target.parent().children('.region-unlock')
        .show();
      this.lockMallId = 0;
    } else {
      this.$jQ('.region-lock').hide();
      this.$jQ('.ivu-table-row').removeClass('active-lock');
      // this.$jQ('.ivu-table-row').removeClass('active-row');
      this.$jQ('.ivu-table-row').find('.region-unlock')
        .hide();
      target.parents('.ivu-table-row').addClass('active-lock');
      target.parents('.ivu-table-row').removeClass('active-unlock');
      target.parent().children('.region-lock')
        .show();
      target.parent().children('.region-unlock')
        .hide();
      this.lockMallId = mallId;
    }
    sessionStorage.setItem('lockMallId', this.lockMallId.toString());
  }

  tableClickHandler(row: any, type?: number): void{
    const { index } = row;
    const id = row[this.field];
    const item = this.sortDataId[id];
    let indexs = index;
    if (item) {
      indexs = this.sortDataId[id].sort_index;
    }
    this.rowActiveMallId = id;
    sessionStorage.setItem('rowActiveMallId', id);
    this.$jQ('.ivu-table-row')
      .removeClass('active-row');
    const target = this.$jQ('.ivu-table-row').eq(indexs);
    target.addClass('active-row');
    const lockStatus = this.$jQ('.region-lock').is('hidden');
    this.$jQ('.region-unlock').hide();
    if (!lockStatus) {
      this.$jQ('.region-unlock').eq(indexs)
        .show();
    }
    if (type === 1) {
      this.tableScrollTop(indexs);
    }
  }

  tableScrollTop(indexs: number): void{
    const el = this.$jQ('.ivu-table-row');
    // const lockStatus = this.$jQ('.region-lock').is('hidden');
    const hg = el.height();
    let top = indexs * hg - 2;
    if (this.pageType === 'city') {
      top = top - hg;
    }
    const tbody = this.$jQ('.ivu-table-body');
    tbody.eq(0).scrollTop(top);
  }
}
