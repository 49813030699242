import { RouterConfig } from '@/types/router';
const summary: RouterConfig = {
  path: '/system/summary',
  name: '客流概况',
  meta: {
    id: '1',
    isShow: true,
  },
  component: () => import('@/pages/systemPages/blank/index.vue'),
  children: [
    {
      path: '/system/summary/statistics',
      name: '客流统计',
      icon: 'm-icon-a-Group6259',
      meta: {
        id: '1-1',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/summary/statistics/index.vue'),
    },
    {
      path: '/system/summary/analysis',
      name: '客流分析',
      icon: 'm-icon-a-Group6249',
      meta: {
        id: '1-2',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/summary/analysis/index.vue'),
    },
    {
      path: '/system/summary/compare',
      name: '客流对比',
      icon: 'm-icon-a-Group6260',
      meta: {
        id: '1-3',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/summary/compare/index.vue'),
    },
  ],
};
export default summary;
