















import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  components: {

  },
})
export default class extends Vue {
  @Prop({
    type: Array,
    required: false,
  })
  list!: [];
  currentValue = 0;
  checkMenulist(val: number): void {
    if (this.currentValue === val) return;
    this.currentValue = val;
    sessionStorage.setItem('aoiCurrentValue', val.toString());
    this.$emit('checkMenulist', val);
    // const item = this.list[val];
    // this.buriedPointHandler(item.buriedPoint);
  }
}
