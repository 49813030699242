import { RouterConfig } from '@/types/router';
const portrait: RouterConfig = {
  path: '/system/portrait',
  name: '客流画像',
  meta: {
    id: '2',
    isShow: true,
  },
  component: () => import('@/pages/systemPages/blank/index.vue'),
  children: [
    {
      path: '/system/portrait/natural',
      name: '自然属性',
      icon: 'm-icon-a-Group6248',
      meta: {
        id: '2-1',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/portrait/natural/index.vue'),
    },
    {
      path: '/system/portrait/economy',
      name: '财富属性',
      icon: 'm-icon-a-Group6399',
      meta: {
        id: '2-2',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/portrait/economy/index.vue'),
    },
    {
      path: '/system/portrait/arrival',
      name: '到访偏好',
      icon: 'm-icon-a-Group6398',
      meta: {
        id: '2-3',
        isShow: true,
      },
      component: () => import('@/pages/systemPages/portrait/arrival/index.vue'),
    },
  ],
};
export default portrait;
