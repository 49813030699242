





import { Vue, Component, Prop } from 'vue-property-decorator';
import { prefix } from '@/config/global';

/*
 *图表卡片
 */

@Component({
  components: {},
})
export default class PortraitPrivcar extends Vue {
  prefix: string = prefix;
  @Prop({
    type: String,
    required: false,
    default: '',
  })  pravcar !: string;
}
