import { RankFilter } from '@/types/store';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../index';

@Module({ dynamic: true, name: 'pageConfig', store })
class PageConfig extends VuexModule {
  filterValue: number[] = [0, 100];
  aoiFilterValue: number[] = [0, 100];
  businessFilterValue: number[] = [0, 100];
  groupFilterValue: number[] = [0, 100];
  rankFilter = {
    step: 0.01,
    min: 0.01,
    max: 0.96,
  };
  exportStatus = true;
  mallUpdateKey = '';

  @Mutation
  setExportStatus(val: boolean): void {
    this.exportStatus = val;
  }

  @Mutation
  setRankFilter(val: RankFilter): void {
    this.rankFilter = val;
  }

  @Mutation
  setFilterValue(val: number[]): void {
    this.filterValue = val;
  }

  @Mutation
  setAoiFilterValue(val: number[]): void {
    this.aoiFilterValue = val;
  }

  @Mutation
  setBusinessFilterValue(val: number[]): void {
    this.businessFilterValue = val;
  }

  @Mutation
  setMallUpdateKey(val: string): void {
    this.mallUpdateKey = val;
  }

  @Mutation
  setGroupFilterValue(val: number[]): void {
    this.groupFilterValue = val;
  }
}

export default PageConfig;
