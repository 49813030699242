import { Vue } from 'vue-property-decorator';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function checkNumber(str: string): boolean {
  const reg = /^[0-9]+.?[0-9]*$/;
  if (reg.test(str)) {
    return true;
  }
  return false;
}

function computePassengerFlowProfile(before: string, today: string, type?: number): string {
  let value: any;
  if (before === '0' && today === '0') {
    value = 0;
    if (type === 3) {
      value = `${value}%`;
    }
  } else if (checkNumber(before) && checkNumber(today)) {
    if (type === 1) {
      value = round(Number(today) / 100 - Number(before) / 100);
    } else {
      value = round((Number(today) - Number(before)) / Number(before));
      if (type === 3) {
        value = `${value}%`;
      }
    }
  } else if (before === '— —' || today === '— —') {
    value = '— —';
  } else if (before === '暂无数据' || today === '暂无数据') {
    value = '暂无数据';
  } else {
    value = '无法比较';
  }
  return value;
}

function computeCompareData(today: string, before: string): number {
  let percent = 0;
  if (before && today) {
    percent = round((Number(today) - Number(before)) / Number(before));
  } else if (today) {
    percent = 100;
  } else {
    percent = 0;
  }
  return percent;
}

function round(value: number): number {
  return Math.round(value * 10000) / 100;
}

function dateArys(date: string, day: number): string[] {
  // 日期功能生产器
  const nDate = new Date(date);
  let dateAry: string[] = [];
  for (let index = 1; index < day; index++) {
    let itemDate = nDate.setDate(nDate.getDate() - 1);
    itemDate = dayjs(itemDate)
      .format('YYYY-MM-DD');
    dateAry.push(`${itemDate}`);
  }
  dateAry.unshift(date);
  dateAry = dateAry.reverse();
  return dateAry;
}

function getDateAryByStartEnd(start: string, end: string): string[] {
  const day = dayjs(end).diff(dayjs(start), 'day');
  const nDate = new Date(start);
  const dateAry: string[] = [];
  dateAry.push(start);
  for (let index = 0; index < day; index++) {
    let itemDate = nDate.setDate(nDate.getDate() + 1);
    itemDate = dayjs(itemDate)
      .format('YYYY-MM-DD');
    dateAry.push(`${itemDate}`);
  }
  return dateAry;
}

function replaceAll(replaces: string, beforeReplacement: string, afterReplacement: string): string {
  /*
  * replace 要替换得变量
  * beforeReplacement 准备替换得值
  * afterReplacement 替换后得值
  */
  const reg = new RegExp(beforeReplacement, 'g');
  const strs = replaces.replace(reg, afterReplacement);
  return strs;
}

function getMax(data: any, field: string): number {
  let res = (Math as any).max.apply(Math, data.map((item: any) => item[field]));
  if (!isFinite(res)) {
    res = 0;
  }
  return res;
}

function getMin(data: any, field: string): number {
  let res = (Math as any).min.apply(Math, data.map((item: any) => item[field]));
  if (!isFinite(res)) {
    res = 0;
  }
  return res;
}

function initDate(duration: string): string {
  let date = '';
  if (duration === 'd') {
    date = dayjs()
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
  } else if (duration === 'xw') {
    date = dayjs()
      .subtract(1, 'week')
      .endOf('week')
      .add(1, 'day')
      .format('YYYY-MM-DD');
  } else if (duration === 'xm') {
    date = `${dayjs()
      .subtract(1, 'month')
      .format('YYYY-MM')}-01`;
  } else if (duration === 'xh') {
    date = dayjs()
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
  } else if (duration === 'r') {
    date = dayjs()
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
    date = `${date},${date}`;
  } else {
    alert('时间类型不存在');
  }
  return date;
}

function dayjs(date = Vue.prototype.$dayjs()) {
  return Vue.prototype.$dayjs(date);
}

function processBeforeDateByDuration(dates: string, duration: string): any {
  const dateObj = {
    dataDate: '', // 数据取数日期
    firstDate: '', // 开始日期
    lastDate: '', // 结束日期
    date: '', // 数据生成日期
  };
  let dataDate = '';
  let firstDate = '';
  let lastDate = '';
  let date = '';
  switch (duration) {
    case 'd':
      lastDate = dayjs(dates)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      firstDate = lastDate;
      dataDate = lastDate;
      date = lastDate;
      break;
    case 'w': case 'xw':
      lastDate = dayjs(dates)
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      firstDate = dayjs(lastDate)
        .subtract(6, 'day')
        .format('YYYY-MM-DD');
      dataDate = lastDate;
      date = dayjs(lastDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');
      break;
    case 'm': case 'xm':
      lastDate = dayjs(dates)
        .subtract(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');
      firstDate = `${dayjs(dates)
        .subtract(1, 'month')
        .format('YYYY-MM')}-01`;
      dataDate = firstDate;
      date = dayjs(lastDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');
      break;
    case 'h':
      lastDate = dayjs(dates)
        .subtract(1, 'year')
        .format('YYYY-MM-DD');
      firstDate = dayjs(dates)
        .subtract(1, 'year')
        .format('YYYY-MM-DD');
      dataDate = lastDate;
      date = dayjs(lastDate)
        .add(1, 'year')
        .format('YYYY-MM-DD');
      break;
    case 's':
      lastDate = dayjs(dates)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      firstDate = dayjs(dates)
        .subtract(3, 'month')
        .format('YYYY-MM-DD');
      dataDate = firstDate;
      date = dates;
      break;
    case 'y':
      lastDate = dayjs(dates)
        .subtract(1, 'year')
        .endOf('year')
        .format('YYYY-MM-DD');
      firstDate = dayjs(dates)
        .subtract(1, 'year')
        .startOf('year')
        .format('YYYY-MM-DD');
      dataDate = firstDate;
      date = dates;
      break;
    default:
      console.log('时间类型不存在，请开发人员重新配置');
  }
  dateObj.dataDate = dataDate;
  dateObj.firstDate = firstDate;
  dateObj.lastDate = lastDate;
  dateObj.date = date;
  return dateObj;
}


interface WeekDate {
  startDate: string;
  endDate: string;
  weekDate: string;
}

function weekDateS(): WeekDate {
  const startDate = dayjs()
    .subtract(1, 'week')
    .startOf('week')
    .add(1, 'day')
    .format('YYYY-MM-DD');
  const endDate = dayjs()
    .subtract(1, 'week')
    .endOf('week')
    .add(1, 'day')
    .format('YYYY-MM-DD');
  const weekDate = dayjs()
    .subtract(1, 'week')
    .startOf('week')
    .add(2, 'day')
    .format('YYYY-MM-DD');

  return {
    startDate,
    endDate,
    weekDate,
  };
}

function getCompareWeekDate(date: string) {
  const dates = dayjs(date)
    .subtract(6, 'day')
    .format('YYYY-MM-DD');
  return dates;
}
function getDayDate(date: string) {
  const dates = dayjs(date)
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  return dates;
}

function getWeekDate(date: string) {
  const dates = dayjs(date)
    .subtract(7, 'day')
    .format('YYYY-MM-DD');
  return dates;
}

function getMonthDate(date: string) {
  const dates = `${dayjs(date)
    .subtract(30, 'day')
    .format('YYYY-MM')}-01`;
  return dates;
}

function getDateByDuration(duration: string, date: string) {
  let dates = '';
  if (duration === 'd') {
    dates = getDayDate(date);
  } else if (duration === 'xw') {
    dates = getWeekDate(date);
  } else {
    dates = getMonthDate(date);
  }
  return dates;
}

function weekDate(dateAry: string[]) {
  const ary: string[] = [];
  dateAry.forEach((item: string) => {
    const date = new Date(item);
    const day = date.getDay();
    if (day === 0 || day === 6) {
      ary.push(item);
    }
  });
  return ary;
}

function workDate(dateAry: string[]) {
  const ary: string[] = [];
  dateAry.forEach((item: string) => {
    const date = new Date(item);
    const day = date.getDay();
    if (day !== 0 && day !== 6) {
      ary.push(item);
    }
  });
  return ary;
}

function getContainer(cha?: number) {
  const height = document.body.clientHeight;
  const maphg = cha ? height - cha : height - 128;
  return maphg;
}

function validateName(value: string) {
  const reg = new RegExp(/<|>|？|;|“|’/);
  let status = 0;
  if (reg.test(value)) {
    status = 1;
  } else {
    status = 0;
  }
  return status;
}
function processObj(data: any, field: string) {
  const obj: any = {};
  data.forEach((item: any) => {
    const key = item[field];
    obj[key] = item;
  });
  return obj;
}
function uniqueData(ary: any, field = '') {
  const temp: any = []; // 一个新的临时数组
  ary.forEach((item: any) => {
    if (field) {
      if (temp.indexOf(item[field]) === -1) {
        temp.push(item[field]);
      }
    } else {
      if (temp.indexOf(item) === -1) {
        temp.push(item);
      }
    }
  });
  // console.log(temp)
  const temp2: any = [];
  temp.forEach((item: any) => {
    let obj: any = {};
    ary.forEach((item1: any) => {
      if (item === item1.value) {
        obj = item1;
      }
    });
    temp2.push(obj);
  });
  return temp2;
}

function createPopLevelArr(options: any) {
  const arr: any = [];
  const { max, color, weightType } = options;
  const lth = color.length;
  const sectionVal = max / lth;
  let minVal = 0;
  let maxVal = sectionVal;
  if (weightType === 1) {
    color.forEach((item: string) => {
      const objTmp = {
        min: Math.round(minVal),
        max: Math.round(maxVal),
        color: item,
      };
      arr.push(objTmp);
      minVal += sectionVal;
      maxVal += sectionVal;
    });
  } else {
    color.forEach((item: string) => {
      const objTmp = {
        min: Math.round(minVal * 100) / 100,
        max: Math.round(maxVal * 100) / 100,
        color: item,
      };
      arr.push(objTmp);
      minVal += sectionVal;
      maxVal += sectionVal;
    });
  }
  return arr;
}
function fillColorPage(weight: number, sectionAry: any) {
  let colors = '';
  sectionAry.forEach((item: any) => {
    const { min, max, color } = item;
    if (weight >= min && weight <= max) {
      colors = color;
    }
  });
  return colors;
}
function updateTimeText(date: string, text = '已添加') {
  const dates = dayjs(date).format('YYYYMMDD');
  const date2 = dayjs().format('YYYYMMDD');
  let str = text;
  if (dates >= date2) str = '准备中';
  return str;
}

function getReportType(): string {
  const reportDropType = localStorage.getItem('reportDropType');
  const dropType = reportDropType ? reportDropType : 'w';
  return dropType;
}

function groupAry(data: string[], lth: number): string[][] {
  let index = 0;
  const newArray = [];
  while (index < data.length) {
    newArray.push(data.slice(index, index += lth));
  }
  return newArray;
}

export {
  round,
  computePassengerFlowProfile,
  checkNumber,
  dateArys,
  getMax,
  getMin,
  replaceAll,
  getDateAryByStartEnd,
  initDate,
  weekDate,
  workDate,
  weekDateS,
  getContainer,
  getCompareWeekDate,
  validateName,
  processObj,
  uniqueData,
  createPopLevelArr,
  fillColorPage,
  getDayDate,
  getWeekDate,
  getMonthDate,
  getDateByDuration,
  computeCompareData,
  updateTimeText,
  processBeforeDateByDuration,
  getReportType,
  groupAry,
};
